import axios from 'axios';
import { API_URL } from '../../config';

const instance = axios.create({
  baseURL: API_URL,
});

export function setTokenHeader(token) {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
}

export function apiCall(method, path, data, config) {
  return new Promise((resolve, reject) => {
    return instance[method.toLowerCase()](path, data, config)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          // console.warn('api. js | client received an error response (5xx, 4xx)');
        } else if (err.request) {
          console.warn('api.js | client never received a response, or request never left');
        }
        if (err.response && err.response.data && err.response.data.error) {
          return reject(err.response.data.error); //error es el error handler del back
        } else {
          return reject(err);
        }
      });
  });
}
