export const TRANSLATIONS_ES = {
  // GenericResponses
  res_error_catch: 'Ups! Ocurrió un problema al realizar esta acción',
  // ServerResponses
  error: 'Error',
  res_error: 'Ups! No se pudo procesar tu solicitud.',
  res_email_not_found: 'No encontramos ese correo. Verifica que lo hayas ingresado correctamente.',
  res_expert_auth_invalid: 'Email y/o contraseña incorrectos.',
  res_expert_login_try_attempts: 'Has hecho muchos intentos en iniciar sesión. Por tu seguridad, podrás reintentarlo en 10 minutos.',
  res_patient_needs_activation: 'Para poder restablecer tu contraseña solicita a tu profesional que active tu cuenta.',
  res_token_invalid: 'El enlace ingresado es inválido o ha expirado.',
  res_token_validation_error: 'Ocurrió un problema al validar este enlace. Por favor, vuelve a restablecer tu contraseña.',
  res_patient_auth_invalid: 'Correo y/o contraseña inválidos.',
  res_patient_not_linked_expert: 'Tu cuenta no se encuentra asociada a este negocio.',
  // HomeMain
  patient: 'Paciente',
  patient_other: 'Pacientes',
  close: 'Cerrar',
  upcoming_appointments: 'Próximas consultas',
  upcoming_appointments_empty: 'No tienes consultas agendadas para los próximos {{minutesBeforeAppointment}} minutos',
  last_appointments: 'Últimas consultas',
  last_appointments_empty: 'Todavía no tienes consultas',
  new_patients: 'Nuevos pacientes',
  new_patients_empty: 'Todavía no registras pacientes',
  pending_mealPlans: 'Dietas pendientes',
  pending_mealPlans_empty: 'No tienes dietas pendientes',
  mealPlan_format: 'Formato',
  mealPlan_objectives: 'Objetivos calóricos',
  mealPlan_foods: 'Elección de alimentos',
  appointments_revenues: 'Ingresos por consultas',
  appointments_revenues_empty: 'Todavía no registras ingresos',
  change_from: 'Variación respecto a',
  weekly_change: 'Variación respecto a la semana pasada',
  quick_actions: 'Accesos rápidos',
  appointments_status: 'Estado de consultas',
  appointments_status_empty: 'Hoy no has iniciado consultas',
  appointments_day: 'Consultas del día',
  appointments_finished: 'finalizada',
  appointments_finished_other: 'finalizadas',
  appointments_process: 'en proceso',
  appointments_pending: 'pendiente',
  appointments_pending_other: 'pendientes',
  appointments_cancelled: 'cancelada',
  appointments_cancelled_other: 'canceladas',
  search_patient: 'Buscar paciente',
  search_patient_action: 'Busca a tus pacientes',
  search_patient_action_empty: 'No se encontraron pacientes',
  search_patient_action_button: 'Ir al perfil',
  referrals: 'Referidos',
  referrals_share: '¿Qué esperas? ¡Comparte tu link!',
  referrals_share_copy: 'Enlace copiado',
  referrals_current: 'Actualmente tienes <1>{{count}}</1> referido',
  referrals_current_other: 'Actualmente tienes <1>{{count}}</1> referidos',
  referrals_todo: 'Con <1>{{count}}</1> referidos pagarías',
  referrals_free_text: 'Con <1>{{count}}</1> referidos Almendra es ',
  referrals_free_tag: 'GRATIS',
  activePatients_widget_you_have: 'Tienes <1>{{ count }}</1> paciente activo en este periodo',
  activePatients_widget_you_have_other: 'Tienes <1>{{ count }}</1> pacientes activos en este periodo',
  activePatients_widget_plan: 'Plan: <1>{{ planName }}</1>',
  activePatients_widget_limit: 'Límite: <1>{{ limit }}</em>',
  activePatients_widget_report: 'Reporte',
  activePatients_widget_empty: 'Aquí podrás visualizar tus pacientes activos cuando tengas una suscripción en Almendra',
  activePatient: 'Paciente activo',
  activePatient_other: 'Pacientes activos',
  // AppointmentCard
  scheduled: 'Agendada',
  delayed: 'Atrasada',
  in_progress: 'En curso',
  appointment_on_site: 'Consulta Presencial',
  started_on: 'Empezó el',
  will_start_on: 'Empezará el',
  should_started_on: 'Debió empezar el',
  finish_appointment: 'Terminar consulta',
  delete_appointment: 'Eliminar consulta',
  // EditWidgets
  edit_widgets_error: 'Solo puedes tener 8 widgets como máximo en tu dashboard',
  edit_widgets_title: 'Organiza la página con los siguientes widgets',
  edit_widgets_content:
    "Selecciona los widgets (cuadrados que contienen información, por ejemplo, el de 'Nuevos Pacientes') que deseas que aparezcan siempre en esta página. Primero haz click en la casilla al lado del nombre del widget y luego en la flecha azul del centro. Escoge un máximo de 7 widgets.",
  widget_button: 'Organizar',
  widget_available: 'Disponible',
  widget_used: 'Utilizado',
  widget_empty: 'No hay widgets en la lista',
  widget_search: 'Buscar widget...',
  // PopupNewPatient
  add_patient: 'Agregar nuevo paciente',
  register_patient: 'Registrar paciente',
  add_patient_action: 'Crear paciente',
  firstName: 'Nombre',
  patient_v_firstName: 'Ingresa el nombre del paciente',
  lastName: 'Apellido',
  patient_v_lastName: 'Ingresa el apellido del paciente',
  gender: 'Sexo',
  patient_v_gender: 'Selecciona el sexo del paciente',
  patient_v_gender_f: 'Mujer', // cambiar
  patient_v_gender_m: 'Hombre', // cambiar
  nationality: 'País de residencia',
  patient_v_nationality: 'Selecciona el país de residencia del paciente.',
  patient_show_extra: 'Ver campos extra',
  patient_hide_extra: 'Ocultar campos extra',
  birthDate: 'Fecha de nacimiento',
  patient_v_birthDate: 'Ingresa la fecha de nacimiento del paciente',
  email: 'Email',
  email_suggestion: '¿Querías escribir',
  email_required: 'Ingresa un correo válido',
  patient_v_mail: 'Ingresa el correo del paciente',
  add_patient_success: 'Paciente creado con éxito',
  patient_v_mail_duplicated: 'Ya tienes registrado a un paciente con el mail que ingresaste',
  idNumber: 'N° Documento',
  patient_v_idNumber: 'Ingresa el número del documento de identidad del paciente',
  occupation: 'Ocupación',
  patient_v_occupation: 'Ingresa la ocupación del paciente',
  phone: 'Teléfono',
  patient_v_phone: 'Ingresa el número de teléfono del paciente',
  sendPasswordMail: '¿Enviar correo con accesos?',
  yes: 'Sí',
  no: 'No',
  add_patient_error: 'No se pudo agregar a este paciente. El equipo de soporte ha sido notificado sobre esto.',
  // NewAppointmentForm
  start_appointment_online_text:
    'Para poder iniciar una consulta online con <1>{{service}}</1> debes vincular tu cuenta de Almendra con tu cuenta de {{service}}. ¿Deseas hacerlo ahora?',
  loading_patient_error: 'No se pudo cargar la información de este paciente',
  appointment_prices_includes: 'El precio incluye {{count}} consulta',
  appointment_prices_includes_other: 'El precio incluye {{count}} consultas',
  appointment_prices_includes_short: 'incluye {{count}} consulta',
  appointment_prices_includes_short_other: 'incluye {{count}} consultas',
  zoomLinked_msg: 'No has vinculado tu cuenta con Zoom',
  googleLinked_msg: 'No has vinculado tu cuenta con Google',
  googleMeetLinked_msg: 'No has vinculado tu cuenta con Google Meet',
  googlePermissions_msg: 'No has habilitado permisos para tu cuenta asociada Google',
  appointment_v_patient: 'Selecciona el paciente',
  healthPlan: 'Tipo de consulta',
  appointment_v_healthPlan: 'Selecciona el tipo de consulta',
  price: 'Precio',
  appointment_v_price: 'Ingresa el precio',
  modality: 'Modalidad',
  modality_onsite: 'Presencial',
  modality_delivery: 'A domicilio',
  modality_online: 'Online',
  conferenceType: 'Videollamada',
  appointment_v_conferenceType: 'Selecciona el servicio de videollamada',
  conferenceType_other: 'Otro servicio',
  conferenceRoomUrl: 'Enlace',
  conferenceRoomUrl_extra: 'Si usas algún servicio como Videollamada de Whatsapp, deja este campo vacío',
  appointment_v_conferenceRoomUrl: 'Ingresa el enlace para acceder a la videollamada',
  appointment_v_conferenceRoomUrl_valid: 'Ingresa un enlace válido', // cambiar
  conferenceRoomUrl_placeholder: 'Link para acceder a la videollamada',
  location: 'Lugar de consulta',
  appointment_v_location: 'Selecciona el lugar de consulta',
  location_empty_pre: 'Agrega direcciones en ajustes generales.',
  goToGeneralSettings: 'Ir a Ajustes Generales',
  date: 'Fecha',
  date_existing: 'Tienes otra consulta agendada para esta fecha',
  date_existing_other: 'Tienes otras consultas agendadas para esta fecha',
  appointment_v_date: 'Selecciona una fecha',
  incomplete: 'Incompleto',
  minute: 'minuto',
  minute_other: 'minutos',
  startTime: 'Inicio',
  endTime: 'Fin',
  appointment_customForm: 'Elegir formulario',
  appointment_no_customForm: 'No enviar formulario',
  sendAppointmentInstantMailBoolean: '¿Enviar correo?',
  chose_option: 'Ingresa una opción',
  appointment_instat_mail_details: 'Se le enviará un correo electrónico al paciente indicándole los detalles de la consulta',
  appointment_conflict: 'Te recordamos que tienes una consulta agendada que hace conflicto con esta fecha y hora.',
  // Appointments
  appointment_in_progress: 'Consulta en proceso',
  appointment_in_progress_other: 'Consultas en proceso',
  appointment_scheduled: 'Consulta agendada',
  appointment_scheduled_other: 'Consultas agendadas',
  appointment_absence: 'Consulta no asistida',
  appointment_absence_other: 'Consultas no asistidas',
  appointment_finished: 'Consulta terminada',
  appointment_finished_other: 'Consultas terminadas',
  php_full: 'Todas las próximas consultas de este paquete ya han sido agendadas.',
  php_usedAppointments: 'consulta realizada',
  php_usedAppointments_other: 'consultas realizadas',
  hadPHP: 'No tiene un paquete...',
  neverHadPHP: 'Nunca ha comprado un paquete...',
  offerPHP: '¡Ofrécele alguno!',
  offerPHP_2: '¡Menciónaselos!',
  price_includes: 'El precio incluye {{count}} consulta',
  price_includes_other: 'El precio incluye {{count}} consultas',
  // PopupScheduleAppointment
  schedule_appointment: 'Agendar consulta',
  schedule_new_appointment: 'Agendar nueva consulta',
  watch_video_tutorial: 'Ver tutorial',
  taken_appointments: 'Todas las consultas de este paquete han sido agendadas',
  schedule_appointment_with: 'Agendaste una consulta con',
  schedule_appointment_success: 'Consulta agendada con éxito',
  done: '¡Listo!',
  error_google_calendar:
    'Hubo un problema agregando esta consulta en tu Google Calendar. Si el problema persiste, vuelve a vincular tu cuenta de Almendra con Google.',
  error_zoom: 'Hubo un problema creando la sala de conferencia en Zoom. Si el problema persiste, vuelve a vincular tu cuenta de Almendra con Zoom.',
  error_php: 'Todas las consultas de este paquete ya han sido agendadas. Refresca la página y vuelve a intentarlo.',
  // PopupNewMealPlan
  mealPlan_for: 'Dieta para',
  mealPlan_success: 'Plan Alimenticio creado con éxito.',
  mealPlan_add: 'Añadir plan alimenticio',
  mealPlan_create_new: 'Crear nuevo plan alimenticio',
  create: 'Crear',
  select_patient: 'Seleccionar paciente',
  mealPlan_v_patient: 'Selecciona al paciente',
  // PopupNewRecipe
  recipe_add: 'Añadir receta',
  recipe_add_new: 'Añadir nueva receta',
  recipe_create_new: 'Crear nueva receta',
  recipe_create: 'Crear receta',
  cancel: 'Cancelar',
  recipe_foodName: 'Nombre',
  recipe_v_foodName: 'Ingresa el nombre de la receta',
  tags: 'Etiquetas',
  recipe_v_tags: 'Selecciona al menos una etiqueta',
  portions: 'Porciones',
  recipe_v_portions: 'Ingresa la porción de la receta',
  people: 'personas',
  preparationTime: 'Tiempo de preparación',
  recipe_v_preparationTime: 'Ingresa el tiempo de preparación de la receta',
  minutes: 'minutos',
  cookingTime: 'Tiempo de cocción',
  recipe_v_cookingTime: 'Ingresa el tiempo de cocción de la receta',
  difficulty: 'Dificultad',
  recipe_v_difficulty: 'Selecciona la dificultad de la receta',
  difficulty_easy: 'Fácil',
  difficulty_medium: 'Medio',
  difficulty_hard: 'Difícil',
  publicRecipe: 'Compartir receta',
  recipe_share_true: 'Esta receta será compartida con otros usuarios de Almendra cuando la termines.',
  recipe_share_false: 'Esta receta no será compartida con otros usuarios de Almendra cuando la termines.',
  public: 'Público',
  private: 'Privado',
  // PopupCreatedFood
  createdFood_edit_success: 'Alimento editado con éxito',
  createdFood_edit_error: 'Parece que el alimento no pudo ser editado. Intenta nuevamente en unos momentos.',
  createdFood_add_success: 'Alimento creado con éxito',
  createdFood_add_error: 'Parece que el alimento no pudo ser creado. Intenta nuevamente en unos momentos.',
  createdFood_weights_limit: 'Has alcanzado el límite permitido de medidas caseras',
  createdFood_v_weight_name: 'Ingresa el nombre de la medida casera',
  createdFood_v_weight_name_duplicated: 'Ya has ingresado una medida casera con este nombre.',
  createdFood_manual_add: 'Ingresar alimento manualmente',
  createdFood_add: 'Añadir alimento',
  createdFood_add_new: 'Añadir nuevo alimento',
  createdFood_edit: 'Editar alimento',
  createdFood_create_new: 'Crear nuevo alimento',
  save_changes: 'Guardar cambios',
  name: 'Nombre',
  createdFood_v_foodName: 'Ingresa el nombre del alimento',
  category: 'Categoría',
  category_other: 'Categorías',
  createdFood_v_category: 'Selecciona la categoría',
  createdFood_nutrients: 'Detalle nutricional (opcional)',
  createdFood_nutrients_label: 'Detalle nutricional por cada: ',
  gram: 'gramo',
  gram_other: 'gramos',
  ounce: 'onza',
  ounce_other: 'onzas',
  add_other_nutrient: 'Añadir otro nutriente',
  createdFood_measurements: 'Medidas caseras (opcional)',
  quantity: 'Cantidad',
  createdFood_v_measurement_quantity: 'Ingresa la cantidad de la unidad casera',
  measurement: 'Medida',
  createdFood_extra_measurement_desc: 'Recuerda incluir solamente el nombre de la unidad y no con la cantidad.',
  tbsp: 'cucharada',
  createdFood_v_measurement_gram: 'Ingresa el peso en gramos',
  add_new_customMeasurement: 'Añadir nueva medida casera',
  // FoodProperties
  alcohol_g: 'Alcohol (g)',
  ash_g: 'Cenizas (g)',
  caffeine_mg: 'Cafeína (mg)',
  calcium_mg: 'Calcio (mg)',
  carbs_g: 'Carbohidratos (g)',
  carbs_avl_g: 'Carbohidratos disponibles (g)',
  alpha_carotene_ug: 'Alfa caroteno (ug)',
  beta_carotene_mcg: 'Beta caroteno (mcg)',
  cholesterol_mg: 'Colesterol (mg)',
  choline_mg: 'Colina (mg)',
  copper_mg: 'Cobre (mg)',
  beta_cryptoxanthin_mcg: 'Criptoxantina Beta (mcg)',
  calories_kcal: 'Calorías (kcal)',
  calories_kJ: 'Calorías (kJ)',
  monounsaturated_fat_g: 'Grasa monoinsaturada (g)',
  polyunsaturated_fat_g: 'Grasa poliinsaturada (g)',
  saturated_fat_g: 'Grasa saturada (g)',
  trans_fat_g: 'Grasas trans (g)',
  fiber_g: 'Fibra (g)',
  fluoride_mcg: 'Fluoruro (mcg)',
  vitaminB9_ug: 'Vitamina B9 (ug)',
  fructose_g: 'Fructosa (g)',
  galactose_g: 'Galactosa (g)',
  glucose_g: 'Glucosa (g)',
  iron_mg: 'Hierro (mg)',
  lactose_g: 'Lactosa (g)',
  lutein_zeaxanthin_mcg: 'Luteína Zeaxantina (mcg)',
  lycopene_mcg: 'Licopeno (mcg)',
  magnesium_mg: 'Magnesio (mg)',
  maltose_g: 'Maltosa (g)',
  manganese_mg: 'Manganeso (mg)',
  niacin_mg: 'Niacina (mg)',
  vitaminB5_mg: 'Vitamina B5 (mg)',
  phosphorus_mg: 'Fósforo (mg)',
  potassium_mg: 'Potasio (mg)',
  protein_g: 'Proteínas (g)',
  retinol_mcg: 'Retinol (mcg)',
  riboflavin_mg: 'Riboflavina (mg)',
  selenium_mcg: 'Selenio (mcg)',
  sodium_mg: 'Sodio (mg)',
  starch_g: 'Almidón (g)',
  sucrose_g: 'Sacarosa (g)',
  sugar_g: 'Azúcar (g)',
  theobromine_mg: 'Teobromina (mg)',
  thiamin_mg: 'Tiamina (mg)',
  fat_g: 'Grasas (g)',
  vitaminA_mcg: 'Vitamina A (mcg)',
  vitaminB12_ug: 'Vitamina B12 (ug)',
  vitaminB6_mg: 'Vitamina B6 (mcg)',
  vitaminC_mg: 'Vitamina C (mg)',
  vitaminD_mcg: 'Vitamina D (mcg)',
  vitaminD2_mcg: 'Vitamina D2 (mcg)',
  vitaminD3_mcg: 'Vitamina D3 (mcg)',
  vitaminE_mg: 'Vitamina E (mg)',
  water_g: 'Agua (g)',
  zinc_mg: 'Zinc (mg)',
  iodine_mcg: 'Yodo (mcg)',
  nonreducing_sugar_g: 'Azúcares no reductores (g)',
  reducing_sugar_g: 'Azúcares reductores (g)',
  aspartic_acid_g: 'Acido Aspartico (g)',
  threonine_g: 'Treonina (g)',
  serine_g: 'Serina (g)',
  glutamic_acid_g: 'Acido Glutámico (g)',
  proline_g: 'Prolina (g)',
  glycine_g: 'Glicina (g)',
  alanine_g: 'Alanina (g)',
  cysteine_g: 'Cisteina (g)',
  valine_g: 'Valina (g)',
  methionine_g: 'Metionina (g)',
  isoleucine_g: 'Isoleucina (g)',
  leucine_g: 'Leucina (g)',
  tyrosine_g: 'Tirosina (g)',
  phenylalanine_g: 'Fenilalanina (g)',
  histidine_g: 'Histidina (g)',
  lysine_g: 'Lisina (g)',
  arginine_g: 'Arginina (g)',
  tryptophan_g: 'Triptofano (g)',
  glycemic_index: 'Índice glicémico',
  glycemic_load: 'Carga glicémica',
  iron_non_heme_g: 'Hierro no hemo (g)',
  // PatientsMain
  top_revenue_empty: 'Actualmente no tienes pacientes que hayan pasado por consulta',
  add_pending_patient_error: 'El paciente no pudo ser agregado a tu registro de pacientes',
  add_pending_patient_error_accept: 'Ocurrió un error al aceptar la solicitud del paciente',
  add_pending_patient_error_reject: 'Ocurrió un error al rechazar la solicitud del paciente',
  add_pending_patient_error_description:
    'Por favor, refresca la página e inténtalo nuevamente. Si el problema persiste, comunícate con nosotros por el chat.',
  add_pending_patient_success_accept: 'Aceptaste la solicitud del paciente. El paciente se agregó a tu registro de pacientes',
  add_pending_patient_success_reject: 'El paciente fue removido de tus pacientes pendientes',
  pending_patients_empty: 'Actualmente no tienes pacientes pendientes por aceptar',
  patients_per_php: 'Pacientes por paquete activo',
  pending_patients_to_accept: 'Pacientes pendientes por aceptar',
  top_revenue_patients: 'Mejores pacientes del mes',
  // PatientsReportsMain
  patients_reports: 'Reporte de pacientes',
  patients_reports_description: 'Visualiza diferentes reportes de tus pacientes',
  // ActivePatientsReport
  active_patients_report_title: 'Pacientes por activación',
  view_all_periods: 'Ver todos los periodos',
  view_current_period: 'Ver periodo actual',
  active_patients_signup_date: 'Fecha de registro del paciente',
  active_patients_tigger_date: 'Fecha de activación',
  active_patients_period: 'Periodo de activación',
  active_patients_period_start: 'Fecha de inicio del periodo de activación',
  active_patients_report_file_title: 'Reporte de pacientes activos al {{-date, DD/MM/YYYY}}',
  active_patients_data: 'Datos',
  // NotificationsBadge
  notifications_linked_account_error: 'Hubo un error con tu cuenta vinculada de <1>{{accountName}}</1>.',
  notifications_linked_account_error_action: 'Por favor, vuelve a vincular tu cuenta para continuar usando este servicio.',
  // InactiveAccountModal
  inactive_account_title: '¡Hola, {{name}}!',
  inactive_account_description: 'Hace tiempo que no tienes actividad en tu cuenta de Almendra',
  inactive_account_description_2: 'Hemos programado la eliminación de tu cuenta para el <1>{{-date, LL}}</1>.',
  inactive_account_description_3:
    'En general, se eliminan las cuentas de Almendra que estén inactivas por más de 90 días a fin de mantener la seguridad, limitar la retención de datos y proteger tu privacidad. Se considera que hay inactividad cuando el usuario no se conecta a su cuenta de Almendra.',
  inactive_account_description_4:
    'En caso desees mantener tu cuenta, haz click en "Mantener mi cuenta activa". De lo contrario, bastaría que cierres esta página, procederemos a eliminar tu cuenta en la fecha mencionada.',
  keep_active_account: 'Mantener mi cuenta activa',
  keep_active_account_success: 'Listo! Tu cuenta acaba de ser activada nuevamente',
  // IdleTimer
  inactivity_in_account: 'Hubo un periodo de inactividad en tu cuenta',
  please_reload: 'Por favor, refresca la página para continuar usando Almendra.',
  reload: 'Refrescar',
  // AppointmentPDF
  appointment_report: 'Reporte de consulta',
  no_measure: 'No se evaluó',
  age: 'Edad',
  year: 'año',
  year_other: 'años',
  not_specified: 'No especificó',
  general_objective: 'Objetivo general',
  specific_objective: 'Objetivo específico',
  reach: 'Llegar a',
  before_date: 'antes del',
  upcoming_appointment: 'Próxima consulta',
  measurement_history: 'Historial de medida',
  bmi: 'Índice de masa corporal (IMC)',
  under_weight: 'Insuficiencia Ponderal',
  normal_weight: 'Peso Normal',
  over_weight: 'Sobrepeso',
  pre_obesity: 'Pre-obesidad',
  obesity_class_1: 'Obesidad Tipo 1',
  obesity_class_2: 'Obesidad Tipo 2',
  extreme_obesity_class_3: 'Obesidad Tipo 3',
  appointment_notes: 'Comentarios de la consulta',
  // MeasurementsWithUnitsWithTypeWithStateName
  weight: 'Peso',
  height: 'Altura',
  seatedHeight: 'Altura sentado',
  armSpan: 'Envergadura de brazos',
  femurBiepicondylarDiameter: 'Diámetro biepicondilar del fémur',
  humerusBiepicondylarDiameter: 'Diámetro biepicondilar del húmero',
  wristBistanoidDiameter: 'Diámetro biestiloideo de la muñeca',
  elbowDiameter: 'Diámetro del codo',
  ankleDiameter: 'Diámetro del tobillo',
  biacromialDiameter: 'Diámetro biacromial',
  bicrestalDiameter: 'Diámetro bicrestal',
  transverseThoraxDiameter: 'Diámetro transverso del tórax',
  anteroPosteriorThoraxDiameter: 'Diámetro antero-posterior del tórax',
  headGirth: 'Perímetro cefálico',
  shoulderGirth: 'Perímetro de los hombros',
  abdominalGirth: 'Perímetro abdominal',
  hipGirth: 'Perímetro de la cadera',
  waistGirth: 'Perímetro de la cintura',
  minimumWaistGirth: 'Perímetro mínimo de la cintura',
  maximumWaistGirth: 'Perímetro máximo de la cintura',
  wristGirth: 'Perímetro de la muñeca',
  thighGirth: 'Perímetro superior del muslo',
  forearmGirth: 'Perímetro del antebrazo',
  armGirth: 'Perímetro del brazo',
  armFlexedGirth: 'Perímetro del brazo en flexión',
  armRelaxedGirth: 'Perímetro del brazo relajado',
  neckGirth: 'Perímetro del cuello',
  ankleGirth: 'Perímetro del tobillo',
  calfGirth: 'Perímetro del gemelo',
  midThighGirth: 'Perímetro medio del muslo',
  chestGirth: 'Perímetro pectoral',
  abdominalSkinFold: 'Pliegue Cutáneo abdominal',
  midaxillarySkinFold: 'Pliegue Cutáneo axilar medio',
  bicepsSkinFold: 'Pliegue Cutáneo bicipital',
  medialCalfSkinFold: 'Pliegue Cutáneo de la Pierna',
  iliacCrestSkinFold: 'Pliegue Cutáneo de cresta iliaca',
  chestSkinFold: 'Pliegue Cutáneo pectoral',
  subscapularSkinFold: 'Pliegue Cutáneo subescapular',
  supraspinaleSkinFold: 'Pliegue Cutáneo supraespinal',
  tricepsSkinFold: 'Pliegue Cutáneo tricipital',
  frontThighSkinFold: 'Pliegue Cutáneo de muslo anterior',
  abdominalSubcutaneousSkinFold: 'Grasa subcutánea en el abdomen',
  ALT_TGP: 'ALT/TGP',
  AST_TGO: 'AST/TGO',
  albumin: 'Albúmina',
  amylase: 'Amilasa',
  apolipoproteinA: 'Apolipoproteína A',
  apolipoproteinB: 'Apolipoproteína B',
  basophils: 'Basófilos',
  totalBilirubin: 'Bilirrubina total',
  directBilirubin: 'Bilirrubina directa',
  calcium: 'Calcio',
  chlorides: 'Cloruros',
  copper: 'Cobre',
  HDLCholesterol: 'Colesterol HDL',
  LDLCholesterol: 'Colesterol LDL',
  totalCholesterol: 'Colesterol total',
  creatinine: 'Creatinina',
  eosinophils: 'Eosinófilos',
  erythrocytes: 'Eritrocitos',
  ferritin: 'Ferritina',
  heartRate: 'Frecuencia cardíaca',
  phosphorus: 'Fósforo',
  gammaGT: 'Gamma GT',
  glucagon: 'Glucagón',
  fastingBloodSugar: 'Glucemia en ayunas',
  afterEatingBloodSugar: 'Glucemia después de comer',
  redBloodCells: 'Glóbulos Rojos',
  hematocrit: 'Hematocrito',
  hemoglobin: 'Hemoglobina',
  a1cHemoglobin: 'Hemoglobina A1c',
  iron: 'Hierro',
  parathyroidHormone_PTH: 'Hormona paratiroidea (PTH)',
  igE: 'IgE',
  igG: 'IgG',
  igM: 'IgM',
  leukocytes: 'Leucocitos',
  lymphocytes: 'Linfocitos',
  magnesium: 'Magnesio',
  monocytes: 'Monocitos',
  nH3: 'NH3',
  neutrophils: 'Neutrófilos',
  platelets: 'Plaquetas',
  potassium: 'Potasio',
  prealbumin: 'Prealbúmina',
  diastolicBloodPressure: 'Presión arterial diastólica',
  systolicBloodPressure: 'Presión arterial sistólica',
  prolactin: 'Prolactina',
  reactiveProteinC: 'Proteína C reactiva',
  proteinsInUrine: 'Proteínas en la orina',
  totalProteins: 'Proteínas totales',
  sodium: 'Sodio',
  thyrotropinTSH: 'Tirotropina (TSH)',
  thyroxine: 'Tiroxina (T4)',
  transferrin: 'Transferrina',
  triglycerides: 'Triglicéridos',
  triiodothyronine_T3: 'Triyodotironina (T3)',
  urea: 'Urea',
  vitaminA: 'Vitamina A',
  vitaminB12: 'Vitamina B12',
  vitaminB6: 'Vitamina B6',
  vitaminD: 'Vitamina D',
  vitaminE: 'Vitamina E',
  zinc: 'Zinc',
  arterialPh: 'pH arterial',
  ascorbicAcid: 'Ácido ascórbico (vitamina C)',
  folicAcid: 'Ácido fólico',
  uricAcid: 'Ácido úrico',
  metabolicAge: 'Edad metabólica',
  extracellularFluid: 'Fluido extracelular',
  intracelullarFluid: 'Fluido intracelular',
  totalBodyWater: 'Agua corporal total',
  bodyMassIndex: 'Índice de masa corporal',
  visceralFatIndex: 'Índice de grasa visceral',
  waistHeightRatio: 'Índice cintura-altura',
  waistHipRatio: 'Índice cintura-cadera',
  bodyDensity: 'Densidad corporal',
  visceralFat: 'Grasa visceral',
  fatMass: 'Masa grasa',
  muscleMass: 'Masa muscular',
  residualMass: 'Masa residual',
  fatFreeMass: 'Masa libre de grasa',
  boneMass: 'Masa ósea',
  leanBodyMass: 'Masa magra',
  visceralFatPercentage: 'Porcentaje de grasa visceral',
  bodyFatPercentage: 'Porcentaje de grasa',
  residualMassPercentage: 'Porcentaje de masa residual',
  fatFreeMassPercentage: 'Porcentaje de masa libre de grasa',
  leanBodyMassPercentage: 'Porcentaje de masa magra',
  bodyWaterPercentage: 'Porcentaje de agua corporal',
  muscleMassPercentage: 'Porcentaje de masa muscular',
  boneMassPercentage: 'Porcentaje de masa ósea',
  idealBodyWeight: 'Peso ideal',
  adjustedBodyWeight: 'Peso corregido',
  acromionRadial: 'Acromial-radial',
  radialeStylon: 'Radial-estiloideo',
  wristToMiddleFinger: 'Estiloideo-medio Dactilar',
  iliospinaleHeight: 'Altura Ileoespinal',
  trochanterionHeight: 'Altura trocantérea',
  trochanterionTibialeLaterale: 'Trocantéreo-Tibial lateral',
  tibialeHeight: 'Altura tibial lateral',
  tibialeMedialetoMalleolar: 'Tibial medial - Maleolar',
  footLength: 'Longitud del Pie',
  // ObjectivesSummary
  objective: 'Objetivo',
  objective_patient_undefined:
    '{{name}} aún no tiene un objetivo definido. Completa el resto de la sección del objetivo preciso para ver el análisis.',
  value: 'Valor',
  initial: 'Inicial',
  appointment: 'Consulta',
  appointment_other: 'Consultas',
  current: 'Actual',
  target: 'Deseado',
  cant_calculate_bmi: 'No es posible calcular el IMC del paciente',
  bmi_short: 'Índice de masa corporal',
  objectives_need_two_measurements: 'Se necesita al menos dos medidas desde la fecha de inicio del objetivo para poder ver el progreso',
  objective_increased: '{{patient}} subió {{difference}} {{unit}}',
  objective_decreased: '{{patient}} bajó {{difference}} {{unit}}',
  objective_maintain: '{{patient}} matuvo su {{measurement}}',
  since_last_appointment: 'desde la última consulta',

  // Error500
  error_bug_title: 'Parece que hubo un error.',
  error_bug_description: 'Hemos notificado a nuestro equipo de desarrolladores sobre este inconveniente para solucionarlo lo más pronto posible.',
  error_bug_cta: 'Ir a Inicio',
  // SessionTimer
  session_expired: 'Tu sesión ha expirado',
  session_expired_des: 'Por seguridad, hacemos que las sesiones expiren cada mes. Para continuar, ingresa tu contraseña.',
  // ExpertAuthForm
  forgot_password: 'Olvidé mi contraseña',
  great: '¡Genial!',
  validating_info: 'Comprobando datos...',
  log_in: 'Iniciar sesión',
  enter_your_password: 'Ingresa tu contraseña',
  password: 'Contraseña',
  email_v_valid: 'Ingresa un correo válido',
  email_2: 'Correo electrónico',
  hello: '¡Hola',
  welcome_to_software: '¡Bienvenido a Almendra!',
  enter_my_account: 'Ingresar a mi cuenta',
  want_login_mobile: '¿Quieres ingresar a través de este dispositivo móvil?',
  mobile_compatibility_disclaimer:
    'Para asegurar que tu experiencia en Almendra sea la mejor, ingresa a tu cuenta a través de una computadora o una tablet.',
  account_verified: 'Tu cuenta ha sido confirmada con éxito',
  improve_your_experience: 'Mejora tu experiencia',
  oops: '¡Ups!',
  expired_link: 'El enlace ingresado es inválido o ha expirado.',
  // PasswordUpdate
  reset_password: 'Restablecer contraseña',
  enter_new_password: 'Ingresa tu nueva contraseña',
  new_password: 'Nueva contraseña',
  confirm_password: 'Confirmar contraseña',
  reset_password_error: 'Hubo un error guardando tu nueva contraseña.',
  reset_password_success: 'Tu contraseña fue cambiada con éxito.',
  password_validator_lowercase: 'Mínimo una letra en minúscula',
  password_validator_uppercase: 'Mínimo una letra en mayúscula',
  password_validator_length: 'Mínimo 8 caracteres',
  password_validator_number: 'Mínimo un número',
  password_validator_match: 'Las contraseñas coinciden',
  // FeaturesPoll
  please_choose_an_option: 'Por favor, elige una opción',
  features_thanks: '¡Gracias por participar de esta encuesta!',
  choose_new_features: '¡Elige las nuevas funcionalidades!',
  featuresPoll_title: '¿Qué te gustaría tener en Almendra?',
  featuresPoll_description: 'Elige las nuevas funcionalidades de Almendra. ¡Tu opinión es la más importante!',
  featuresPoll_pick_others:
    'Por favor, especifica qué funcionalidad te gustaría tener y por qué te gustaría tenerla. Detalla lo más que puedas para que podamos entenderte mejor.',
  featuresPoll_pick_option: '¿Por qué razón prefieres esto? Detalla lo más que puedas para que podamos entenderte mejor.',
  send: 'Enviar',
  featuresPoll_vote_submitted: '¡Listo, tu voto ha sido registrado!',
  featuresPoll_submit_description:
    'Si tu funcionalidad elegida no está como favorita, no te preocupes, la volveremos a evaluar próximamente. Cualquier duda o consulta no dudes en contactarnos!',
  featuresPoll_votes: 'Porcentaje de usuarios que votaron por esta funcionalidad',
  // FeedbackPopup
  feedbackPopup_title: '¿Qué tan feliz estás con Almendra?',
  feedbackPopup_suggestion: '¿Tienes alguna sugerencia o comentario?',
  feedbackPopup_suggestion_placeholder: 'Escribe tu sugerencia y ayúdanos a mejorar',
  feedbackPopup_submitted_title: '¡Gracias por tu ayuda!',
  feedbackPopup_submitted_description: 'Tomaremos tus comentarios en cuenta',
  feedbackPopup_validator: 'Por favor, elige un puntaje o escribe un comentario',
  successfully_sent: '¡Enviado satisfactoriamente!',
  // FinancialsExpenses
  workTools: 'Accesorios de trabajo',
  others: 'Otros',
  expenses_empty: '¡Todavía no tienes gastos! Aparecerán apenas registres un gasto.',
  expenses_for_current_period: 'Gastos del periodo',
  // FinancialsIncome
  income_empty: '¡Todavía no tienes ingresos! Aparecerán apenas realices una consulta o registres un ingreso.',
  income_edit_disclaimer: 'Solo se pueden editar ingresos extra, no ingresos provenientes de consultas',
  income_for_current_period: 'Ingresos del periodo',
  // NewTable
  amount: 'Monto',
  extra_income: 'Ingreso extra',
  edit_recurrent_expense_question: '¿Deseas editar solo este gasto, o todas veces que el gasto recurrente se ha registrado?',
  going_to_edit_recurrent_expense: 'Estas apunto de editar un gasto recurrente',
  recurrent_expense: 'Gasto recurrente',
  showColumns: 'Mostrar columnas',
  filters_all: 'Todos',
  filters: 'Filtros',
  reset: 'Restablecer',
  viewColumns: 'Ver Columnas',
  filterTable: 'Filtrar Tabla',
  next: 'Siguiente',
  previous: 'Anterior',
  rowsPerPage: 'Filas por página',
  displayRows: 'de',
  order: 'Ordenar',
  financials_empty_table: 'No se encontraron {{name}} en este periodo de tiempo',
  transactions: 'transacciones',
  expenses: 'gastos',
  income: 'ingresos',
  // JoyrideTour
  joyride_back: 'Atrás',
  joyride_close: 'Cerrar',
  joyride_last: 'Terminar tour',
  joyride_next: 'Siguiente',
  joyride_skip: 'Cerrar tour',
  // ToursHelp - falta traducción videos y variable "videos"
  toursHelp_title: 'Videos Tutoriales',
  toursHelp_description: 'Descubre cómo aprovechar Almendra al máximo',
  view: 'Ver',
  tutorial_start_appointment: 'Cómo iniciar una consulta',
  tutorial_schedule_appointment: 'Cómo agendar una consulta',
  tutorial_appointment_process: 'El proceso de la consulta',
  tutorial_create_meal_plan: 'Cómo crear un plan alimenticio',
  tutorial_flexible_meal_plan_1: 'Cómo elaborar un plan alimenticio flexible - Parte 1',
  tutorial_flexible_meal_plan_2: 'Cómo elaborar un plan alimenticio flexible - Parte 2',
  tutorial_flexible_meal_plan_3: 'Cómo elaborar un plan alimenticio flexible - Parte 3',
  tutorial_strict_meal_plan_1: 'Cómo elaborar un plan alimenticio estricto - Parte 1',
  tutorial_strict_meal_plan_2: 'Cómo elaborar un plan alimenticio estricto - Parte 2',
  tutorial_strict_meal_plan_3: 'Cómo elaborar un plan alimenticio estricto - Parte 3',
  tutorial_meal_plan_from_other: 'Cómo elaborar un plan alimenticio a partir de otro',
  tutorial_meal_plan_from_template: 'Cómo elaborar un plan alimenticio a partir de una plantilla',
  tutorial_create_recipe: 'Cómo crear una receta',
  tutorial_create_service: 'Cómo crear paquetes nutricionales',
  tutorial_setup_agenda: 'Cómo configurar la agenda (calendario)',
  tutorial_configure_account: 'Cómo configurar tu cuenta',
  tutorial_online_booking: 'Cómo configurar el sistema de reservas online',
  tutorial_financials: 'Cómo registrar ingresos y gastos (finanzas)',
  // PopupIndicationsTemplate
  indicationsTemplate_v_title: 'Por favor, ingresa un título para la plantilla de indicaciones.',
  indicationsTemplate_v_empty: 'Las indicaciones no pueden estar vacías.',
  indicationTemplate_create_success: 'Plantilla de indicaciones creada con éxito',
  indicationTemplate_edit_success: 'Plantilla de indicaciones editada con éxito',
  edit: 'Editar',
  indicationTemplate_create: 'Crear plantilla de indicaciones',
  indicationTemplate_edit_title: 'Edita la plantilla de indicaciones',
  indicationTemplate_create_title: 'Agrega una nueva plantilla de indicaciones',
  save_template: 'Guardar plantilla',
  create_template: 'Crear plantilla',
  title: 'Título',
  indicationTemplate_title_placeholder: 'Ej: Plantilla para diabéticos',
  indications: 'Indicaciones',
  indicationsTemplate_delete_indication: '¿Deseas borrar esta indicación?',
  indicationsTempalte_add_indication: 'Agregar indicación',
  // UseIndicationsTemplates
  templates: 'Plantillas',
  useIndicationTemplates_title: 'Selecciona una plantilla de indicaciones para insertarla al plan alimenticio',
  your_templates: 'Tus plantillas',
  insert: 'Insertar',
  system_templates: 'Plantillas del sistema',
  // BrowserCompatibilityHoc
  unsupported_browser: 'Estás utilizando un navegador que no soportamos.',
  unsupported_browser_version: 'Estás utilizando una versión de {{browserName}} ({{browserVersion}}) que no soportamos.',
  unsupported_browser_details: 'Por favor, {{name}}migra a otro navegador para tener una mejor experiencia en Almendra.',
  update_browser_version: 'actualiza la versión de tu navegador o ',
  download_latest_browser_version: 'Dale click a este link para ver una guía de cómo descargar la última versión de tu navegador.',
  download_google_chrome: 'Dale click a este link para descargar Google Chrome.',
  // OnBoardingGoToVideo
  go_to_video: 'Ir al video',
  // OnBoardingInitialModal
  onboarding_b2c_content_0: '{{name}}, todavía no contamos con planes alimenticios para el público',
  onboarding_b2c_content_1:
    'Muy pronto tendremos esta funcionalidad a un precio muy accesible y te contactaremos con un <1>descuento especial</1>. Pero por ahora solo somos un software hecho para profesionales de nutrición.',
  onboarding_b2c_content_2:
    'Si crees que esto es un error y si eres un profesional de nutrición, por favor contáctate con nosotros a <1>soporte@almendra.io</1> o escríbenos por el chat.',
  demo_mealPlan: 'Plan alimenticio (demo)',
  error_message: 'Hubo un problema... Inténtalo nuevamente en unos minutos.',
  onboarding_start: '¡Empecemos!',
  save: 'Guardar',
  onboarding_lets_start: '¡Empecemos a usar Almendra!',
  onboarding_contact_prospect: 'Un miembro del equipo se contactará contigo para acompañarte en esta etapa inicial',
  onboarding_tour_mealPlan: 'Quiero hacer un plan alimenticio',
  onboarding_tour_appointment: 'Quiero hacer una consulta',
  onboarding_tour_skip: 'Lo haré después',
  // OnBoardingMobileModal
  onboarding_width_compatible:
    'Mejora tu experiencia a través de una computadora, laptop o tablet. Por ahora no podrás usar todas las funcionalidades, pero aquí tienes unos videos para que sepas de qué se trata.',
  mealPlan: 'Plan alimenticio',
  mealPlan_other: 'Planes alimenticios',
  online_booking: 'Reservas online',
  // MetsDropdownMenu
  calculate_pal: 'Calcular NAF',
  // PatientDisciplineCard
  patient_discipline_card_tooltip: 'Esta actividad no se encuentra en la lista de actividades con METs',
  mets_activity: 'La actividad tiene <1>{{name}} METs</1>.',
  mets_activity_on_patient: '{{patient}} quemará aproximadamente <1>{{calories}}</1> calorías semanales.',
  minutes_per_session: 'Minutos por sesión',
  minutes_per_session_placeholder: 'Ej: 120',
  activity_frequence: 'Frecuencia de práctica',
  monday_short: 'Lun',
  tuesday_short: 'Mar',
  wednesday_short: 'Mié',
  thursday_short: 'Jue',
  friday_short: 'Vie',
  saturday_short: 'Sáb',
  sunday_short: 'Dom',
  // PopupActivePatientInfo
  click_for_more_info: 'Click para más información',
  whats_an_active_patient: '¿Qué es un paciente activo?',
  active_patient_explanation_1:
    'Un paciente es activo si ha realizado una consulta o ha tenido un plan alimenticio en uso en este período de facturación.',
  for_example: 'Por ejemplo',
  active_patient_explanation_2:
    'Supongamos que te uniste a Almendra el 10 de marzo. Eso quiere decir que tu periodo de facturación va del 10 de marzo al 10 de abril.',
  active_patient_explanation_3:
    'Has atendido a Luis, María y Carmen entre estas dos fechas. Carmen vino dos veces a tu consultorio, por lo tanto has tenido 4 consultas en este periodo.',
  active_patient_explanation_4:
    'Como has tenido 3 pacientes distintos que han tenido consultas, significa que tienes 3 pacientes activos. Si el mismo paciente tiene 4 consultas en un periodo de facturación, solo habrías tenido 1 paciente activo durante ese periodo.',
  active_patient_explanation_5:
    'Exactamente el mismo caso aplica a los planes alimenticios. Sin embargo, ya que los planes alimenticios ocurren durante un intervalo de días y no un momento puntual (como el caso de la consulta).',
  active_patient_explanation_6:
    'Si tienes un plan alimenticio que abarca dos periodos de facturación, el paciente se considerará activo en ambos periodos.',
  active_patient_explanation_7:
    'Con el mismo ejemplo mencionado antes, si tu periodo de facturación va del 10 de marzo al 10 de abril, y el plan alimenticio para tu paciente abarca del 6 de abril al 13 de abril. El paciente contará como activo en ambos periodos.',
  active_patient_actual: 'En lo que va del mes, tienes {{count}} paciente activo.',
  active_patient_actual_other: 'En lo que va del mes, tienes {{count}} pacientes activos.',
  have_doubts: '¿Tienes alguna duda?',
  contact_us_mail_pre: 'Escríbenos a ',
  contact_us_mail_post: ', estamos siempre disponibles para ti.',
  // ScheduleMain
  dragAndSchedule_validator: 'Selecciona un horario futuro para poder agendar una consulta',
  drag_event_edit_validator: 'Estás arrastrando un evento a una fecha pasada',
  to_date: 'a',
  drag_event_edit_title: '¿Deseas cambiar el horario de esta consulta agendada?',
  before: 'Antes',
  now: 'Ahora',
  edit_schedule_appointment_email_send: '¿Enviar correo de reprogramación?',
  edit_schedule_appointment_email_send_description:
    'En caso marques "Sí", se le enviará un correo electrónico al paciente indicándole los nuevos detalles de la consulta',
  edit_scheduled_appointment_success: 'Editaste la consulta agendada con {{name}}',
  yes_change: 'Sí, cambiar',
  loading_info: 'Cargando información...',
  empty_schedule_finished: 'No tienes consultas terminadas para esta semana',
  schedule_finished: 'Terminadas',
  schedule_finished_appointments_week: 'Tienes {{count}} consulta terminada para esta semana',
  schedule_finished_appointments_week_other: 'Tienes {{count}} consultas terminadas para esta semana',
  empty_schedule_scheduled: 'No tienes consultas agendadas para esta semana',
  schedule_scheduled: 'Agendadas',
  schedule_scheduled_appointments_week: 'Tienes {{count}} consulta agendada para esta semana',
  schedule_scheduled_appointments_week_other: 'Tienes {{count}} consultas agendadas para esta semana',
  empty_schedule_cancelled: 'No tienes consultas canceladas para esta semana',
  schedule_cancelled: 'Canceladas',
  schedule_cancelled_appointments_week: 'Tienes {{count}} consulta cancelada para esta semana',
  schedule_cancelled_appointments_week_other: 'Tienes {{count}} consultas canceladas para esta semana',
  loading_appointments: 'Cargando consultas...',
  weekly_list: 'Agenda semanal',
  monthly_list: 'Agenda mensual',
  // CopyToClipboard
  link_copied: 'Enlace copiado',
  copy_this_link_description: 'Copia este enlace una vez que acabes y pégalo donde desees',
  copy_link: 'Copiar enlace',
  // HoursAndMinutesInput
  mets_activity_minutes_validator: 'Ingresa el tiempo practicado al día de la actividad',
  // NoMatchEquivalentsModal
  no_match_equivalents_title: 'Todavía no has creado equivalencias para este alimento',
  no_match_equivalents_description: 'Recuerda que puedes crear equivalencias alimentarias en la sección',
  understood: 'Entendido',
  feeding: 'Alimentación',
  equivalents: 'Equivalencias',
  // TagInput
  tag_input_validator: 'Esta opción ya ha sido incluida',
  // SomatoChart
  somatograph: 'Somatografía',
  endomorphy: 'Endomorfia',
  ectomorphy: 'Ectomorfia',
  mesomorphy: 'Mesomorfia',
  // VerificationPeriodExcededAlert
  resend_verification_email_success: 'Acabamos de reenviarte el correo de confirmación',
  resend_verification_email_error: '¡Ups! Parece que hubo un error al intentar reenviar el correo de confirmación. Inténtalo en unos momentos.',
  verification_email_title: 'Todavía no has verificado tu correo',
  verification_email_description:
    'Necesitamos saber que tu correo electrónico es auténtico para poder continuar. Te recordamos que el correo puede tardar hasta 5 minutos en llegar y podría estar en tu bandeja de promociones y/o spam.',
  // AlertPatientsLimit
  click_here_for_info: 'Click aquí para más información',
  active_patient: 'paciente activo',
  active_patient_other: 'pacientes activos',
  patientsLimit_hit:
    '<0 /> Si tienes <2>{{count}} paciente activo más</2> hasta el <4>{{- date, dddd DD/MMMM/YYYY}}</4>, tendrás que mejorar tu plan.',
  patientsLimit_hit_other:
    '<0 /> Si tienes <2>{{count}} pacientes activos más</2> hasta el <4>{{- date, dddd DD/MMMM/YYYY}}</4>, tendrás que mejorar tu plan.',
  patientsLimit_gracePeriod:
    'Te otorgamos un plazo de <1>3 días más, hasta el {{- date, DD/MM/YYYY}}</1>, para que elijas un plan que permita más de {{patientsLimit}} pacientes activos al mes y sigas disfrutando de Almendra. De lo contrario, tu cuenta será inhabilitada hasta que mejores tu plan.',
  patientsLimit_gracePeriod_onDemand:
    'Te otorgamos un plazo de <1>3 días más, hasta el {{- date, DD/MM/YYYY}}</1>, para que te suscribas al Plan Pro que permite tener más de {{patientsLimit}} pacientes activos al mes y sigas disfrutando de Almendra. De lo contrario, tu cuenta será inhabilitada hasta tu próxima fecha de facturación, en la cual se restablecerá tus pacientes activos.',
  trialEnding_alert: '<0 /> Falta {{count}} día para que acabe tu periodo de prueba. ¡No esperes al último día!',
  trialEnding_alert_other: '<0 /> Faltan {{count}} días para que acabe tu periodo de prueba. ¡No esperes al último día!',
  transactionFailed_alert:
    'Hubo un error en el cobro mensual del <1>{{planName}}</1> para el periodo del <3>{{- period_start, DD/MM/YYYY}}</3> al <5>{{- period_end, DD/MM/YYYY}}</5>. Haz <7>click aquí</7> para regularizar tu suscripción.',
  patientsLimit_reached: 'superaste el número de pacientes activos que permite tu plan y queremos ayudarte a seguir creciendo. ',
  patientsLimit_reached_date: 'El {{- date, DD/MMMM/YYYY}}',
  upgrade_plan: 'Mejorar plan',
  subscribe: 'Suscribirme',
  // NewPatientForm
  newPatient_firstName_v: 'Ingresa tu nombre',
  newPatient_lastName_v: 'Ingresa tu apellido',
  newPatient_gender_v: 'Selecciona tu sexo',
  newPatient_phone_v_required: 'Ingresa tu número de teléfono',
  newPatient_phone_v_valid: 'Ingresa un número de teléfono válido',
  newPatient_email_extra:
    'Con tu correo electrónico podrás ingresar a tu cuenta de Almendra, donde podrás visualizar tus planes alimenticios, tu progeso y agendar consultas con tu nutricionista.',
  newPatient_email_v_valid: 'Ingresa un correo electrónico válido',
  newPatient_email_v_required: 'Ingresa tu correo electrónico',
  // ReviewAndCofirm
  notes: 'Comentarios',
  additional_notes: 'Comentarios adicionales',
  notes_v_max: 'Máximo 120 caracteres',
  notes_placeholder: '(opcional) Agrega algún comentario',
  // PopupDeleteExpertConfirmation
  you_have_an_active_subscription: 'Tienes una suscripción activa',
  cancel_before_delete_account: 'Para eliminar tu cuenta, cancela la suscripción que tienes.',
  delete_my_account: 'Eliminar mi cuenta',
  delete_account_title_step_0: '¿Piensas dejarnos?',
  delete_account_title_step_1: '¿Realmente nos abandonas?',
  delete_account_title_step_2: 'Esta es tu última oportunidad',
  continue: 'Continuar',
  delete_account_close: 'Cerrar, quiero quedarme en Almendra',
  delete_account_description_step_0:
    'Recuerda que Almendra es el mejor software para expertos en nutrición del mercado. Aquí algunos de los motivos:',
  why_almendra_0: 'Base de datos de alimentos oficial del país donde te encuentras.',
  why_almendra_1: 'Gestionas el seguimiento de los planes alimenticios fácilmente gracias a la cuenta para pacientes.',
  why_almendra_2: 'Analítica de data profunda de todos los ámbitos de tu negocio.',
  why_almendra_3: 'Información precisa de todos tus pacientes que nunca se pierde.',
  contact_us_delete_account: 'Escríbenos a <1>soporte@almendra.io</1> si quieres conversar con nosotros, estamos siempre disponibles para ti.',
  delete_account_description_step_1:
    'Nos gustaría saber en qué hemos fallado. Por favor, cuéntanos el/los motivo(s) por el/los que te vas (puedes seleccionar más de una casilla):',
  delete_account_option_hardToUse: 'Muy difícil de usar',
  delete_account_option_highPrice: 'Precio muy elevado',
  delete_account_option_lackOfNutritionInfo: 'Falta de información sobre temas de nutrición',
  delete_account_option_badCustomerSupport: 'Mal servicio al cliente (demoran en contestar, mal trato u otros)',
  delete_account_option_badUI: 'Diseño poco atractivo',
  delete_account_option_notAttendingPatients: 'Actualmente no estoy atendiendo pacientes',
  enter_here: 'Escribe aquí',
  pre_delete_account:
    'Si le das click a este botón, tu cuenta con correo <1>{{email}}</1> será permanentemente eliminada y te redirigiremos a la página principal de <3>Almendra</3>.',
  apologize: 'Perdón por no haber satisfecho tus necesidades.<1 />No dudes que mejoraremos y esperamos encontrarnos de nuevo en el futuro. ',
  delete_account_reason_min_length: 'Por favor, explica con al menos 10 caracteres cómo podemos mejorar',
  delete_account_checkbox_required: 'Por favor, selecciona al menos una razón',
  // ExpertSettings
  profile: 'Perfil',
  services: 'Paquetes Nutricionales',
  web_tools: 'Herramientas Web',
  general_settings: 'Ajustes Generales',
  mailing: 'Mailing',
  visual: 'Visual',
  security: 'Seguridad',
  subscription: 'Suscripción',
  googleAccountLinked_success: 'Vinculación con Google realizada con éxito',
  googleAccountLinked_error: 'Hubo un error vinculando tu cuenta con Google',
  zoomAccountLinked_success: 'Vinculación con Zoom realizada con éxito',
  zoomAccountLinked_error: 'Hubo un error vinculando tu cuenta con Zoom',
  mercadoPagoAccountLinked_success: 'Vinculación con MercadoPago realizada con éxito',
  mercadoPagoAccountLinked_error: 'Hubo un error vinculando tu cuenta con MercadoPago',
  mercadoPagoAccountLinked_description: 'Aprende cómo usar esta integración en el siguiente artículo: <1>Almendra y MercadoPago</1>',
  payPalAccountLinked_success: 'Vinculación con PayPal realizada con éxito',
  payPalAccountLinked_error: 'Hubo un error vinculando tu cuenta con PayPal',
  // EquivalentsMain
  equivalence_default_edit_content: 'Al confirmar, el grupo de equivalencias pasará a ser tuyo. Te recordamos que esta acción es irreversible.',
  equivalence_default_edit_error:
    'Ocurrió un error mientras editabas el grupo de equivalencia. Por favor, refresca la página e inténtalo nuevamente.',
  equivalence_default_edit_title: 'Estás editando un grupo de equivalencias del {{name}}, ¿deseas continuar?',
  yes_edit: 'Sí, editar',
  equivalence_edit_success: 'Equivalencia editada con éxito',
  equivalence_create_success: 'Equivalencia creada con éxito',
  equivalence_group: 'Grupo de equivalencias',
  equivalence_groupName_v: 'El nombre del grupo de equivalencias no puede estar vacío',
  update_success: 'Actualizado con éxito',
  equivalence_delete_title: '¿Deseas eliminar este grupo de equivalencias?',
  equivalence_delete_content_0:
    'En caso hayas usado este grupo en algún plan alimenticio con el formato de Intercambios, se removerá como grupo de <1>todos</1> los planes alimenticios y plantillas.',
  equivalence_delete_content_1:
    'Hacer esto no es recomendado y podría generar distorción en los planes de tus pacientes. Te recordamos que esta acción es irreversible.',
  yes_delete: 'Sí, eliminar',
  equivalence_delete_success: 'Equivalencia eliminada con éxito',
  food_add_success: 'Alimento agregado con éxito',
  food_edit_success: 'Alimento editado con éxito',
  food_delete_success: 'Alimento eliminado con éxito',
  search_by_group: 'Buscar por grupo',
  loading_equivalents_groups: 'Cargando grupos de equivalencias...',
  groupName: 'Grupo',
  equivalence_groupName_placeholder: 'Nombre del grupo de equivalencias',
  equivalence_groupName_details: 'Mi grupo de equivalencias',
  equivalence_default_groupName_details: 'Grupo de equivalencias del {{name}}',
  equivalence_stats_average: 'Promedio de calorías y macronutrientes',
  fat_short: 'GRA',
  fat_one_letter: 'G',
  carbs_short: 'CHO',
  carbs_one_letter: 'C',
  protein_short: 'PRO',
  protein_one_letter: 'P',
  delete_group: 'Eliminar grupo',
  create_equivalence: 'Crear equivalencia',
  equivalence_empty: 'No tienes listas de equivalencias nutricionales creadas.',
  equivalence_empty_search: 'No se encontraron grupos de equivalencias con la búsqueda "<1>{{name}}</1>".',
  equivalence_groupDescription_placeholder: 'Ingresa una descripción para este grupo de equivalencias',
  equivalence_foods: 'Alimentos equivalentes',
  equivalence_group_empty: 'Agrega un alimento al grupo de equivalencias',
  // FavoriteFoodsTable
  favoriteFoods_edit_error: 'Ocurrió un error al realizar el cambio de tu alimento favorito. Por favor, refresca la página.',
  favoriteFoods_delete_title: '¿Deseas remover como favorito este alimento?',
  irreversible_action: 'Te recordamos que esta acción es irreversible.',
  search_food: 'Buscar alimento',
  unit: 'Unidad',
  food: 'Alimento',
  food_other: 'Alimentos',
  calories: 'Calorías',
  no_value: 'Sin valor',
  fat: 'Grasa',
  carbs: 'Carbohidrato',
  prot: 'Proteína',
  favoriteFoods: 'Alimentos favoritos',
  favoriteFoods_empty_search: 'No se encontraron alimentos favoritos con la búsqueda "<1>{{name}}</1>".',
  favoriteFoods_empty: 'No has marcado tus alimentos favoritos',
  favoriteFoods_empty_description:
    'Agrega los alimentos que siempre utilizas en tus planes alimenticios como favoritos para facilitarte en la elaboración de planes alimenticios.',
  // PatientPhysicalActivity
  very_sedentary: 'Muy Sedentario',
  sedentary: 'Sedentario',
  sedentary_desc: '(trabajo de escritorio, poco o nada de ejercicio)',
  lightly_active: 'Ligeramente Activo',
  lightly_active_desc: '(ejercicio ligero/deportes 1-3 veces por semana)',
  moderately_active: 'Moderadamente Activo',
  moderately_active_desc: '(ejercicio moderado/deportes 3-5 veces por semana)',
  very_active: 'Muy Activo',
  very_active_desc: '(ejercicio intenso/deportes 6-7 veces por semana)',
  extra_active: 'Extremadamente Activo',
  extra_active_desc: '(ejercicio intenso diario, jornada laboral intensa)',
  manually_calculated_pal: '(NAF calculado manualmente)',
  pal: 'NAF',
  // StatsCard
  since_you_joined: 'desde que te uniste a Almendra',
  // PatientsBySexBarGraph
  men: 'Hombres',
  women: 'Mujeres',
  // PatientsGraph
  total: 'Total',
  new: 'Nuevo',
  new_other: 'Nuevos',
  // PlanUsageGraph
  accumulated_active_patients: 'Pacientes activos acumulados',
  active_patients_plan_empty: 'Aquí podrás visualizar tu actividad del periodo cuando te suscribas a un plan',
  range_from: 'del',
  range_to: 'al',
  // NewBillingMain
  plans_fetch_error_title: 'No fue posible cargar los planes de Almendra',
  plans_fetch_error_content: 'Por favor, refresca la página e inténtalo de nuevo',
  verifying_subscription: 'Verificando suscripción',
  regularize_subscription_title: 'Regulariza tu suscripción',
  regularize_subscription_content_0:
    'El {{- date, DD/MM/YYYY}} hubo un problema con tu método de pago y no se pudo hacer el cobro de <1>$ {{failedAmount}}</1> por el periodo de tu suscripción al <3>Plan {{planName}}</3>.',
  regularize_subscription_content_1:
    'Se incluirá este monto pendiente por el periodo del {{- startDate, DD/MM/YYYY}} al {{- endDate, DD/MM/YYYY}} y empezarás un nuevo periodo en el <1>Plan {{planName}}</1>.',
  regularize_subscription_content_2: '¿Deseas regularizar tu suscripción?',
  yes_regularize: 'Sí, regularizar',
  pass_to_freemium_on_plan_title: 'Actualmente te encuentras en el Plan {{planName}}',
  pass_to_freemium_on_plan_content:
    'Para pasar al Plan Free, cancela tu suscripción actual. Una vez termine la vigencia de tu plan el {{- date, DD/MM/YYYY}}, automáticamente pasarás al Plan Free.',
  // PaymentMethod
  ok: 'Ok',
  redirecting: 'Redirigiendo',
  action_waiting_seconds: 'Esta acción podría tardar unos segundos.',
  action_status_error: 'La operación no se pudo realizar con éxito',
  on_demand_delete_method_title: 'La cancelación de tu suscripción se encuentra programada para el {{-date, DD/MM/YYYY}}',
  on_demand_delete_method_content: 'Podrás eliminar tu método de pago a partir de ese día.',
  cancel_plan_before_method_delete: 'Para eliminar el método de pago, cancela cualquier plan activo o suscripción futura que tengas.',
  deleting_payment_method: 'Eliminando método de pago',
  payment_method_delete_success: 'Método de pago eliminado con éxito',
  you_dont_have_active_payment_method: 'No cuentas con un método de pago activo',
  required_payment_method_0: 'Tu plan actual necesita tener un método de pago vinculado',
  required_payment_method_1: 'Añade un nuevo método de pago a tu cuenta para continuar utilizando Almendra.',
  add_payment_method: 'Añadir método de pago',
  payment_method: 'Método de pago',
  add_method: 'Añadir',
  payment_method_question_delete: '¿Deseas eliminar este método de pago?',
  payment_method_question_change: '¿Deseas cambiar de método de pago?',
  payment_method_question_add: '¿Deseas añadir un nuevo método de pago?',
  yes_add: 'Si, añadir',
  method_credit_card: 'Tarjeta de crédito',
  method_debit_card: 'Tarjeta de débito',
  method_added_date: 'Añadida el {{- date, DD/MM/YYYY}}',
  payment_method_not_found: 'No tienes registrado un método de pago',
  payment_method_message_change: 'Al momento de suscribirte también podrás cambiar tu método de pago.',
  payment_method_message_add_also: 'Al momento de suscribirte también podrás agregar tu método de pago.',
  payment_method_message_add: 'Al momento de suscribirte podrás agregar tu método de pago.',
  // BillingEstimation
  estimate_bill: 'Estimar factura',
  bill_period_range: 'Periodo del {{- startDate, DD/MM/YYYY}} al {{- endDate, DD/MM/YYYY}}',
  bill_patients_estimation: '¿Cuántos pacientes activos distintos estimas tener en este periodo?',
  more_than_x: 'Más de {{count}}',
  your_estimated_bill_0: 'Tu facturación estimada es de ',
  faq_what_is_active_patient:
    'Paciente activo es aquel paciente por el cual ha pasado por una consulta o ha tenido un plan alimenticio en uso durante el periodo.',
  // TransactionStatus
  transaction_status_title: '¡Excelente!',
  transaction_status_scheduled: 'Tu suscripción ha sido programada con éxito',
  transaction_status_success: 'Tu suscripción se ha realizado con éxito',
  transaction_status_plan: 'Plan',
  transaction_status_startDate: 'Fecha de inicio',
  transaction_status_nextBillingDate: 'Próxima facturación',
  // SubscriptionSettings
  receipt: 'Recibo',
  up_to_3_active_patients: 'hasta 3 pacientes activos',
  unlimited_active_patients: 'pacientes activos ilimitados',
  x_active_patients: '{{count}} paciente activo',
  x_active_patients_other: '{{count}} pacientes activos',
  active_patients_ratio: '{{count}} de {{limit}}',
  without_subscription: 'Sin suscripción',
  upgrade_subscription_title: 'Mejorar tu suscripción',
  upgrade_subscription: 'Mejorar Suscripción',
  plan_alert_limit_error: 'Has superado el número de pacientes activos que permite tu plan.',
  regulate_your_subscription: 'Regulariza tu suscripción',
  requires_action: 'Requiere acción',
  plan_alert_payment_error: 'Hubo un error en el cobro de tu plan el {{- date, DD [de] MMMM [del] YYYY }}.',
  regulate_subscription: 'Regularizar Suscripción',
  plan_renewal: 'Renovación',
  plan_renewal_status_cancelled: 'Cancelada',
  billing_discount: 'dscto',
  current_billing: 'Facturación actual',
  plan_status: 'Estado',
  plan_status_failed: 'Error en el cobro',
  plan_status_active: 'Activo',
  plan_active_patients: 'Pacientes activos',
  plan_period: 'Periodo',
  plan_next_billing: 'Próxima facturación',
  plan_billing_cancelled: 'renovación cancelada',
  billing_transactions: 'Facturación',
  billing_actual_period_use: 'Uso actual del periodo',
  cancel_future_subscription: 'Cancelar suscripción futura',
  cancel_scheduled_subscription_title: '¿Deseas cancelar tu suscripción futura al {{name}}?',
  cancel_scheduled_subscription_content:
    'Te recordamos que tu plan actual ({{name}}) tiene vigencia hasta el {{- date, DD/MMMM/YYYY}}. Después de esa fecha, te quedarás sin suscripción.',
  cancel_scheduled_subscription_success: 'Se canceló la suscripción futura al {{name}}.',
  cancel_scheduled_subscription_error: 'No se pudo cancelar la suscripción futura al {{name}}.',
  transactionId: 'Código de transacción',
  details: 'Detalle',
  transaction_amount: 'Monto',
  status: 'Estado',
  transaction_charged: 'Éxito', //cambiar
  transaction_failed: 'Error', //cambiar
  transaction_refunded: 'Devolución',
  view_billing_plans: 'Ver Planes',
  billingPlan: 'Plan',
  subscribed_since_f: 'Suscrita desde',
  subscribed_since_m: 'Suscrito desde',
  days_left: 'Días restantes',
  monthly: 'mensual',
  annually: 'anual',
  subscription_status_error: 'Error en el cobro',
  subscription_status_success: 'Activo', // cambiar
  user_since: 'Usuario desde',
  subscription_details: 'Detalles del plan',
  current_plan_details: 'Detalles del plan',
  transactionHistory: 'Historial de facturación',
  date_format_short: '{{- date, DD/MM/YYYY}}',
  date_format_short_mmm: '{{- date, DD/MMM/YYYY}}',
  date_format_short_year: '{{- date, DD/MM/YY}}',
  dateTime_format_short: '{{- date, DD/MM/YYYY hh:mmA}}',
  dateTime_format_short2: '{{- date, DD/MM/YYYY, h:mm a}}',
  date_format_short_m: '{{- date, DD/MMMM/YYYY}}',
  date_format_long: '{{- date, DD[ de ]MMMM[, ]YYYY}}',
  next_billing: 'Próxima facturación',
  active_patients: 'Pacientes activos',
  today_expires_trial: 'Hoy vence tu período de prueba',
  choose_your_plan: 'Elige tu plan',
  you_arent_subscribed_m: 'No estás suscrito a un plan actualmente',
  you_arent_subscribed_f: 'No estás suscrita a un plan actualmente',
  next_subscription_in_process: 'Te estamos transfiriendo al {{name}} en estos momentos.\n Por favor, refresca la página en unos instantes.',
  cancel_and_subscribe: 'Ups! Anula tu próxima suscripción y vuelve a suscribirte.',
  start_subscription: 'Empezar suscripción',
  renew_was_cancelled: 'Se canceló la renovación',
  start: 'Inicio',
  scheduled_plan_details:
    'Cambiaste de plan al {{nextSubscriptionName}}, el cual entrará en vigencia el {{- nextSubscriptionDate, DD/MM/YYYY }}. Hasta entonces podrás aprovechar los beneficios del {{currentSubscriptionName}}.',
  cancel_scheduled_subscription: 'Cancelar suscripción futura',
  regularize_subscription: 'Regularizar suscripción',
  // GoogleLinkAccount
  link_account: 'Vincular cuenta',
  unlink_account: 'Desvincular cuenta',
  google_reminder_extra:
    'Esta opción es independiente al tiempo de recordatorio para las consultas agendadas. Para desactivar el recordatorio, dejar el campo vacío.',
  google_reminder_label: 'Recordatorio de eventos en Google Calendar (minutos)',
  google_color_label: 'Color de eventos en Google Calendar',
  google_email_label: 'Enviar correos al paciente desde mi Gmail',
  google_email_extra_title: 'Los siguientes correos serán enviados desde Gmail',
  google_email_extra_desc_0: 'Bienvenida personalizada al paciente',
  google_email_extra_desc_1: 'Saludo por cumpleaños del paciente',
  google_email_extra_desc_2: 'Recordatorio de consulta',
  google_email_extra_desc_3: 'PDF del Plan Alimenticio',
  google_email_extra_desc_4: 'Registro de nuevo paciente',
  google_email_extra_desc_5: 'Confirmación de nutricionista al paciente',
  google_calendar_label: 'Mostrar las consultas agendadas en mi Google Calendar',
  google_linking: 'Vinculación con Google',
  google_reminder_v_min: 'El valor mínimo permitido es 10 minutos antes',
  google_reminder_v_max: 'El valor máximo permitido es 48 horas antes (2880 minutos)',
  google_unlink_title: '¿Quieres desvincular tu cuenta de Google con Almendra?',
  account_successfully_unlinked: 'Cuenta desvinculada con éxito',
  google_link_title: '¿Quieres vincular tu cuenta de Google con Almendra?',
  google_link_content_0:
    'Podrás visualizar las consultas que agendas con Almendra en tu calendario de Google. Sin embargo, los cambios que realices en tu calendario de Google no se actualizarán en Almendra.',
  google_link_content_1: 'Los correos electrónicos que envíes a tus pacientes saldrán directamente desde tu Gmail.',
  google_link_content_2: 'Te redireccionaremos a la página de Google para que puedas realizar esta operación.',
  google_link_error: 'Hubo un error al contactar el servidor de Google. Inténtalo nuevamente.',
  unlink_error_message: 'Hubo un error a desvincular tu cuenta. Por favor, inténtalo nuevamente.',
  yes_link_account: 'Sí, vincular mi cuenta',
  google_extra_access: 'Cuando vinculaste la cuenta con Google, no concediste este permiso. Vuelve a vincular tu cuenta y acepta todos los permisos.',
  // PopupNewAppointment
  appointment_in_process_v_title: 'Tienes una consulta en proceso',
  appointment_in_process_v_content: 'Termina la consulta {{name}}para poder iniciar otra.',
  with_patient: 'con {{name}}',
  all_php_appointments_taken: 'Todas las consultas de este paquete han sido agendadas',
  appointment_with_videocall_title: 'Esta consulta tiene una videollamada',
  appointment_with_videocall_content: '¿Deseas abrir el enlace en una nueva pestaña?',
  error_PHPLimitReached: 'Todas las consultas de este paquete ya han sido agendadas. Refresca la página y vuelve a intentarlo.',
  start_appointment: 'Iniciar consulta',
  do_you_want_to_start_other_appointment: '¿Deseas iniciar otra consulta?',
  start_new_appointment: 'Iniciar una nueva consulta',
  you_have_next_appointments: 'Tienes consultas que empezarán pronto',
  see_next_appointments: 'Ver próximas consultas',
  pending_past_appointments: '{{count}} consulta atrasada',
  pending_past_appointments_other: '{{count}} consulta atrasada',
  pending_future_appointments: '{{count}} consulta que empezará pronto',
  pending_future_appointments_other: '{{count}} consultas que empezarán pronto',
  you_have_x_and_y: 'Tienes {{x}} y {{y}}',
  you_have_x: 'Tienes {{x}}',
  // PopupPDFAppointmentPreview
  files_attached: 'archivo adjunto',
  files_attached_other: 'archivos adjuntos',
  subject: 'Asunto',
  email_pdf_placeholder: 'Ingresa el correo del paciente',
  to_email: 'Para',
  appointment_report_included: 'Reporte de consulta incluido',
  send_appointment_report_email: 'Enviar el Reporte de Consulta por correo electrónico',
  send_pdf_v_email_required: 'Debes ingresar una dirección de correo electrónico',
  send_pdf_v_email_valid: 'El correo ingresado no tiene un formato válido',
  send_pdf_v_subject_valid: 'Ingresa el asunto del correo',
  sending: 'Enviando...',
  sending_appointment_report_to_patient: 'Estamos enviando el Reporte de Consulta a {{name}}',
  this_action_could_take_seconds: 'Esta acción podría tardar unos segundos',
  sending_pdf_success: 'Correo enviado con éxito a {{email}}',
  sending_pdf_error_title: 'Hubo un problema al enviar el correo electrónico',
  sending_pdf_error_content: 'Por favor, refresca la página e inténtalo nuevamente.', //cambiar
  attachment_error_title: 'No se pudo enviar el correo',
  attachment_error_content: 'Ocurrió un problema con los archivos adjuntados',
  // EditDateMealPlanSummaryModal
  current_format: 'Formato actual',
  option: 'Opción',
  option_other: 'Opciones',
  edit_mealPlan_date_change_days: 'Cambiarlos de día',
  edit_mealPlan_date_maintain_days: 'Mantenerlos en su día',
  edit_mealPlan_date_foods_are_example: 'Los alimentos mostrados son un ejemplo',
  edit_mealPlan_date_question: '¿Qué deseas hacer con los alimentos colocados?',
  dayColumn: 'Día',
  day: 'día',
  day_other: 'días',
  week: 'semana',
  week_other: 'semanas',
  date_day_month_format: 'DD/MMMM',
  date_format_short_format: 'DD/MM/YYYY',
  date_format_shorter_format: 'DD/MM/YY',
  date_format_long_format: 'DD/MM/YYYY h:mm a',
  from_date: 'Del',
  to_date_2: 'al',
  confirm: 'Confirmar',
  what_is_this: '¿Qué es esto?',
  mealPlan_dates: 'Fechas del plan alimenticio',
  duration: 'Duración',
  date_format_long_time: '{{- date,DD/MMM/YYYY hh:mm a}}',
  date_format_short_time: '{{- date,DD/MM/YYYY h:mm a}}',
  food_mango: 'Mango',
  food_kiwi: 'Kiwi',
  food_banana: 'Plátano',
  food_milk: 'Leche',
  food_egg: 'Huevo',
  food_pecan: 'Pecana',
  food_pear: 'Pera',
  monday_long: 'Lunes',
  tuesday_long: 'Martes',
  wednesday_long: 'Miércoles',
  thursday_long: 'Jueves',
  friday_long: 'Viernes',
  saturday_long: 'Sábado',
  sunday_long: 'Domingo',
  please_select_an_option: 'Por favor, selecciona una opción',
  edit_mealPlan_date_v: 'Por favor, ingresa la duración del plan',
  edit_mealPlan_date_v_interference: 'La extensión de este plan alimenticio interfiere con otro.',
  // EquivalentsFoodSwap
  patient_food_allergies: 'Alergia a alimentos',
  patient_food_unliked: 'Alimentos que disgustan',
  patient_food_liked: 'Alimentos preferidos',
  none_foods: 'Ningún alimento',
  equivalents_swap_empty: 'No tienes más equivalencias para este alimento',
  of_food: 'de',
  equivalents_swap_heading: '¿Cuál de las siguientes equivalencias quisieras usar para reemplazar el alimento?',
  is_equivalent_to: 'Equivale a',
  equivalents_swap_title: 'Reemplazar alimento por equivalente',
  choose_equivalence: 'Elegir equivalencia',
  swap: 'Reemplazar',
  // AddExchangeGroup
  add_exchange_group: 'Añadir grupo de intercambios',
  add_group: 'Agregar grupo',
  add_exchange_group_title: 'Has deseleccionado grupos que estaban incluídos en el plan alimenticio, ¿deseas continuar?',
  add_exchange_group_content_0: 'Los siguientes grupos estaban incluídos en el plan y serán removidos',
  yes_continue: 'Sí, continuar',
  group: 'Grupo',
  // ExchangePopupMeal
  cant_open_meal_details: 'No se pudo abrir los detalles de esta comida',
  delete_option_title: '¿Deseas eliminar esta opción?',
  delete_option_content: 'Se eliminarán los {{count}} alimentos incluídos. Te recordamos que esta acción es irreversible.',
  save_and_close: 'Guardar y cerrar',
  delete_option: 'Eliminar opción',
  no_extra_options: 'Sin opciones extras',
  meal_without_options: 'Esta comida no tiene opciones extras asignadas',
  option_not_created: 'Todavía no ha sido creado. Para crear la opción {{count}}, agrega un alimento a esta sección',
  each_option_must_contain: 'Cada opcion debería contener',
  analysis: 'Análisis',
  exchange_groups: 'Grupos de intercambios',
  no_exchange_groups: 'Sin grupos de intercambios',
  actions: 'Acciones',
  add_food: 'Agregar alimento',
  copy_meal: 'Copiar comida',
  delete_options: 'Eliminar opciones',
  time: 'Hora',
  no_time: 'Sin hora',
  no_comments: 'Sin comentarios',
  comment: 'Comentario',
  comment_other: 'Comentarios',
  meal_comment_placeholder: 'Ej: Mezcla el yogurt con la avena',
  // ExchangeTableMealPlanDays
  click: 'Click en',
  custom_notification_exchange_add: 'para agregar este alimento en esa comida',
  custom_notification_exchange_delete: 'para eliminar este alimento en esa comida',
  custom_notification_exchange_finish: 'Click en Terminar para agregar este alimento a la selección',
  finish: 'Terminar',
  edit_recipe_and_finish: 'Editar receta y terminar',
  indexesToAddFood_empty: 'No has seleccionado opciones para agregar este alimento',
  handleAddFoodOption_error: 'Ocurrió un error al agregar algunos alimentos',
  handleDeleteFoodOption_error: 'Ocurrió un error al borrar este alimento',
  confirm_delete_option: '¿Deseas eliminar esta opción?',
  confirm_delete_option_content_many: 'Se eliminarán los {{count}} alimentos incluídos en esta opción. ',
  handle_delete_meal_error: 'Ocurrió un error al borrar esta comida',
  confirm_delete_all_options_title: '¿Deseas eliminar todas las opciones?',
  confirm_delete_all_options_content: 'Se eliminarán todas las opciones de esta comida.',
  couldnt_do_action: 'No se pudo realizar la acción',
  clear_day_content_title: '¿Deseas borrar el contenido de este día?',
  clear_day_content_content_0: 'Se eliminará el siguiente contenido del ',
  clear_day_content_content_1: 'Grupos de intercambios',
  clear_day_content_content_2: 'Opciones extras',
  clear_day_content_content_3: 'Horas de las comidas',
  clear_day_content_content_4: 'Comentarios de las comidas',
  yes_clear_day: 'Sí, limpiar día',
  copy: 'Copiar',
  day_without_meal: 'Este día no tiene asignada esta comida',
  hour_for_this_meal: 'Hora para esta comida',
  comment_for_this_meal: 'Comentario para esta comida',
  without_exchange_groups: 'Sin intercambios',
  groupName_mayus: 'NOMBRE DEL GRUPO',
  // FoodOptionDisplayMP
  delete: 'Eliminar',
  // FoodInformation
  food_information_quantity_v: 'Ingresa una cantidad mayor a 0',
  food_information: 'Información', //cambiar
  see_more_details: 'Ver más detalles',
  nutricional_details: 'Detalle nutricional',
  recipe: 'Receta',
  recipe_other: 'Recetas',
  categories: 'Categorías',
  source: 'Fuente',
  createdFood: 'Alimento propio',
  createdFood_other: 'Alimentos propios',
  software_recipe: 'Receta de Almendra',
  createdRecipe: 'Receta propia',
  recipe_from: 'Receta de {{name}}',
  brands_peru: 'Alimentos de supermercados Perú (2021)',
  cooking: 'Cocción',
  time_minutes: '{{count}} minuto',
  time_minutes_other: '{{count}} minutos',
  preparation: 'Preparación',
  portion: 'Porción',
  persons: '{{count}} persona',
  persons_other: '{{count}} personas',
  ingredients: 'Ingredientes',
  optional_ingredients: 'Ingredientes opcionales',
  instructions: 'Instrucciones',
  image: 'Imagen',
  nutrients: 'Nutrientes',
  total_weight: 'Peso total',
  'Alimentos infantiles': 'Alimentos infantiles',
  'Alimentos preparados (Entradas)': 'Alimentos preparados (Entradas)',
  'Alimentos preparados (Segundos)': 'Alimentos preparados (Segundos)',
  Aperitivos: 'Aperitivos',
  Bebidas: 'Bebidas',
  'Bebidas (alcohólicas y analcohólicas)': 'Bebidas (alcohólicas y analcohólicas)',
  'Carnes y derivados': 'Carnes y derivados',
  'Cereales de desayuno': 'Cereales de desayuno',
  'Cereales y derivados': 'Cereales y derivados',
  'Comida de restaurante': 'Comida de restaurante',
  'Comida para bebés': 'Comida para bebés',
  'Comida rápida': 'Comida rápida',
  'Comidas, entradas y acompañamientos': 'Comidas, entradas y acompañamientos',
  'Cordero, ternero y productos de caza': 'Cordero, ternero y productos de caza',
  Dulces: 'Dulces',
  'Embutidos y fiambres': 'Embutidos y fiambres',
  'Especies y hierbas': 'Especies y hierbas',
  'Frutas y derivados': 'Frutas y derivados',
  'Frutas y jugos': 'Frutas y jugos',
  'Granos de cereales y pasta': 'Granos de cereales y pasta',
  'Grasas y aceites': 'Grasas y aceites',
  'Grasas, aceites y oleaginosas': 'Grasas, aceites y oleaginosas',
  'Huevos y derivados': 'Huevos y derivados',
  'Leche y derivados': 'Leche y derivados',
  Legumbres: 'Legumbres',
  'Leguminosas y derivados': 'Leguminosas y derivados',
  'Lácteos y huevos': 'Lácteos y huevos',
  Misceláneos: 'Misceláneos',
  'Nueces y semillas': 'Nueces y semillas',
  'Pescados y mariscos': 'Pescados y mariscos',
  'Productos avícolas': 'Productos avícolas',
  'Productos azucarados': 'Productos azucarados',
  'Productos de cerdo': 'Productos de cerdo',
  'Productos horneados': 'Productos horneados',
  'Productos porcinos': 'Productos porcinos',
  'Sopas y salsas': 'Sopas y salsas',
  Suplementos: 'Suplementos',
  'Tubérculos, raíces y derivados': 'Tubérculos, raíces y derivados',
  Vegetales: 'Vegetales',
  'Verduras, hortalizas y derivados': 'Verduras, hortalizas y derivados',
  // GroceriesListModal
  groceriesList_reset_title: '¿Deseas restablecer la lista de compras?',
  groceriesList_reset_content:
    'Tras confirmar, se restablecerá la lista de compras con los alimentos del plan alimenticio. Todo comentarios o agrupación realizada en esta lista se perderá. Esta acción es irreversible.',
  yes_reset: 'Sí, restablecer',
  groceriesList_reset_success: 'La lista de compras se restableció con éxito',
  groceriesList_reset_error: 'Ocurrió un problema al restablecer la lista de compras',
  groceriesList_delete_title: '¿Deseas desactivar la lista de compras?',
  groceriesList_delete_content:
    'Tras confirmar, tu paciente no podrá visualizar la lista de compras en su App ni en el PDF. Los comentarios de la lista de compras serán borrados.',
  yes_disable: 'Sí, desactivar',
  groceriesList_delete_error:
    'Ocurrió un problema al guardar la lista de compras. Por favor, si el problema persiste comunícate con soporte@almendra.io',
  groceries_list: 'Lista de compras',
  groceriesList_pdf_error_title: 'Ocurrió un error al generar la Lista de compras.',
  reload_and_try_again: 'Por favor, refresca la página e inténtalo nuevamente.',
  groceriesList_visibility_title: '¿Deseas cambiar la visibilidad de la lista de compras?',
  groceriesList_visibility_content:
    'La vista se cambiará de <1>{{from}}</1> a <3>{{to}}</3>. Se volverá a generar la lista de compras sobre la base del plan alimenticio. Cualquier cambio o comentario que hayas realizado a esta lista se perderá.',
  groceriesList_dave_error: 'Ocurrió un problema al guardar la lista de compras.',
  groceriesList_unavailable:
    'Actualmente no tenemos habilitada la lista de compras para el plan alimenticio {{mealPlanType}}. Si deseas que se desarrolle esta funcionalidad, por favor coméntanosla en el buzón de Sugerencias',
  mealPlanType_exchange: 'de intercambios',
  mealPlanType_flexible: 'flexible',
  mealPlanType_blank: 'de texto libre',
  saving_changes: 'Guardando cambios...',
  downloading: 'Descargando...',
  download: 'Descargar',
  groceriesList_view_weekly_tooltip: 'Lista de compras semanal para el plan alimenticio. Se generará {{count}} lista para la semana del plan.',
  groceriesList_view_weekly_tooltip_other:
    'Lista de compras semanal para el plan alimenticio. Se generará {{count}} listas para las {{count}} semanas del plan.',
  weekly: 'Semanal',
  groceriesList_view_total_tooltip: 'Todo lo que se tendrá que comprar para el plan alimenticio en una sola lista',
  groceriesList_notes: 'Notas',
  groceriesList_notes_quantity: 'Las cantidades se redondearon hacia arriba en múltiplos de 0.5 al generar la lista.',
  groceriesList_notes_recipes: 'Los ingredientes de las siguientes recetas se incluyeron al generar la lista de compras:',
  // IndicationsContainer
  add_indication: 'Agregar indicación',
  indication_delete: '¿Deseas borrar esta indicación?',
  indication_delete_success: 'Indicación eliminada con éxito',
  indication_add_success: 'Nueva indicación agregada con éxito',
  // MealPlanMailModal
  attachments_upload_limit: 'El límite máximo total para adjuntar otros archivos es de 10MB',
  sending_meal_plan_to_patient: 'Estamos enviando el Plan Alimenticio a {{email}}',
  meal_plan: 'Plan alimenticio',
  send_meal_plan_pdf: 'Enviar el Plan Alimenticio por correo electrónico',
  meal_plan_included: 'Plan alimenticio incluido',
  attach_files: 'Adjuntar archivos',
  // MealPlanPDF
  Comida: 'Comida',
  Lunes: 'Lunes',
  Martes: 'Martes',
  Miércoles: 'Miércoles',
  Jueves: 'Jueves',
  Viernes: 'Viernes',
  Sábado: 'Sábado',
  Domingo: 'Domingo',
  Lu: 'Lu',
  Ma: 'Ma',
  Mi: 'Mi',
  Ju: 'Ju',
  Vi: 'Vi',
  Sá: 'Sá',
  Do: 'Do',
  'Al despertarse': 'Al despertarse',
  Desayuno: 'Desayuno',
  'Media mañana': 'Media mañana',
  Almuerzo: 'Almuerzo',
  'Media tarde': 'Media tarde',
  Cena: 'Cena',
  'Antes de dormir': 'Antes de dormir',
  'Pre-entreno': 'Pre-entreno',
  'Post-entreno': 'Post-entreno',
  mealTime: 'Hora',
  or: 'o',
  and: 'y',
  exclude: 'Excluir',
  menu: 'Menú',
  professional: 'Profesional',
  free_day: 'Día libre',
  free_day_other: 'Días libres',
  how_to_read_this: '¿Cómo leer esta sección? ',
  meal_plan_pdf_exchange_demo_read: 'Si en tu desayuno aparece “2 {{name}}”, podrás elegir 2 alimentos con sus respectivas porciones para comer.',
  recipes: 'Recetas',
  minutes_short: 'min',
  recipe_portions: 'porción',
  recipe_portions_other: 'porciones',
  optionals: 'Opcionales',
  // PendingMealPlans
  empty_pending_meal_plans: '¡No tienes planes alimenticios pendientes!',
  creation_date: 'Fecha de creación',
  progress: 'Progreso',
  // MealPlansTable
  search_by_name: 'Buscar por nombre',
  empty_search_meal_plans: 'No se encontraron planes alimenticios',
  empty_search_meal_plans_description_0: 'Empieza filtrando los planes alimenticios según los criterios que desees en la barra lateral izquierda.',
  empty_search_meal_plans_description_1: 'Para buscar por nombre del paciente o del plan alimenticio, haz click en <1 /> arriba de esta tabla.',
  empty_search_meal_plans_description_2: 'Finalmente, ubica el plan alimenticio que quieras copiar y haz click en la fila.',
  show_meal_plans: 'Mostrar planes alimenticios',
  of_this_patient: 'De este paciente',
  of_everyone: 'De todos',
  format: 'Formato',
  finished: 'Terminado',
  unfinished: 'Sin terminar',
  mealPlan_exchange: 'Intercambios',
  mealPlan_blank: 'Texto libre',
  mealPlan_flexible: 'Flexible',
  mealPlan_strict: 'Estricto',
  'Otros objetivos': 'Otros objetivos',
  'Sin objetivo': 'Sin objetivo',
  caloric_target: 'Objetivo Calórico',
  show_all: 'Mostrar todos',
  this_week: 'Esta semana',
  this_month: 'Este mes',
  this_year: 'Este año',
  patient_sex: 'Sexo del paciente',
  patient_tags: 'Etiquetas del paciente',
  no_tag: 'Sin etiqueta',
  meal_plans: 'Planes Alimenticios',
  loading: 'Cargando...',
  meet_filters: 'Coinciden con los filtros',
  showing_recent_meal_plans: 'Mostrando los {{count}} planes alimenticios más recientes',
  show_x_more: 'Mostrar {{count}} más',
  copy_other_meal_plans_title: '¿Deseas copiar este plan alimenticio al actual?',
  yes_copy: 'Sí, copiar',
  cant_preview_plan: 'No se pudo previsualizar el plan',
  selected_meal_plan_details: 'Detalles del plan alimenticio seleccionado',
  days: 'Días',
  meal_plan_summary: 'Resumen del plan alimenticio',
  meal_plan_without_meals: 'Este plan alimenticio no contiene comidas registradas.',
  average_daily_calories: 'Total de calorías promedio por día',
  meal_plan_consist_days: 'El plan consiste de {{count}} día',
  meal_plan_consist_days_other: 'El plan consiste de {{count}} días',
  meal_plan_consist_meals: 'Los días tienen aproximadamente {{count}} comida',
  meal_plan_consist_meals_other: 'Los días tienen aproximadamente {{count}} comidas',
  meal_plan_summary_analysis_fat_high: 'Esta dieta es alta en grasas',
  meal_plan_summary_analysis_protein_high: 'Esta dieta es alta en proteinas',
  meal_plan_summary_analysis_carbs_high: 'Esta dieta es alta en carbohidratos',
  meal_plan_summary_analysis_carbs_low: 'Esta dieta es baja en carbohidratos',
  meal_plan_summary_analysis_balanced: 'Esta dieta está balanceada',
  according_to_amdr_tooltip: 'Según el AMDR (Acceptable Macronutrient Distribution Range) para personas adultas.',
  meal_plan_with_indications: 'Esta dieta tiene las siguientes indicaciones:',
  meal_plan_without_indications: 'Esta dieta tiene no tiene indicaciones',
  clear_filters: 'Limpiar filtros',
  average_calories_target: 'Objetivo calórico promedio',
  // MealPlanSummary
  free_text_feature_disabled: 'Esta funcionalidad está deshabilitada para el plan alimenticio de texto libre',
  no_recipes_on_meal_plan: 'No has agregado recetas a este plan alimenticio',
  no_workout_on_meal_plan: 'No has asignado una rutina de ejercicios a este plan alimenticio',
  meal_plan_type_feature_disabled: 'Esta funcionalidad está deshabilitada para el plan alimenticio {{name}}',
  groceries_list_empty_meal_plan: 'Agrega alimentos a este plan alimenticio para poder activar la lista de compras',
  create_template_from_meal_plan_title: '¿Deseas crear una plantilla a base de este plan alimenticio?',
  create_template_from_meal_plan_content:
    'Se creará una nueva plantilla utilizando este plan alimenticio como base (todos los días con sus objetivos, comidas, alimentos, comentarios y horas).',
  enter_template_name: 'Ingresa el nombre de la plantilla',
  yes_create_template: 'Sí, crear plantilla',
  create_template_from_meal_plan_v_name: 'Ingresa el título de la nueva plantilla.',
  template_create_success: 'Plantilla "{{name}}" creada con éxito',
  downloading_meal_plan: 'Descargando el Plan Alimenticio',
  mealPlan_pdf_error_title: 'Ocurrió un error al generar el PDF del Plan Alimenticio.',
  empty_meal_plan_message_pdf: 'Agrega alimentos a este plan alimenticio para poder {{name}}',
  download_the_pdf: 'descargar el PDF',
  send_the_pdf: 'enviar el PDF',
  app: 'App',
  app_always_shows_images: 'Las imágenes siempre se mostrarán en el App',
  pdf: 'PDF',
  show_calories: 'Mostrar calorías',
  show_calories_tooltip: 'Mostrar las calorías y macronutrientes por día en el App. Mostrar las calorías al lado de cada alimento en el PDF.',
  show_groceries_list: 'Mostrar lista de compras',
  show_groceries_list_tooltip: 'Mostrar la lista de compras',
  show_recipes: 'Mostrar recetas',
  show_recipes_tooltip: 'Mostrar todas las recetas con sus respectivos ingredientes e instrucciones.',
  show_recipes_images: 'Mostrar imágenes de recetas',
  show_recipes_images_tooltip: 'Mostrar las imágenes de las recetas siempre que estas existan.',
  show_workout: 'Mostrar rutina',
  show_workout_tooltip: 'Mostrar la rutina de ejercicios asignada.',
  meal_plan_workout_groceries_list: 'Plan alimenticio, rutina y lista de compras',
  meal_plan_groceries_list: 'Plan alimenticio y lista de compras',
  meal_plan_workout: 'Plan alimenticio y rutina',
  template_just_created: "Plantilla '{{name}}'",
  meal_plan_just_created: 'Resumen del plan alimenticio para {{name}}',
  no_meals_message: 'No hay comidas',
  no_meals_description:
    'Para ver el resumen, debes agregar por lo menos una comida en cualquiera de los días. El plan alimenticio quedará como pendiente.',
  mealPlanName: 'Nombre del plan alimenticio',
  mealPlanDuration: 'Duración del plan alimenticio',
  mealPlanSummary_days: 'El plan consiste en <1>{{count}} día{{name}}</1>',
  mealPlanSummary_days_other: 'El plan consiste en <1>{{count}} días{{name}}</1>',
  model: 'modelo',
  mealPlanSummary_days_meals: 'Los días tienen aproximadamente <1>{{count}} comida</1>',
  mealPlanSummary_days_meals_other: 'Los días tienen aproximadamente <1>{{count}} comida</1>',
  things_to_keep_in_mind_patient: '¿Qué cosas debería tener en cuenta {{name}}?',
  meal_plan_options: 'Opciones del plan alimenticio',
  create_template_from_meal_plan: 'Crear plantilla desde este plan',
  download_pdf: 'Descargar PDF',
  send_pdf: 'Enviar PDF',
  // MicronutrientChart
  minimum_recommended: 'Mínimo recomendado',
  difference: 'Diferencia',
  surplus: 'Superávit',
  deficit: 'Déficit',
  // NewOrTemplate
  generated_with: 'Generado con',
  save_75p_time: 'Ahorra hasta 75% de tiempo',
  save_95p_time: 'Ahorra hasta 95% de tiempo',
  free_text_format: 'Formato texto libre',
  nutrients_not_calculated: 'Nutrientes no calculados',
  exchange_format: 'Formato intercambios',
  flexible_format: 'Formato flexible',
  strict_format: 'Formato estricto',
  start_from_scratch: 'Quiero empezar desde cero',
  select_the_type_of_x: 'Selecciona el tipo de {{name}}',
  start_from_point: 'Tengo una base',
  select_starting_point: 'Selecciona la base del plan',
  a_meal_plan: 'Un plan alimenticio',
  a_template: 'Una plantilla',
  another_plan: 'Otro plan',
  mealPlanBrief_1: 'usando el sistema de intercambios, basado en días modelo',
  mealPlanBrief_2: 'que permite elegir diferentes opciones de alimentos, basado en de días modelo',
  mealPlanBrief_3: 'con horarios y comidas que se deben seguir tal como se indican',
  mealPlanBrief_4: 'donde no se calcularán los nutrientes. Escribe lo que desees',
  mealPlanBrief_engine: 'Un click. Un plan alimenticio personalizado.',
  select_the_plan_format: 'Selecciona {{name}} quieres crear el Plan Alimenticio para {{patient}}.',
  the_template_which: 'la plantilla desde la cual',
  the_meal_plan_which: 'la plan alimenticio desde el cual',
  thats_how_it_will_look: 'Así se vería tu {{name}} en PDF una vez {{verb}}',
  finished_m: 'finalizado',
  finished_f: 'finalizada',
  preview: 'Vista previa',
  model_days: 'Días modelo',
  meal_plan_visibility: '¿Cómo deseas visualizar {{name}}?',
  byTable: 'En tabla',
  byDay: 'Día por día',
  ready_to_create_m: '¿Listo para hacer {{name}}?',
  ready_to_create_f: '¿Lista para hacer {{name}}?',
  fantastic_template: 'una plantilla fantástica',
  fantastic_meal_plan: 'un plan fantástico',
  select_left_options: 'Selecciona una de las opciones de la izquierda para empezar',
  // OverheadMacrosPopover
  overhead_macros_title: 'Este día contiene {{name}}, pero le asignaste un objetivo de 0 gramos',
  actual_distribution: 'Distribución actual',
  // PrefillMealPlanButton
  prefill_meal_plan_success: 'El plan alimenticio fue generado con éxito',
  prefill_meal_plan: 'Generar plan automáticamente',
  prefill_meal_plan_title: 'Genera el plan alimenticio automáticamente',
  prefill: 'Generar',
  one_moment_please: 'Un momento, por favor...',
  prefill_phrase_0: 'Afilando el cuchillo',
  prefill_phrase_1: 'Preparando la mesa',
  prefill_phrase_2: 'Doblando servilletas',
  prefill_phrase_3: 'Cortando cebollas',
  prefill_phrase_4: 'Hirviendo el agua',
  prefill_phrase_5: 'Removiendo la salsa',
  prefill_phrase_6: 'Limpiando la cocina',
  prefill_phrase_7: 'Probando la sopa',
  prefill_phrase_8: 'Añadiendo especias',
  prefill_phrase_9: 'Preparando café',
  prefill_phrase_10: 'Perfumando el ambiente',
  prefill_phrase_11: 'Colocando el mantel',
  prefill_phrase_12: 'Sirviendo los platos',
  prefill_phraseB_0: 'Pelando y cortando verduras frescas',
  prefill_phraseB_1: 'Lavando las hojas de ensalada',
  prefill_phraseB_2: 'Asando pollo a la parrilla',
  prefill_phraseB_3: 'Preparando el aderezo casero',
  prefill_phraseB_4: 'Remojando los frijoles',
  prefill_phraseB_5: 'Haciendo un caldo casero',
  prefill_phraseB_6: 'Horneando una tarta de frutas sin azúcar',
  prefill_phraseB_7: 'Mezclando una ensalada de frutas frescas',
  prefill_phraseB_8: 'Empacando una lonchera saludable',
  prefill_phraseB_9: 'Cocinando al vapor un filete de pescado',
  prefill_phraseB_10: 'Mezclando un batido de proteínas',
  prefill_phraseB_11: 'Remojando la avena para el desayuno',
  prefill_phraseB_12: 'Cocinando quinoa en una cacerola',
  prefill_phraseB_13: 'Asando una berenjena en rodajas',
  prefill_phraseB_14: 'Preparando un guiso de vegetales',
  prefill_phraseB_15: 'Marinando un tofu para la cena',
  prefill_phraseB_16: 'Cortando en juliana zanahorias frescas',
  prefill_phraseB_17: 'Haciendo una salsa de tomate casera',
  prefill_phraseB_18: 'Cocinando un arroz integral',
  prefill_phraseB_19: 'Cocinando a fuego lento un chili sin carne',
  prefill_phraseB_20: 'Mezclando una ensalada de lentejas',
  prefill_phraseB_21: 'Cocinando al horno un salmón con hierbas frescas',
  prefill_phraseB_22: 'Rallando queso parmesano fresco',
  prefill_phraseB_23: 'Horneando un pan integral casero',
  prefill_phraseB_24: 'Mezclando una ensalada de garbanzos',
  prefill_phraseB_25: 'Cocinando al wok verduras de temporada',
  prefill_phraseB_26: 'Mezclando un smoothie de bayas',
  prefill_phraseB_27: 'Cocinando en una olla lenta una sopa de verduras',
  prefill_phraseB_28: 'Asando una calabaza fresca',
  prefill_phraseB_29: 'Haciendo una ensalada de pasta integral con verduras frescas',
  prefill_description: 'Las comidas serán prellenadas por diferentes recetas según grupos de intercambios asignados.',
  prefill_n_options_label: '¿Cuántas opciones por comida quieres ofrecer?',
  prefill_n_options_extra: 'En caso una comida no tenga intercambios asignados, se utilizará una receta que mejor calce en el plan',
  // PatientSelectOptions
  'No tiene': 'No tiene',
  Leve: 'Leve',
  Moderado: 'Moderado',
  Fuerte: 'Fuerte',
  Severo: 'Severo',
  'Ganar masa muscular': 'Ganar masa muscular',
  'Perder peso': 'Perder peso',
  'Bajar de peso': 'Weight loss',
  'Subir de peso': 'Gain weight',
  'Tener más energía': 'Tener más energía',
  'Mejorar hábitos alimenticios': 'Mejorar hábitos alimenticios',
  'Ganar músculo y perder grasa': 'Ganar músculo y perder grasa',
  Casado: 'Casado',
  Soltero: 'Soltero',
  Viudo: 'Viudo',
  Divorciado: 'Divorciado',
  Separado: 'Separado',
  'Unión libre': 'Unión libre',
  Casada: 'Casada',
  Soltera: 'Soltera',
  Viuda: 'Viuda',
  Divorciada: 'Divorciada',
  Separada: 'Separada',
  Ron: 'Ron',
  Cerveza: 'Cerveza',
  'Vino Blanco': 'Vino Blanco',
  'Vino Tinto': 'Vino Tinto',
  Whisky: 'Whisky',
  Pisco: 'Pisco',
  Vodka: 'Vodka',
  Gin: 'Gin',
  Jägermeister: 'Jägermeister',
  'Todos los días': 'Todos los días',
  '1 o 2 veces por semana': '1 o 2 veces por semana',
  '1 vez cada 15 días': '1 vez cada 15 días',
  '1 vez al mes': '1 vez al mes',
  'Menos de 1 vez al mes': 'Menos de 1 vez al mes',
  Nunca: 'Nunca',
  '5 o más tazas al día': '5 o más tazas al día',
  '4 tazas al día': '4 tazas al día',
  '3 tazas al día': '3 tazas al día',
  '2 tazas al día': '2 tazas al día',
  '1 taza al día': '1 taza al día',
  Interdiario: 'Interdiario',
  'Ocasiones puntuales': 'Ocasiones puntuales',
  'Proteina de suero de leche': 'Proteina de suero de leche',
  'Proteina de suero de leche aislada': 'Proteina de suero de leche aislada',
  'Vitamina B12': 'Vitamina B12',
  Creatina: 'Creatina',
  Carnitina: 'Carnitina',
  Multivitamínico: 'Multivitamínico',
  Glutamina: 'Glutamina',
  'Omega 3': 'Omega 3',
  BCAA: 'BCAA',
  Zinc: 'Zinc',
  Magnesio: 'Magnesio',
  ZMA: 'ZMA',
  HMB: 'HMB',
  Yohimbina: 'Yohimbina',
  'Potenciador de testosterona': 'Potenciador de testosterona',
  'Ganador de peso (Mass gainer)': 'Ganador de peso (Mass gainer)',
  Celiaquía: 'Celiaquía',
  Hipertensión: 'Hipertensión',
  Dislipemias: 'Dislipemias',
  'Diabetes Mellitus': 'Diabetes Mellitus',
  Obesidad: 'Obesidad',
  'Normal - Menos de 1 vez al día': 'Normal - Menos de 1 vez al día',
  'Normal - Aprox. 1 vez al día': 'Normal - Aprox. 1 vez al día',
  'Normal - Más de 1 vez al día': 'Normal - Más de 1 vez al día',
  Diarrea: 'Diarrea',
  Estreñimiento: 'Estreñimiento',
  Sangrado: 'Sangrado',
  'Sin restricciones': 'Sin restricciones',
  Vegana: 'Vegana',
  Vegetariana: 'Vegetariana',
  Mediterránea: 'Mediterránea',
  Ketogénica: 'Ketogénica',
  Orgánica: 'Orgánica',
  Paleolítica: 'Paleolítica',
  Celíaco: 'Celíaco',
  'Es un familiar': 'Es un familiar',
  Referido: 'Referido',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
  'Otra red social': 'Otra red social',
  'Página web': 'Página web',
  'Es un amigo': 'Es un amigo',
  'Por mi trabajo': 'Por mi trabajo',
  // PopupBodyFormulas
  used_formulas: 'Fórmulas utilizadas',
  bmr_long: 'Metabolismo Basal (BMR)',
  bmr_short: 'BMR',
  formula: 'Fórmula',
  values: 'Valores',
  lean_body: 'Masa magra',
  tdee_long: 'Gasto Energético Total Diario (TDEE)',
  tdee_short: 'TDEE',
  active_physical_value: 'Factor de actividad física',
  calculation: 'Cálculo',
  selected: 'Seleccionado',
  select: 'Seleccionar',
  'Ecuación Mifflin-St Jeor': 'Ecuación Mifflin-St Jeor',
  'Ecuación Harris Benedict Revisada': 'Ecuación Harris Benedict Revisada',
  'Ecuación Harris Benedict Original': 'Ecuación Harris Benedict Original',
  'Ecuación de la OMS/FAO': 'Ecuación de la OMS/FAO',
  'Ecuación Katch-McArdle': 'Ecuación Katch-McArdle',
  'Ecuación Cunningham': 'Ecuación Cunningham',
  popup_body_formulas_error_1: 'Por favor ingresa un número',
  popup_body_formulas_error_2: 'Por favor ingresa un número mayor a 0 y menor a 10',
  // PopupCopyMeal
  copy_meal_modal_title: 'Copiar comida {{mealName}} - {{dayName}}',
  copy_meal_modal_description_0: 'Selecciona las comidas a las cuales deseas copiar los alimentos de la comida {{mealName}} del {{dayName}}.',
  copy_meal_modal_description_1:
    'Si alguna comida a la que quieras copiar ya contiene alimentos o grupos de interambios, estos serán reemplazados. Esta acción es irreversible.',
  copying: 'Copiando',
  copied: '¡Copiado!',
  // PopupMealPlanSettings
  confirm_this_action: 'Confirma esta acción',
  meal_plan_settings_confirm_content:
    'Al hacer click en "Confirmar", todos los alimentos de este plan alimenticio serán restablecidos y las comidas pasarán a estar vacías. Esta acción es irreversible.',
  meal_plan_settings: 'Ajustes del plan alimenticio',
  plan_settings: 'Ajustes del plan',
  visibility: 'Visibilidad',
  table: 'Tabla',
  meal_plan_settings_table_description: 'Una perspectiva completa de la semana a medida que elaboras el plan.',
  by_day: 'Por día',
  meal_plan_settings_day_description: 'Para obtener información precisa de cada día a simple vista.',
  placing_foods: 'Ingreso de alimentos',
  foods_search: 'Buscador de alimentos',
  meal_plan_settings_search_description: 'Selecciona alimentos de las bases de datos. Nutrientes calculados automáticamente.',
  free_text: 'Texto libre',
  meal_plan_settings_freeText_description: 'Escribe lo que desees en las comidas. No se calcularán los nutrientes.',
  // CurrentBillingInfoModal
  why_this_amount: '¿Por qué este monto?',
  current_active_patients: 'Pacientes activos hasta el momento para este periodo:',
  current_billing_description:
    'En caso no tengás más pacientes activos hasta el {{-endDate, DD/MM/YYYY}}, se cobrará en tu facturación <1>$ {{amount}}</1>',
  current_billing_description_discount_included: 'Se incluye el descuento del {{discount}} %.',
  current_billing_description_minimum:
    'Recuerda que la facturación del Plan {{planName}} es como mínimo {{minPatients}} pacientes activos. Por lo que, igual se cobrará como si tuvieras {{minPatients}} pacientes activos en caso tengas menos pacientes activos.',
  // ActivePatientsModal
  activePatients_details: 'Detalles',
  deleted_patient: 'Paciente eliminado',
  active_patients_actions_appointment: 'Realizar una consulta',
  active_patients_actions_create_meal_plan: 'Crear un plan alimenticio',
  active_patients_actions_download_meal_plan: 'Descargar un plan alimenticio',
  active_patients_actions_date_meal_plan: 'Cambiar la fecha de un plan alimenticio',
  active_patients_actions_finish_meal_plan: 'Terminar un plan alimenticio',
  active_patients_actions_period_with_meal_plan: 'Empezar el periodo con un plan activo',
  active_patients_actions_send_meal_plan: 'Enviar un plan alimenticio',
  trigger_date: 'Fecha de activación',
  trigger_action: 'Acción responsable',
  view_details: 'Ver detalles',
  active_patients_modal_description:
    'Aquí podrás ver cuándo es que tus pacientes se volvieron activos en este mes de suscripción. La columna "acción responsable" indica la acción que realizaste en el periodo actual de facturación que activó al paciente.',
  empty_active_patients: 'Todavía no tienes pacientes activos',
  // PatientPrefillModal
  patient_already_registered: 'Este paciente ya se encuentra registrado en Almendra',
  patient_prefil_description:
    '<0>{{patientName}} ya tiene una cuenta en Almendra.</0> Enviaremos un correo a <2>{{patientMail}}</2> para que {{patientName}} confirme esta acción. Una vez confirme, deseas:',
  visualize: 'Visualizar',
  patient_prefill_visualize_description: 'Se mostrará la información preexistente de este paciente en su perfil.',
  dont_visualize: 'No visualizar',
  patient_prefill_dont_visualize_description: 'No se mostrará la información preexistente de este paciente en su perfil.',
  // PayPalForm
  paypal_error_connect: 'Ocurrió un problema al conectarse con PayPal',
  try_again_later: 'Please, try again later',
  choose_an_option: 'Elige una opción',
  paypal_pay_instructions:
    'Haz click en el botón "PayPal" para pagar con tu cuenta de PayPal. Si no tienes una, puedes hacer click en el botón "Tarjeta de débito o crédito".',
  wrong_payment_method: 'Medio de pago incorrecto',
  payment_method_error_description:
    'No te preocupes, no se ha realizado ningún pago. Revisa que hayas ingresado correctamente los datos de tu medio de pago.',
  payment_to_do: 'Pago por realizar',
  paypal_usd_policy: 'Por política de PayPal, se pagará en dólares americanos.',
  // PopupSpecialConditionInfo
  nursing: 'lactancia',
  pregnancy: 'embarazo',
  patient_on: 'Paciente en',
  condition_nursing_info_0:
    'Para las mujeres lactantes que tuvieron un aumento adecuado del peso gestacional, se agregó un factor de 505 kcal al BMR dentro de los primeros 6 meses de lactancia.',
  condition_nursing_info_1: 'Se deja a discreción tuya cuánto más se le debe agregar luego de los 6 meses, en caso la lactancia continúe.',
  nursing_data: 'Datos de la lactancia',
  nursing_stage: 'Etapa de la lactancia',
  extra_comments: 'Comentarios extra',
  nursing_source: 'Fuente: FAO (Organización de las Naciones Unidas para la Alimentación y Agricultura)',
  pregestational_weight_basis: 'Se toma como base el peso pre-gestacional para el BMR',
  condition_pregnancy_info_0: 'Dependiendo del trimestre de embarazo en el cual se encuentre la paciente, los cambios hechos en el BMR son:',
  condition_pregnancy_info_1: 'Un incremento del 5% del BMR en el primer trimestre de embarazo.',
  condition_pregnancy_info_2: 'Un incremento del 10% del BMR en el segundo trimestre de embarazo.',
  condition_pregnancy_info_3: 'Un incremento del 25% del BMR en el tercer trimestre de embarazo.',
  condition_pregnancy_twin: 'Se recomienda agregar aproximadamente 500 kcal extra en casos de gestación gemelar.',
  pregnancy_data: 'Datos del embarazo',
  pre_pregnancy_weight: 'Peso pre-gestacional',
  pregnancy_stage: 'Etapa del embarazo',
  pregnancy_type: 'Tipo de embarazo',
  pregnancy_type_twin: 'Gestación Gemelar',
  pregnancy_type_unique: 'Gestación Única',
  pregnancy_source: 'Fuente: FAO (Organización de las Naciones Unidas para la Alimentación y Agricultura) y de Nutrition Care Manual (ADA)',
  pregnancy_requirements:
    'Se necesita agregar el peso pre-gestacional para poder hacer los cálculos correctamente, se puede agregar en el perfil del paciente, en la sección "Estilo de vida" dentro de "Condiciones especiales"',
  q1: 'Primer Trimestre',
  q2: 'Segundo Trimestre',
  q3: 'Tercer Trimestre',
  q4: 'Cuarto Trimestre',
  second_year: 'Segundo Año',
  third_year: 'Tercer Año',
  special_condition_date: '{{time}}, Semana {{week}}',
  // SelectAvailableHour
  choose_future_time: 'Selecciona un horario futuro',
  video_call_through: 'Realizada a través de',
  'Consulta Presencial': 'Consulta Presencial',
  'Consulta a Domicilio': 'Consulta a Domicilio',
  'Consulta Online': 'Consulta Online',
  address: 'Dirección',
  agenda_v_address_enter: 'Ingresa tu dirección',
  agenda_v_address_select: 'Selecciona la dirección',
  agenda_v_date_select: 'Selecciona una fecha',
  agenda_address_empty: 'No se encontraron direcciones disponibles en este momento.',
  select_other_day: 'Selecciona otro día',
  agenda_v_time: 'Selecciona un horario',
  agenda_time_empty: 'Se agotaron los horarios disponibles para este día.',
  // RecipeView
  edit_recipe_again_title: '¿Deseas editar esta receta?',
  edit_recipe_again_content:
    'Los cambios que realices en esta receta no alterarán ningún plan alimenticio, plantilla o grupo de equivalencias que contengan esta receta.',
  editting_recipe: 'Estás editando la receta "{{name}}"',
  duplicated: 'duplicado',
  duplicate_recipe_title: 'Estás a punto de duplicar una receta',
  duplicate_recipe_content:
    'Recomendamos hacer esto solo cuando quieras hacer una receta muy parecida a la que estás duplicando. Acuérdate de cambiarle el nombre a la receta luego de darle click a "Confirmar".',
  duplicate: 'Duplicar receta',
  created_by: 'Hecha por',
  system_default: 'Sistema',
  no_ingredients: 'Sin ingredientes',
  no_instructions: 'Sin instrucciones',
  no_optional_ingredients: 'Sin ingredientes opcionales',
  nutrients_per_serving: 'Nutrientes por porción',
  // PatientsReport
  patients_per_active_php: 'Pacientes por paquete activo',
  export_to_excel: 'Exportar a Excel',
  used_appointment_date: 'Fecha de consulta utilizada',
  patient_creation_date: 'Fecha de registro del paciente',
  active_php: 'Paquete activo',
  php_creation_date: 'Fecha de asignación del paquete',
  php_creation_date_short: 'Fecha de asignación',
  php_appointments: 'Consultas del paquete',
  appointments_used: 'Consultas utilizadas',
  data: 'Datos',
  patients_report_title: 'Reporte de pacientes por paquete activo al {{- date, DD/MM/YYYY}} - {{name}}',
  download_success: 'Descarga exitosa',
  go_to_patient_profile: '¿Deseas ir al perfil de {{name}}?',
  php_appointments_used_info: 'Consultas realizadas o actualmente agendadas',
  // OnBoardingMain
  demo_mealPlan_name: 'Plan alimenticio (demo)',
  onboarding_signup: 'Crear tu cuenta en Almendra',
  onboarding_create_meal_plan: 'Creando un plan alimenticio',
  onboarding_create_meal_plan_by_template: 'Creando un plan a partir de una plantilla',
  onboaring_create_recipe: 'Creando una receta',
  onboarding_making_an_appointment: 'Haciendo una consulta',
  onboarding_creating_php: 'Creando paquetes nutricionales',
  onboarding_setup_agenda: 'Aprendiendo a agendar consultas',
  onboarding_customizing: 'Personalizando Almendra',
  onboarding_setup_booking: 'Aprendiendo a usar el sistema de reservas online',
  onboarding_register_revenue_expenses: 'Registrando ingresos y gastos',
  onboarding_patient_app: '¡Todos tus pacientes tienen un app!',
  onboarding_setup_schedule: 'Activando tu sistema de reservas online',
  onboarding_account_link: 'Intégrate con Google, Zoom, PayPal, MercadoPago',
  onboarding_setup_button: 'Configurar',
  onboarding_schedule_meeting: 'Agendando mi reunión virtual',
  onboarding_finished_modal_title: '¡Felicitaciones por terminar la introducción!',
  onboarding_finished_description_0: 'Ahora tendrás un <1>40% de descuento</1> en tu primera facturación de Almendra.',
  onboarding_finished_description_1: 'Cualquier duda que tengas, nos la puedes comunicar en el chat de soporte, estaremos felices de ayudarte.',
  onboarding_finished_description_2:
    'Tu opinión es importantísima para nosotros. Si hay algo que te gustaría encontrar en Almendra, utiliza el botón de Sugerencias en la barra superior y lo tomaremos en cuenta.',
  oboarding_intro_title: 'Guía de introducción',
  oboarding_intro_description: 'Por cada sección que completes tendrás <1>10%</1> de descuento extra en tu primer mes',
  oboarding_intro_discount: 'Total acumulado de descuento: <1>{{count}}%</1>',
  onboarding_first_step: 'El primer paso',
  onboarding_first_step_steps: '1 de 1',
  onboarding_about_mealPlans: 'Sobre el plan alimenticio, plantillas y recetas',
  onboarding_about_appointments: 'Sobre consultas, paquetes y agenda',
  of_step: 'de',
  onboarding_about_settings: 'Descubre el potencial de Almendra',
  onboading_about_meeting: 'Agenda tu entrenamiento con un miembro del equipo',
  // MealPlanCreate
  meal_plan_load_error: 'Parece que hubo un problema al cargar el plan alimenticio. Por favor, vuelve a intentarlo.',
  meal_plan_before_unload: 'Hay cambios sin guardar, ¿igual deseas salir?',
  meal_plan_peding_modal_title: 'Este plan alimenticio quedará pendiente',
  meal_plan_peding_modal_content: 'Podrás encontrarlo en la tabla de planes alimenticios pendientes dentro de la sección "Dietas"',
  meal_plan_of_pending: 'Plan Alimenticio de {{name}} pendiente',
  meal_plan_pending: 'Plan Alimenticio pendiente',
  meal_plan_reset_title: '¿Deseas volver a empezar a editar el plan alimenticio?',
  meal_plan_reset_content: 'Esta acción es irreversible y borrará todos los avances realizados.',
  yes_start_again: 'Sí, volver a empezar',
  meal_plan_reset_success: 'El plan alimenticio fue reiniciado',
  meal_plan_format_success: 'Formato establecido con éxito',
  meal_plan_exchange_tour_end_modal_title: 'El tour del Plan Alimenticio por Intercambios ha terminado',
  meal_plan_exchange_tour_end_modal_content:
    'Puedes continuar con la edición del plan alimenticio. Recuerda que puedes acceder nuevamente al tour en el ícono de "<1/>" que se encuentra antes del botón "Siguiente".',
  meal_plan_from_template_modal_title: '¿Deseas crear el plan alimenticio desde esta plantilla?',
  yes_create_from_template: 'Sí, crear desde plantilla',
  meal_plan_from_template_success: 'Plan alimenticio copiado desde plantilla',
  meal_plan_from_plan_success: 'Plan alimenticio copiado desde otro plan alimenticio',
  no_targets_placed: 'No has establecido objetivos para todos los días.',
  no_targets_placed_content:
    'Debes establecer objetivos para todos los días para poder continuar. Si deseas que el plan alimenticio tenga más o menos días modelo, haz click en Anterior e ingresa nuevamente el número de días que desees.',
  days_targets_required: 'Debes ingresar los objetivos calóricos para al menos un día antes de pasar a la elección de alimentos.',
  freeDay_check_modal_title: 'No has seleccionado objetivos para el día {{name}}.<1/><2/>¿Deseas seleccionarlo como día libre?',
  freeDay_check_modal_title_other: 'No has seleccionado objetivos para los días {{name}}.<1/><2/>¿Deseas seleccionarlos como días libres?',
  select_days_as_free: 'Seleccionar los días como libres',
  edit_caloric_target_success: 'Objetivos calóricos editados con éxito',
  meal_plan_v_mealPlanName_required: 'El nombre del plan alimenticio no puede estar vacío',
  edit_mealPlanName_success: 'Nombre del día modelo editado con éxito',
  mealPlan_day_add_success: 'Día agregado con éxito',
  mealPlan_day_add_error: 'Parece que hubo un error. No se pudo agregar el día.',
  mealPlan_day_delete_v_unique: 'Para poder borrar, asegúrate que el nombre del día sea único.',
  delete_day_ask: '¿Deseas eliminar este día?',
  delete_day_content: 'Todo lo asociado a este día se eliminará del plan alimenticio.',
  delete_day_success: 'Día eliminado con éxito',
  mealPlan_day_delete_error: 'Parece que hubo un error. No se pudo eliminar el día.',
  mealPlanName_edit_success: 'Nombre del plan alimenticio editado con éxito',
  mealPlan_meals_order_update_success: 'Orden de comidas actualizado con éxito',
  mealPlan_copy_day_success: 'Copiado realizado con éxito',
  mealPlan_exit_save_confirm: '¿Deseas salir del editor de plan alimenticio?\nTodos los cambios han sido guardados',
  mealPlan_empty_title: 'Parece que no has empezado a llenar el plan alimenticio...',
  mealPlan_empty_content: 'Para poder terminar este plan alimenticio tienes que ingresar al menos un alimento.',
  mealPlan_finish_success: 'Plan Alimenticio de {{name}} finalizado',
  mealPlan_delete_title: '¿Quieres eliminar este plan alimenticio?',
  mealPlan_delete_success: 'Plan alimenticio eliminado',
  mealPlan_exchange_tour_title: '¿Deseas iniciar el tour explicativo del Plan Alimenticio por Intercambios?',
  mealPlan_exchange_tour_content:
    'Al confirmar, te mostraremos un plan alimenticio de prueba para explicarte mejor las funcionalidades de este formato. Cuando el tour termine, podrás editar tu plan con normalidad.',
  yes_start_tour: 'Sí, iniciar tour',
  select_all_days: 'Selecciona todos los días',
  macro_distribution_required: 'Establece una distribución de macronutrientes',
  last_changes_were_unsaved: 'Los últimos cambios no fueron guardados. Por favor, refresca la página',
  saving_mealPlan: 'Guardando el plan alimenticio... Un momento, por favor',
  mealPlan_saving_error: 'No se pudo guardar los cambios',
  mealPlan_saving: 'Guardando cambios',
  mealPlan_unsaved: 'Cambios sin guardar',
  mealPlan_saved: 'Se han guardado todos los cambios',
  mealPlan_step_format: 'Formato',
  mealPlan_step_targets: 'Objetivos',
  mealPlan_step_plan: 'Plan',
  mealPlan_step_summary: 'Resumen',
  watch_tour: 'Ver tour explicativo',
  go_to_appointment: 'Regresar a la consulta',
  delete_plan: 'Eliminar plan',
  mealPlan_next_tooltip_button: 'Termina de seleccionar los objetivos en todos tus días, dándole click a los cuadrados en la tabla',
  // MealPlanTemplateCreate
  template_old_version_title: 'Encontramos una versión de esta plantilla que no ha sido guardada',
  template_old_version_content_0: 'Se encontró una versión no guardada cuya última fecha de modificación fue',
  template_old_version_content_1: 'La última fecha de modificación de la plantilla actual fue',
  template_old_version_content_2: '¿Deseas recuperar la versión no guardada de la plantilla?',
  recover_version: 'Recuperar esa versión',
  discard_version: 'Descartar esa versión',
  recover_version_error: 'No se pudo recuperar la versión. Por favor, refresca la página y vuelve a intentarlo',
  is_template_finished_title: '¿Terminaste de editar la plantilla?',
  is_template_finished_content: 'Si seleccionas "No, la terminaré luego", aparecerá en tus plantillas pendientes.',
  no_will_finish_later: 'No, la terminaré luego',
  template_finished_success: "Plantilla '{{name}}' terminada",
  template_pending_with_name: "Plantilla '{{name}}' pendiente",
  template_pending: 'Plantilla pendiente',
  template_reset_title: '¿Deseas volver a empezar a editar la plantilla?',
  template_reset_success: 'La plantilla fue reiniciado',
  template_v_mealPlanName_required: 'El nombre de la plantilla no puede estar vacío',
  template_edit_mealPlanName_success: 'Nombre de la plantilla editado con éxito',
  template_empty_title: 'Parece que no has empezado a llenar el plan alimenticio...',
  template_empty_content: 'Para poder terminar este plan alimenticio tienes que ingresar al menos un alimento.',
  template_delete_title: '¿Quieres eliminar esta plantilla?',
  template_delete_success: 'Plantilla eliminada',
  // MealPlanTemplateMain
  edit_template_title: '¿Deseas editar la plantilla "{{name}}"?',
  clone_template_title: '¿Deseas duplicar la plantilla "{{name}}"?',
  clone_template_content: 'Se duplicarán todos los días con sus comidas y alimentos, respectivamente.',
  yes_clone_template: 'Sí, duplicar plantilla',
  clone_template_success: 'Plantilla "{{name}}" duplicada con éxito',
  delete_template_title: '¿Deseas borrar la plantilla "{{templateName}}"?',
  yes_delete_template: 'Sí, eliminar plantilla',
  delete_template_success: 'Plantilla "{{name}}" eliminada con éxito',
  delete_template: 'Eliminar plantilla',
  you_have_x_pending_templates: 'Tienes {{count}} plantilla pendiente por completar',
  you_have_x_pending_templates_other: 'Tienes {{count}} plantillas pendientes por completar',
  pending_templates: 'Plantillas pendientes',
  meal_plan_templates: 'Plantillas de planes alimenticios',
  finished_templates: 'Plantillas terminadas',
  per_daily_calories: 'Por calorías diarias',
  per_format: 'Por formato',
  // PopupTargetMeals
  only_one_meal_no_block: 'Solo existe una comida, así que no es necesario bloquearla',
  has_already_been_blocked: 'también ha sido bloqueado',
  lock_meal_target_description:
    'Como el resto de tiempos de comida fueron bloqueados, también se bloqueó {{name}}, dado que ya no se hubiese podido modificar.',
  at_least_one_meal: 'Debes tener por lo menos una comida',
  all_percentage_to_assign: '¡Debes asignar el 100% de las calorías!',
  distribution_per_meal_time: 'Distribución por tiempo de comida',
  days_have_same_meal_targets: 'Los días seleccionados tienen la misma distribución de calorías por tiempo de comida',
  delete_x: '¿Eliminar {{name}}?',
  lock_target: 'Bloquear objetivo',
  unlock_target: 'Desbloquear objetivo',
  distribute_macros: 'Distribuir macros',
  should_have_calories_in_meal: 'Para distribuir los macros, la comida debe tener calorías ingresadas',
  we_found_an_inconsistency: 'Encontramos una inconsistencia',
  selectedInconsistencyObjective_title:
    'Los objetivos de macronutrientes diarios (los de la parte de arriba de esta ventana) que colocaste no calzan con los objetivos de macronutrientes ponderados por tiempo de comida y sus respectivas calorías. Debes decidir con cuáles quedarte. ¡Si necesitas ayuda no dudes en contactarnos a través del chat!',
  selectedInconsistencyObjective_description: 'Selecciona con qué objetivos deseas quedarte',
  daily_targets: 'Objetivos diarios',
  weighted_objectives: 'Objetivos ponderados',
  // BillingMain
  with_scheduled_subscription_title: 'Cuentas con un próximo plan programado para el {{- date, DD/MM/YYYY}}',
  with_scheduled_subscription_content:
    'Para poder elegir un nuevo plan de suscripción, primero cancela tu suscripción futura en <1>Configuración > Suscripción</1>.',
  do_you_want_to_resuscribe: '¿Deseas volver a suscribirte al {{planName}}?',
  regulate_subscription_title: '¿Deseas continuar con tu suscripción al {{name}}?',
  regulate_subscription_description: 'Se procederá a suscribirte al {{name}} dado que hubo un error en el cobro de tu renovación automática.',
  yes_subscribe: 'Sí, suscribirme',
  pay_subscription_upgrade_0: 'Estás cambiando del <1>{{fromPlan}}</1> al <3>{{toPlan}}</3>.',
  pay_subscription_upgrade_1: 'Como todavía tienes un plan en vigencia, <1>te devolveremos el monto que aún no consumes de tu plan actual.</1>',
  pay_subscription_upgrade_2:
    'Por ejemplo: si tu próxima fecha de facturación es dentro de 15 días y tu plan tiene facturación mensual, te devolveremos el 50% (mitad del mes) del monto pagado de tu suscripción anterior.',
  pay_subscription_upgrade_3: 'Apenas confirmes, podrás aprovechar todos los beneficios de tu nuevo plan.',
  pay_subscription_downgrade_0:
    'En tu próxima fecha de facturación (<1>{{nextDate}}</1>) tu suscripción se cambiará del <3>{{fromPlan}}</3> al <5>{{toPlan}}</5>.',
  pay_subscription_downgrade_1: 'Hasta entonces, podrás aprovechar los beneficios de tu suscripción actual (<1>{{name}}</1>).',
  pay_subscription_new: 'Estás suscribiéndote al {{name}}. Apenas confirmes podrás aprovechar todos los beneficios de tu nuevo plan.',
  you_are_subscribing_same_plan: 'Estás tratando de suscribirte al mismo plan.',
  pay_subscription_modal_title: '¿Deseas suscribirte al {{name}}?',
  yes_schedule_subscription: 'Sí, programar suscripción',
  pay_subscription_modal_error: 'Ocurrió un error. Por favor, refresca la página. En caso el error persista, comunícate con nosotros por el chat.',
  validating: 'Validando...',
  scheduling_subscription: 'Programando cambio de plan en la próxima facturación',
  certifying_transaction: 'Certificando transacción',
  paying_content: 'Esta acción podría tardar unos segundos. Por favor, no cierres ni refresques esta página',
  next_subscription_due_title: 'Cuentas con un próximo plan que será aplicado el {{nextDate}}',
  next_subscription_due_content: 'Para poder elegir un nuevo plan de suscripción, primero cancela tu suscripción futura',
  click_here: 'Click aquí',
  plan: 'Plan',
  payment: 'Pago',
  go_back: 'Atrás',
  checkout: 'Checkout',
  certifying_transaction_wait: 'Certificando transacción. En breve estamos de vuelta contigo...',
  you_have_discount_referral: '¡Tienes <1>{{referralBenefit}}% de descuento</1> de por vida gracias al programa de referidos!',
  your_subscription_details: 'Detalles de tu suscripción',
  first_month: 'primer mes',
  after_discount: 'Luego: US$ {{price}}/mes',
  month: 'mes',
  normal_price: 'Precio normal',
  free_plan_disclaimer_2_dollars: 'Para procesar con la suscripción gratis, se te cargará un monto simbólico de US$ 2 por única vez.',
  this_plan_will_be_billed: 'Este plan será facturado {{name}}',
  next_billing_date: 'Próxima facturación',
  pci_disclaimer_processing: 'Este pago es procesado de forma segura por {{name}}, un proveedor de pagos PCI de nivel 1.',
  regulate_subscription_alert:
    'Para regularizar tu suscripción por <1>error en el cobro</1>, vuelve a suscribirte al actual. También puedes aprovechar en elegir otros planes que se adapten a tus necesidades.',
  confirm_and_pay: 'Confirmar y pagar',
  processing_transaction: 'Procesando transacción',
  already_subscribed_alert_m: 'Ya te encuentras suscrito a este plan.',
  already_subscribed_alert_f: 'Ya te encuentras suscrita a este plan.',
  summary: 'Resumen',
  awesome: '¡Excelente!',
  subscription_create_success: 'Tu suscripción se ha realizado con éxito',
  subscription_schedule_success: 'Tu suscripción ha sido programada con éxito',
  start_date: 'Fecha de inicio',
  go_to_home: 'Ir a Inicio',
  error_occurred: 'Ocurrió un error',
  subscription_create_error_title: 'Tu suscripción no se pudo realizar.',
  subscription_create_error_description:
    'No te preocupes, esto significa que no hubo algún cobro de tu tarjeta. Verifica que la información que ingreses sea válida.',
  go_previous: 'Regresar',
  'Plan Básico': 'Plan Básico',
  'Plan Avanzado': 'Plan Avanzado',
  'Plan Experto': 'Plan Experto',
  'Plan Ilimitado': 'Plan Ilimitado',
  Avanzado: 'Avanzado',
  Experto: 'Experto',
  Ilimitado: 'Ilimitado',
  billing_fetch_error: 'Ups! Ocurrió un error. Por favor, refresca la página y vuelve a intentarlo',
  // WorkoutPDF
  workout_routine: 'Rutina de entrenamiento',
  workout_with_equipment: 'Esta rutina requiere equipamiento',
  workout_without_equipment: 'Esta rutina requiere equipamiento',
  exercise_days: 'Días de ejercicio',
  minutes_duration: 'Duración (en minutos)',
  routine_goal: 'Objetivo de la rutina',
  routine_details: 'Detalle de la rutina',
  // TableMealPlanDays
  custom_notification_tmpd_add: 'para agregar este alimento en esa comida',
  custom_notification_tmpd_delete: 'para eliminar este alimento de esa comida',
  custom_notification_tmpd_finish: 'Click en Terminar o en el buscador para agregar otra comida',
  copying_meal: '¡Estas copiando esta comida!',
  already_copied_meal: '¡Esta comida ya ha sido copiada!',
  cannot_copy_meal_free_day: 'No puedes copiar una comida en un día libre',
  copy_meal_title: '¿Deseas copiar esta comida?',
  copy_meal_description:
    'En caso la comida que acabas de seleccionar contenga alimentos, estos serán reemplazados por los de la comida "{{mealName}} - {{dayName}}". Esta acción es irreversible.',
  yes_copy_meal: 'Sí, copiar comida',
  belongs_to_free_day_v: 'No puedes agregar un alimento en una comida que pertenece a un día libre',
  drop_belongs_to_free_day_v: 'Para agregar un alimento primero cambia el {{dayName}} a un día que no sea libre',
  meal_belongs_to_free_day_v: 'Para agregar una comida primero cambia el {{dayName}} a un día que no sea libre',
  random_equivalence: 'Equivalencia aleatoria',
  information: 'Información',
  delete_meal_row_title: '¿Eliminar la comida {{mealName}} en todos los días?',
  delete_meal_row_description:
    'Si alguno de los días tiene objetivos calóricos por comida, estos serán restablecidos. Puedes volver a asignarlos en el paso anterior.',
  food_name_v_required: 'El nombre del alimento no puede estar vacío',
  pre_confirm_add_many_foods_v: 'No has seleccionado comidas para agregar este alimento',
  delete_meal_button_title: '¿Deseas eliminar "{{mealName}} - {{dayName}}"?',
  yes_delete_meal: 'Sí, eliminar comida',
  delete_meal_button_content:
    'Realiza esta acción si quieres que el paciente no tenga {{mealName}} este día. Al confirmar, los alimentos, comentarios y tiempo de comida serán borrados y no podrán ser recuperados.',
  delete_meal_button_extra: 'Se restablecerán los objetivos calóricos de las comidas de este día. Puedes volver a asignarlos en el paso anterior.',
  day_is_already_empty: 'Este día ya se encuentra vacío',
  reset_day_title: '¿Deseas borrar el contenido del día {{name}}?',
  set_free_day_title: '¿Deseas que {{dayName}} sea un día libre?',
  unset_free_day_title: '¿Deseas que {{dayName}} deje de ser un día libre?',
  yes_set_free_day: 'Sí, convertir en día libre',
  yes_unset_free_day: 'Sí, ir a la elección de objetivos',
  unset_free_day_content:
    'Al confirmar, irás al paso de la elección de los objetivos calóricos y de macronutrientes, para que le coloques objetivos a este día.',
  set_free_day_content_0:
    'Este día no tendrá alimentos asignados. Sin embargo, puedes colocar indicaciones en el último paso de la elaboración del plan alimenticio.',
  set_free_day_content_1: 'Si agregas un alimento a este día, automáticamente dejará de ser un día libre.',
  add_blank_meal_v: 'Debes ingresar por lo menos un alimento',
  replace_blank_meal_title: '¿Deseas reemplazar esta comida?',
  replace_blank_meal_content:
    'La comida que acabas de seleccionar ya contiene alimentos. Al hacer click en "Sí, reemplazar comida", todo lo escrito en la comida seleccionada será reemplazado por lo escrito en el campo que se encuentra debajo de la tabla. Esta acción es irreversible.',
  yes_replace_meal: 'Sí, reemplazar comida',
  handle_blank_area_click: 'Haz click en Terminar para poder escribir más alimentos',
  food_already_added_meals: 'Este alimento ya se encuentra en esta comida para todos los días',
  special_condition_requirements_pregnancy: 'Los requerimientos han sido modificados específicamente para pacientes embarazadas.',
  special_condition_requirements_lactating: 'Los requerimientos han sido modificados específicamente para pacientes lactantes.',
  day_target: 'Objetivo',
  day_with_meal_time_targets: 'Día con objetivos por tiempo de comida',
  copy_with_equivalence: 'Copiar con equivalencias',
  set_free_day: 'Día libre',
  unset_free_day: 'Quitar día libre',
  legend: 'Leyenda',
  foods: 'Alimentos',
  place_it: 'Colocar',
  one_food_per_line: 'Un alimento por línea',
  meal: 'Comida',
  // MealPlanTemplatesTable
  empty_pending_meal_plan_templates: 'Aún no creas plantillas',
  template: 'Plantilla',
  duplicate_template: 'Duplicar plantilla',
  // PopupUploadPatients
  file_loaded_successfully: '{{name}} cargado exitosamente!',
  import_patients: 'Importar pacientes',
  import_patients_list: 'Importar lista de pacientes',
  import_your_patients: 'Importa tus pacientes a tu cuenta',
  import_patients_step_1: 'Descarga nuestra plantilla y sigue las indicaciones durante el llenado de información de tus pacientes.',
  import_patients_step_2: 'Luego de descargar la plantilla y completarla, súbela aquí.',
  import_patients_step_3: 'Haz click en "Importar pacientes" para subir tus pacientes al sistema.',
  import_patients_step_4: '¡Listo! Actualiza la página y podrás ver tus nuevos pacientes.',
  import_patients_file_name: 'Plantilla Almendra',
  download_template: 'Descargar plantilla',
  upload_file: 'Subir archivo',
  uploading_file: 'Cargando archivo',
  // PreciseObjective
  delete_precise_objective_title: '¿Deseas eliminar el objetivo preciso?',
  delete_precise_objective_content: 'Se borrarán los campos asociados a este objetivo.',
  patient_with_objective: '¿{{name}} tiene un objetivo preciso?',
  precise_objective_ex: 'Objetivo preciso es aquel que tiene una medida específica y una fecha límite, ej: Llegar a 65kg en 4 meses',
  measuremente_value_target: 'Valor deseado',
  end_date: 'Fecha de fin',
  initial_value: 'Valor inicial',
  recent_value: 'Valor reciente',
  measured_on: 'medido el',
  add_at_least_two_measurements: 'Ingresa al menos dos medidas desde la fecha de inicio para poder ver el progreso',
  // AddBlogPost
  enter_a_valid_title: 'Ingresa un título válido',
  create_new_post: 'Crear nueva publicación',
  new_post: 'Nueva publicación',
  post_title: 'Título de la publicación',
  post_link: 'Link del blog',
  blog_post_v_title_required: 'Por favor, ingresa el título',
  blog_post_v_title_min: 'Ingresa al menos 12 caracteres',
  blog_post_v_title_unique: 'Ya has creado una publicación con este título. Recuerda que el título de la publicación debe ser único.',
  enter_title: 'Ingresa el título',
  // PatientCustomFields
  custom_fields_title: 'Campos personalizables (Perfil de paciente)',
  custom_fields_subtitle:
    'Crea tus propios campos personalizados para guardar información adicional en el perfil de tus pacientes. Podrás visualizarlos en la pestaña <1>Campos propios</1> dentro de la sección <3>Información</3> en el perfil de paciente.',
  patient_profile: 'Perfil de paciente',
  custom_fields_settings_extra:
    '¿No encuentras algun campo en el perfil? Crea tus propios campos personalizados para guardar más información sobre tus pacientes',
  setup: 'Configurar',
  custom_fields_name: 'Nombre del campo',
  custom_fields_name_validator: 'Por favor, ingresa el nombre del campo personalizable',
  custom_fields_type: 'Tipo',
  custom_fields_type_long: 'Tipo del campo',
  custom_fields_type_validator: 'Por favor, selecciona el tipo del campo personalizable',
  custom_fields_options: 'Opciones',
  custom_fields_options_long: 'Opciones del campo',
  custom_fields_options_extra: 'Añade las posibles opciones para este campo personalizable',
  custom_fields_short_answer: 'Respuesta corta',
  custom_fields_paragraph: 'Párrafo',
  custom_fields_number: 'Número',
  custom_fields_radio: 'Radio',
  custom_fields_tags: 'Desplegable (opción múltiple)',
  custom_fields_select: 'Desplegable (única opción)',
  custom_fields_delete_title: '¿Deseas eliminar este campo personalizable?',
  custom_fields_delete_content:
    'También se eliminará este campo de tus formularios de consulta donde lo hayas incluído. Te recordamos que esta acción es irreversible.',
  custom_fields_delete_error: 'Ocurrió un problema al eliminar el campo',
  custom_fields_delete_success: 'Campo personalizado eliminado con éxito',
  custom_fields_edit_error: 'Ocurrió un problema al editar el campo',
  custom_fields_edit_success: 'Campo personalizado editado con éxito',
  custom_fields_add_error: 'Ocurrió un problema al agregar el campo',
  custom_fields_add_success: 'Campo personalizado agregado con éxito',
  custom_fields_validator_options_min: 'Añade al menos 2 opciones',
  custom_fields_validator_options_empty: 'Las opciones no pueden estar vacías',
  custom_fields_validator_options_unique: 'El contenido de cada opción debe ser único',
  custom_fields_create: 'Crear campo',
  custom_fields_create_title: 'Crear campo personalizable',
  custom_fields_edit_title: 'Editar campo personalizable',
  add_option: 'Añadir opción',
  custom_fields_default_value: 'Aquí iría el valor del campo',
  this_is_an_answer: 'Esta es una respuesta',
  this_is_a_long_answer: 'Esta es una respuesta pero más larga, en la que el usuario podrá ingresar más detalles...',
  example: 'Ejemplo',
  // EmptyCustomFields
  empty_custom_fields_title: 'Todavía no has creado tus propios campos personalizados.',
  empty_custom_fields_create_pre: 'Créalos fácilmente en',
  empty_custom_fields_path: 'Ajustes Generales > Perfil de paciente > Campos personalizados',
  empty_custom_fields_purpose_title: '¿Para qué sirven los campos personalizados?',
  empty_custom_fields_purpose_content:
    'Los campos personalizados sirven para guardar información del paciente cuando no encuentres un campo en el perfil de paciente, podrás crear tus propios campos personalizados con el título y formato de campo.',
  empty_custom_fields_purpose_example:
    'Por ejemplo, si deseas guardar la información "<1>{{label}}</1>" podrás hacerlo e incluso agregar opciones como "<3>{{option_1}}</3>", "<5>{{option_2}}</5>" y "<7>{{option_3}}</7>".',
  empty_custom_fields_purpose_result: 'El resultado será el siguiente:',
  empty_custom_fields_purpose_example_label: '¿Quién prepara las comidas?',
  empty_custom_fields_purpose_example_option_1: 'El/Ella mismo/a',
  empty_custom_fields_purpose_example_option_2: 'Familiar',
  empty_custom_fields_purpose_example_option_3: 'Otra persona',
  // CustomFormsModal
  custom_form_delete_title: '¿Deseas eliminar este formulario?',
  custom_form_delete_content:
    'Cualquier paciente que actualmente tenga un formulario pendiente no podrá llenarlo. Te recordamos que esta acción es irreversible.',
  custom_form_delete_success: 'Formulario eliminado con éxito',
  custom_form_delete_error: 'Hubo un error eliminando el formulario. Inténtalo más tarde.',
  dont_send_form: 'No enviar formulario',
  see_my_forms: 'Ver mis formularios',
  my_custom_forms: 'Mis formularios de consulta',
  no_custom_forms: 'Actualmente no tienes formularios de consulta',
  // ImagesUploadingInstructions
  image_upload_instruction_1: 'Sube una imagen con el botón de abajo.',
  image_upload_instruction_2: 'Haz click en <1>Copiar enlace</1> al costado de la imagen subida.',
  image_upload_instruction_3: 'En el editor de texto, haz click en <1>Imagen..</1> (Insertar - Imagen).',
  image_upload_instruction_4: 'Pega el link generado en el campo <1>Enlace</1> y añade una descripción.',
  // EditBlogPost
  delete_image_title: '¿Deseas eliminar esta imagen?',
  delete_image_success: 'Imagen eliminada con éxito',
  delete_image_error: 'Hubo un error al eliminar la imagen',
  upload_image_success: 'Imagen subida con éxito',
  upload_image_error: 'Hubo un error al subir la imagen',
  blog_post_edit_success: 'Los cambios de tu publicación se han guardado con éxito.',
  blog_post_edit_error: 'Ocurrió un error al guardar el contenido de tu publicación.',
  edit_post: 'Editar publicación',
  delete_post: 'Eliminar publicación',
  how_to_upload_image_blog: '¿Cómo subir una imagen a tu publicación?',
  blog_post_tag_extra: 'Las etiquetas son palabras claves que describen el contenido de tu publicación. Sepáralas ingresando una coma.',
  blog_post_tag_extra_ex: 'Ejemplo: salud, nutrición, estilo de vida, equilibrio',
  content: 'Contenido',
  blog_post_editor_initial_0: '&iexcl;Hola, {{firstName}}!',
  blog_post_editor_initial_1: 'En esta secci&oacute;n podr&aacute;s:',
  blog_post_editor_initial_2: 'Escribir tus art&iacute;culos.',
  blog_post_editor_initial_3: 'Desaf&iacute;ar tu creatividad.',
  blog_post_editor_initial_4: 'Compartir tu conocimiento con el mundo.',
  blog_post_editor_initial_5: 'Te deseamos lo mejor,',
  blog_post_editor_initial_6: 'El equipo de Almendra :)',
  follow_steps_upload_image: 'Sigue los siguientes pasos para subir una imagen a tu {{name}}',
  blog_post_images: 'Imágenes de la publicación',
  uploading_image: 'Subiendo imagen',
  blog_post_upload_image: 'Sube una imagen a tu publicación',
  // EditPersonalPageContent
  personal_page_edit_success: 'Los cambios de página personal se han guardado con éxito.',
  personal_page_edit_error: 'Ocurrió un error al guardar el contenido de tu página personal.',
  how_to_upload_image_personal_page: '¿Cómo subir una imagen a tu página personal?',
  images_folder: 'Carpeta de imagenes',
  upload_image: 'Subir imagen',
  delete_image: 'Eliminar imagen',
  // GeneralSettings
  change_currency_title: 'Vas a proceder a cambiar el tipo de moneda en todo el software, ¿deseas continuar?',
  change_currency_content_0:
    'Al darle click a "Si, continuar" <1>toda tu contabilidad cambiará de moneda y se editarán todos los pagos que has recibido históricamente a esta nueva moneda.</1>. Se utilizará el tipo de cambio del día para realizar las conversiones de tus pagos.',
  change_currency_content_1: 'Solo recomendamos hacer esto en caso hayas cambiado permanentemente el tipo de moneda que recibes por tus servicios.',
  change_currency_error: 'No se pudo realizar el cambio de moneda. Por favor, inténtalo más tarde.',
  change_measurement_title: '¿Deseas cambiar el método de medida de la cintura?',
  you_have_selected_at_least_one_of: 'Tienes seleccionada por lo menos una de las siguientes medidas',
  change_measurement_many_waist:
    'Parece que en este momento quieres cambiar el método de medida del perímetro de la cintura. Por eso, al hacer click en Aceptar, se deseleccionará cualquiera de esas medidas que tengas, y se seleccionará',
  you_have_selected: 'Tienes seleccionada la medida',
  change_measurement_many_waist_2:
    'Parece que en este momento quieres cambiar el método de medida del perímetro de la cintura. Por eso, al hacer click en Aceptar, se deseleccionará esa medida, y se seleccionarán',
  field_must_not_be_empty: 'El campo no puede estar vacío',
  your_current_method_is_already_name: 'Tu método actual ya es el {{name}}',
  body_fat_method_v: 'No has seleccionado todas las medidas necesarias para utilizar esta fórmula',
  general_setting_error: 'Ocurrió un error mientras realizabas los cambios. Por favor, inténtalo nuevamente.',
  general_disclaimer_foodSource:
    'Algunos nombres de alimentos de las bases de datos han sido levemente modificados con el objetivo de tener un significado más claro para su lectura.',
  data_bases: 'Bases de datos',
  spanish: 'Español',
  english: 'Inglés',
  units: 'Unidades',
  length: 'Longitud',
  meter: 'Metro',
  foot_and_inch: 'Pie y Pulgada',
  kilogram: 'Kilogramo',
  pound: 'Libra',
  energy: 'Energía',
  kilocalorie: 'Kilocaloría',
  kilojoule: 'Kilojoule',
  appointments: 'Consultas',
  appointments_settings_label: 'Mostrar consultas agendadas que empiecen dentro de',
  appointments_settings_extra: 'Se mostrará en Inicio y en el botón de Iniciar Consulta',
  average_appointment_duration: 'Duración promedio de una consulta',
  make_online_appointments: '¿Realizas consultas online?',
  make_delivery_appointments: '¿Realizas consultas a domicilio?',
  send_schedule_email_appointment: '¿Deseas enviarle un mail automático al paciente al agendarle una consulta?',
  request_custom_form_fill: 'Solicitar el llenado de formulario previo a la consulta',
  request_custom_form_fill_v_empty: 'Comienza creando tus formularios para usar esta funcionalidad.',
  request_custom_form_fill_v_enabled: 'Se pedirá el llenado del formulario a los pacientes cuando su consulta sea agendada.',
  request_custom_form_fill_v_disabled: 'No se pedirá el llenado del formulario a tus pacientes.',
  office_address: 'Dirección del consultorio',
  business_hours: 'Horario de atención',
  business_hours_empty: 'Todavía no has configurado tu horario de atención.',
  predictive_formulas: 'Fórmulas predictivas',
  current_method: 'Método actual',
  save_method: 'Guardar Método',
  required_measurements_on_method: 'Medidas requeridas en el',
  show_DCText: 'Se obtiene la densidad corporal. Se aplicará el {{- name}} para llegar al % de grasa.',
  density_to_body_fat: 'Densidad corporal a porcentaje de grasa',
  'Método de Siri': 'Método de Siri',
  'Método de Brozek': 'Método de Brozek',
  body_density_disclaimer:
    'La densidad corporal será hallada a partir del método elegido en "Porcentaje de grasa", en caso el método devuelva como resultado la densidad corporal',
  consumption_frequency_form: 'Formulario de frecuencia de consumo',
  consumption_frequency_form_extra_settings:
    'Agrega y edita los grupos de alimentos que desees. Podrás encontrar el formulario dentro de la sección "Registro de Alimentos" en el perfil de cada paciente.',
  meal_plan_error_range: 'Margen del objetivo calórico (progreso)',
  meal_plan_error_range_extra:
    'Ej: Cuando el margen de error sea 10% y el día del plan alimenticio tenga como objetivo 2000kcal, si la suma de las calorías del día se encuentra entre 1900-2100 kcal entonces se te notificará que has completado el día.',
  meal_plan_error_range_placeholder: 'Ej: 4%',
  finance: 'Finanzas',
  currency: 'Moneda',
  card_comission: 'Comisión promedio de tarjeta (POS)',
  card_comission_extra: 'En caso recibas pagos con tarjeta de débito o crédito para tus consultas.',
  card_comission_placeholder: 'Ej: 8%',
  timezone: 'Zona horaria',
  'Método 7 Calipers de Jackson/Pollock': 'Método 7 Calipers de Jackson/Pollock',
  'Método 4 Calipers de Jackson/Pollock': 'Método 4 Calipers de Jackson/Pollock',
  'Método 3 Calipers de Jackson/Pollock': 'Método 3 Calipers de Jackson/Pollock',
  'Método de Durnin/Womersley': 'Método de Durnin/Womersley',
  'Método de Petroski': 'Método de Petroski',
  'Método de Sloan': 'Método de Sloan',
  'Método de Wilmore-Behnke': 'Método de Wilmore-Behnke',
  'Método de Katch-McArdle': 'Método de Katch-McArdle',
  'Método de Lean': 'Método de Lean',
  'Método de Faulkner': 'Método de Faulkner',
  'Método de Carter': 'Método de Carter',
  'Método de Ramirez-Torun': 'Método de Ramirez-Torun',
  'Método de Deurenberg': 'Método de Deurenberg',
  'Método de Yuhasz': 'Método de Yuhasz',
  Básico: 'Básico',
  Diámetro: 'Diámetro',
  Perímetro: 'Perímetro',
  'Pliegue Cutáneo': 'Pliegue Cutáneo',
  Analítico: 'Analítico',
  'Índices corporales': 'Índices corporales',
  'Longitud/Altura': 'Longitud/Altura',
  mealPlan_preview_front: 'Vista previa de la carátula de tus planes',
  default_image: 'Imagen predeterminada',
  default_mealPlan_pdf_image: 'Imagen del plan alimenticio en PDF (cabecera)',
  default_mealPlan_pdf_image_extra: 'Procura que la imagen tenga dimensiones de ratio 23:7 (2300 x 700) para evitar que se vea distorsionada',
  language: 'Idioma',
  language_change_title: '¿Deseas cambiar el idioma del software?',
  language_change_description: 'Te informamos que también se cambiará el idioma de las notificaciones que te enviemos por email.',
  language_des: 'El idioma de tu dashboard. Se cambiará también el idioma de las notificaciones por email que te enviemos.',
  language_es: 'Español',
  language_en: 'Inglés',
  delete_mp_cover_title: '¿Deseas eliminar esta imagen?',
  delete_mp_cover_description: 'La imagen de cabecera del PDF de plan alimenticio será eliminada. Se restablecerá a la imagen predeterminda.',
  yes_delete_image: 'Sí, eliminar imagen',
  deleted_image_success: 'Imagen eliminada con éxito',
  deleted_image_error: 'Hubo un error al eliminar la imagen',
  // BlockServiceHours
  available: 'Disponible',
  unavailable: 'No disponible',
  blocked_future_hours: 'Tienes {{count}} bloqueo futuro',
  blocked_future_hours_other: 'Tienes {{count}} bloqueos futuros',
  drag_to_block: 'Arrastra las horas que desees bloquear',
  block_hours: 'Bloquear horas',
  block_service_hours_description:
    'Tus pacientes no podrán agendar citas durante los horarios seleccionados. Ideal en caso tengas algo que hacer y no podrás atender a nadie. En caso desees eliminar un bloqueo, simplemente haz click en el área bloqueada.',
  block_selected_hours: 'Bloquear horario',
  delete_selected_block: 'Eliminar bloqueo',
  delete_selected_block_des:
    'Al eliminar el bloqueo, volverás a habilitar la disponibilidad del horario seleccionado, permitiendo a los pacientes que reserven citas en ese rango.',
  // PopupAddPastAppointment
  height_v_min: 'El valor de Altura parece ser muy pequeño, recuerda que debe ser ingresada en cm',
  add_past_appointment_v_min: 'Por favor, selecciona al menos una medida para continuar',
  add_more_past_appointments_title: '¿Deseas añadir más medidas pasadas para este paciente?',
  yes_add_more_past_measurements: 'Sí, añadir más medidas pasadas',
  predictive_equation: 'Ecuación predictiva',
  change_measurement_arguments: 'Puedes editar esta medida directamente o cambiando sus argumentos.',
  this_measurement_has_formula: 'Esta medida tiene como fórmula {{- name}}.',
  the_following_equation: 'la siguiente ecuación',
  the_equation_x: 'el {{- name}}',
  add_past_measurements: 'Agregar medidas pasadas',
  add_measurements: 'Añadir medidas',
  do_it_here: 'hazlo aquí',
  registration_date: 'Fecha de registro',
  past_appointment_v_unique: 'Ya existen registros en esta fecha',
  measurements_taken: 'Medidas realizadas',
  enter_a_measurement: 'Ingresa una medida',
  add_more_measurements: 'Añadir más medidas',
  // AppointmentsMeasurements
  today: 'Hoy',
  not_found_with_search: 'No se encontraron medidas con la búsqueda',
  no_measurements_found: 'No se encontraron medidas disponibles',
  description: 'Descripción',
  bmi_classification: 'Clasificación del Índice de Masa Corporal (IMC)',
  bmi_alert_description: 'Debes ingresar el peso y la altura del paciente para visualizar el Índice de masa corporal.',
  last_two_appointment_measurements: 'Estas son las medidas modificadas en las últimas dos consultas realizadas con este paciente',
  appointment_measurements_select_placeholder: 'Selecciona una medida para ingresar su valor',
  last_measurements: 'Últimas medidas',
  all_measurements: 'Todas las medidas',
  measurements_of: 'Medidas de',
  all: 'Todas',
  last_3_appointments: 'Últimas 3 consultas',
  last_6_appointments: 'Últimas 6 consultas',
  last_9_appointments: 'Últimas 9 consultas',
  all_appointments: 'Todas las consultas',
  normal_values: 'Valores normales',
  as_maximum: 'como máximo',
  as_minimum: 'como mínimo',
  patient_inside_normal_range: 'El paciente se encuentra en el rango normal',
  patient_outside_normal_range: 'El paciente NO se encuentra en el rango normal',
  normal_values_source: 'Fuente: MedlinePlus (Biblioteca Nacional de Medicina de los EEUU) y laboratorios Blufstein',
  search: 'Buscar',
  clear: 'Limpiar',
  search_measurement: 'Buscar medida',
  predictive_measurement: 'Ecuación predictiva',
  predictive_measurement_is_output:
    'Esta medida fue calculada a partir de la siguiente ecuación. Para editar esta medida cambia alguno de sus argumentos y esta variará de manera automática.',
  calculated_value_is: 'El valor calculado es',
  actual_value_cannot_backup: 'El valor actual no podrá ser recuperado',
  height_minimum_change: 'Ese valor parece ser muy pequeño, recuerda que la altura debe ser ingresada en cm',
  can_use_fat_percentage_equation: 'Puedes utilizar el {{name}} para obtener el porcentaje de grasa, que toma en cuenta las siguientes medidas:',
  can_use_next_equation: 'Puedes utilizar la siguiente ecuación para calcular esta medida:',
  predictive_measurement_could_update: 'Esta medida podrá ser modificada automáticamente cuando ingreses las medidas <1>{{name}}</1>:',
  of: 'del',
  of_the_following_equation: 'de la siguiente ecuación',
  measurement_basic: 'Básico',
  measurement_basic_desc: 'Ej. Peso',
  measurement_skinFold: 'Pliegues Cutáneos',
  measurement_skinFold_desc: 'Ej. Pliegue cutáneo abdominal',
  measurement_diameter: 'Díametro',
  measurement_diameter_desc: 'Ej. Diámetro del codo',
  measurement_girth: 'Circunferencia',
  measurement_girth_desc: 'Ej. Perímetro de la cadera',
  measurement_analytical: 'Analítico',
  measurement_analytical_desc: 'Ej. Colesterol HDL',
  measurement_bodyIndex: 'Índices Corporales',
  measurement_bodyIndex_desc: 'Ej. % de grasa',
  measurement_lengths: 'Longitud/Altura',
  measurement_lengths_desc: 'Ej. Acromial-radial',
  apply: 'Aplicar',
  // UpgradeSubscription
  upgrade_subscription_hoc_title: 'Esta funcionalidad no está disponible para el {{planName}}',
  upgrade_subscription_hoc_description: 'Mejora tu plan para poder disfrutarla :)',
  upgrade_my_plan: 'Mejorar mi plan',
  //
  // SMT
  //
  // AppointmentMealPlan
  mealPlan_for_firstName_lastName: 'Plan alimenticio para <1>{{firstName}}</1> <2>{{lastName}}</2>',
  mealPlan_how_to_complete:
    'Podrás completar los planes alimenticios durante la consulta o una vez terminada. Encuéntralos en "Dietas" en la barra lateral.',
  // ErrorNotification
  error_notification_description: 'Parece que hubo un problema al realizar esta acción. Por favor vuelve a intentarlo en unos momentos.',
  // ExpertVerificationAlert
  resend_email_confirmation: 'Acabamos de reenviarte el correo de confirmación.',
  resend_email_confirmation_error: '¡Ups! Parece que hubo un error al intentar reenviar el correo de confirmación. Inténtalo en unos momentos.',
  expert_verification_alert_message:
    '¡Revisa tu correo! Te enviamos un mail de confirmación a <1>{{email}}</1>. Tienes {{ count }} {{ countText }} para hacerlo, de lo contrario no podrás usar Almendra hasta confirmar tu correo. ¡Recuerda ver las carpetas de spam y promociones en caso no lo encuentres!',
  expert_verification_alert_text: 'día restante',
  expert_verification_alert_text_other: 'días restantes',
  // ExpertPasswordChange
  // PatientMain
  redirecting_to_app_store: 'Redirigiendo al App Store',
  redirecting_to_google_play: 'Redirigiendo a Google Play',
  download_the_app_patient_main: 'Descarga la App',
  download_the_app_patient_main_subtitle: 'Podrás agendar consultas, ver tus planes alimenticios, tu progreso, recetas, y mucho más',
  // PatientAuthtoken
  okText_verifying: 'Verificando...',
  patient_auth_token_modal_title: 'Por favor espera unos segundos',
  patient_auth_token_modal_content: 'Estamos verificando el enlace ingresado',
  patient_auth_token_modal_success_title: '¡Listo!',
  patient_auth_token_modal_success_subtitle: 'Tu cuenta ha sido confirmada con éxito. En Almendra podrás:',
  patient_auth_token_modal_success_li_1: 'Visualizar tu progreso',
  patient_auth_token_modal_success_li_2: 'Consultar tu historial',
  patient_auth_token_modal_success_li_3: 'Ver tus planes alimenticios en tiempo real',
  patient_auth_token_modal_success_li_4: 'Agendar consultas con tu profesional',
  patient_auth_token_modal_success_li_5: 'Llegar a tu objetivo mucho más fácil',
  patient_auth_token_create_account_success: '¡Tu cuenta fue creada con éxito!',
  patient_auth_token_password_change_success: 'Tu contraseña se ha actualizado con éxito',
  patient_auth_token_expert_change_success: 'Tu cambio de profesional se ha realizado con éxito',
  patient_auth_token_password_onBoarding_title: 'En Almendra podrás:',
  patient_auth_token_content_title: '¡Hola {{ patientName }}!',
  patient_auth_token_content_subtitle: 'Comienza creando tu contraseña',
  patient_auth_token_type_password: 'Ingresa tu nueva contraseña',
  patient_auth_token_type_password_again: 'Vuelve a ingresar tu contraseña',
  patient_auth_token_validation_lowercase: 'Mínimo una letra en minúscula',
  patient_auth_token_validation_uppercase: 'Mínimo una letra en mayúscula',
  patient_auth_token_validation_characters: 'Mínimo 8 caracteres',
  patient_auth_token_validation_number: 'Mínimo un número',
  patient_auth_token_validation_passwordMatch: 'Las contraseñas coinciden',
  patient_auth_token_cta: 'Crear contraseña',
  // PaymentForm
  payment_form_show_error_message: 'Medio de pago incorrecto',
  payment_form_show_error_description:
    'No te preocupes, no se ha realizado ningún pago. Revisa que hayas ingresado correctamente los datos de tu medio de pago.',
  // PasswordReset
  password_reset_success_title: '¡Listo!',
  password_reset_success_content: 'Te hemos enviado a tu correo electrónico los pasos para poder restablecer tu contraseña.',
  password_reset_container_title: 'Restablece tu contraseña',
  password_reset_container_subheader: 'Ingresa el correo electrónico asociado a tu cuenta para poder restablecer tu contraseña',
  password_reset_email_label: 'Correo electrónico',
  password_reset_email_helperText: 'Ingresa un correo válido',
  password_reset_button_text: 'Enviar correo',
  password_reset_backToLogin: 'Regresar a Iniciar sesión',
  // RecipeCard
  recipe_card_servings_text: 'persona',
  recipe_card_servings_text_other: 'personas',
  // ActivityLoader
  activity_loader_first_line: 'Esto está tardando más de lo usual',
  activity_loader_second_line: 'Por favor, revisa que tengas una conexión a internet estable.',
  activity_loader_cta: 'Refrescar la página',
  // DesktopRequiredModal
  desktop_required_modal_title: 'Mejora tu experiencia',
  desktop_required_modal_content_1:
    'Para asegurar que tu experiencia en Almendra sea la mejor, <1>preferimos que hagas tus planes alimenticios a través de una computadora o una tablet.</1>',
  desktop_required_modal_content_2:
    'Podrás encontrar este plan alimenticio en la sección de planes alimenticios pendientes<1> (/plans) </1>una vez que entres desde tu computadora',
  desktop_required_modal_backToHome: 'Regresar a /inicio',
  // PatientsByAge
  patients_by_age_bar_label: 'Pacientes por edad',
  // PatientsBySource
  patients_by_source_bar_label: 'Pacientes por proviniencia',
  // PatientsByTags
  patients_by_tags_bar_label: 'Pacientes por etiqueta',
  // RevenueGraph
  revenue_graph_data_label: 'Ingresos',
  // TextAreaEditor
  text_area_editor_loading: 'Cargando...',
  text_area_editor_modal_title: 'Revisa el contenido del correo',
  text_area_editor_conflicts: 'Encontramos {{count}} conflicto con las variables del mail.',
  text_area_editor_conflicts_other: 'Encontramos {{count}} conflictos con las variables del mail.',
  text_area_editor_conflicts_content_1:
    "Revisa que hayas ingresado correctamente el nombre de la variable. Esto suele suceder cuando le das formato a una parte de la variable y no a todo el contenido entre los brackets <1>'{ }'</1>.",
  text_area_editor_conflicts_content_2: 'Corrige estos errores para que tus pacientes puedan visualizar correctamente el contenido del correo.',
  // AppointmentPDF
  appointment_pdf_title: 'Reporte de consulta',
  // ApointmentReport
  appointment_report_image_error: 'No se pudo cargar la imagen',
  appointment_report_filename: 'Reporte de consulta - {{firstName}} {{lastName}}.pdf',
  appointment_report_download_modal_title: 'Se necesita al menos una medida para descargar el PDF',
  appointment_report_download_modal_okText: 'Ok',
  appointment_report_download_modal_loading: 'Descargando...',
  appointment_report_download_modal_loading_title: 'Descargando reporte de consulta',
  appointment_report_download_modal_loading_content: 'Esto podría tardar unos segundos.',
  appointment_report_download_modal_error_title: 'Ocurrió un error al generar el reporte de consulta',
  appointment_report_download_modal_error_content: 'Por favor refresca la página e inténtalo nuevamente.',
  appointment_report_options_1: 'Opciones del PDF',
  appointment_report_options_2: 'Descargar PDF',
  appointment_report_options_2_title: 'Se necesita al menos una medida para descargar el PDF',
  appointment_report_options_3: 'Enviar por mail',
  appointment_report_button: 'Reporte de consulta (PDF)',
  appointment_report_settings_title: 'Personaliza el PDF a tu medida',
  appointment_report_settings_okText: 'Guardar',
  appointment_report_settings_cancelText: 'Cancelar',
  appointment_report_settings_comments: 'Comentarios de la consulta',
  appointment_report_settings_imcChart: 'Gráfico IMC',
  appointment_report_settings_watermark: 'Marca de agua',
  appointment_report_settings_checked: 'Sí',
  appointment_report_settings_unchecked: 'No',
  // IndicationsTemplateCard
  indications_template_card_modalDelete_title: '¿Quieres eliminar esta plantilla de indicaciones?',
  indications_template_card_modalDelete_content: 'Te recordamos que esta acción es irreversible.',
  indications_template_card_modalDelete_cancelText: 'No',
  indications_template_card_modalDelete_okText: 'Sí, eliminar',
  indications_template_card_modalDelete_success: 'Plantilla de indicaciones borrada con éxito',
  indications_template_card_delete: 'Eliminar',
  indications_template_card_indications: 'Indicaciones:',
  // MealPlanChart
  meal_plan_chart_target: 'Objetivo: ',
  meal_plan_chart_actual: 'Actual: ',
  meal_plan_chart_difference: 'Diferencia',
  meal_plan_chart_surplus: 'Superávit',
  meal_plan_chart_deficit: 'Déficit',
  // IndicationsTemplatesMain
  indications_templates_main_title: 'Plantillas de indicaciones',
  indications_templates_main_system: 'Indicaciones del sistema',
  // DeletePastAppointment
  delete_past_appointment_modal_title: '¿Deseas eliminar esta consulta?',
  delete_past_appointment_modal_content: 'Esta consulta fue agendada pero no ha iniciado. ¿Deseas eliminarla de todos modos?',
  delete_past_appointment_okText: 'Sí, eliminar consulta',
  // RecipesHome
  recipes_home_category: 'Categoría: ',
  recipes_home_preparation: 'Preparación: ',
  recipes_home_cookingTime: 'Cocción: ',
  recipes_home_difficulty: 'Dificultad: ',
  recipes_home_noRecipes: 'No se encontraron recetas',
  recipes_home_public_card_desc: 'Próximamente podrás ver las recetas de {{ firstName }}',
  recipes_home_showing_recipes: 'Mostrando {{count}} receta',
  recipes_home_showing_recipes_other: 'Mostrando {{count}} recetas',
  recipes_home_school: 'Colegiatura: ',
  about_me: 'Sobre mí',
  recipes_home_Fácil: 'Fácil',
  recipes_home_Medio: 'Medio',
  recipes_home_Difícil: 'Difícil',
  // MetsSelectInput
  mets_select_input_error: 'Por favor ingresa un valor o elimina la actividad',
  every_day: 'Todos los días',
  mets_select_input_select_days: 'Selecciona días',
  // VerifyAccount
  check_your_email: 'Revisa tu correo',
  verify_account_content_1: 'Sigue las indicaciones enviadas a<1>{{mail}}</1> para poder comenzar a usar Almendra.',
  verify_account_close: 'Ya puedes cerrar esta página.',
  verify_account_did_not_receive: '¿No recibiste el correo? Recuerda revisar la carpeta de Spam y Promociones',
  // ExpertWorkAddress
  delete_address_from_business_hour:
    'Se eliminará esta dirección de la configuración de tu horario de atención (en <1>Configuración > Ajustes Generales > Horario de atención</1>)',
  expert_work_address_warning: 'Por favor procura que la dirección tenga al menos 10 caracteres.',
  expert_work_address_error: 'Ocurrió un error mientras editabas la dirección de consultorio. Inténtalo nuevamente.',
  expert_work_address_newAddress_warning: 'Por favor completa la dirección antes de crear una nueva.',
  expert_work_address_deleteModal_title: 'Estas a punto de eliminar una dirección de trabajo que fue configurada en tu horario de atención',
  expert_work_address_deleteModal_okText: 'Sí, eliminar',
  expert_work_address_deleteModal_cancelText: 'Cancelar',
  expert_work_address_deleteModal_confirm: '¿Deseas eliminar esta dirección de trabajo?',
  expert_work_address_main_address: 'Dirección principal:',
  expert_work_address_placeholder: 'Ej: Jirón Las Almendras 123',
  expert_work_address_alt_address: 'Dirección alternativa',
  expert_work_address_add_address: 'Añadir nueva dirección',
  // PopupAddMeal
  popup_add_meal_confirmError: 'Selecciona la comida que desees agregar',
  popup_add_meal_addButton: 'Agregar Comida',
  popup_add_meal_addButton2: 'Comida',
  popup_add_meal_modal_title: '¿Qué comida deseas agregar?',
  popup_add_meal_addToDay: 'Se agregará la comida al {{currentDayName}}',
  popup_add_meal_addToEveryDay: 'La comida se agregará en todos los días',
  popup_add_meal_time: '¿A qué hora será?',
  popup_add_meal_warning_1: 'Los días que tengan objetivos calóricos por tiempo de comida pasarán a tener solamente objetivos por día.',
  popup_add_meal_warning_2: 'Puedes regresar al paso anterior para colocar nuevos objetivos por tiempo de comida en esos días.',
  // PopupEditBanner
  edit_banner_load_error: 'Hubo un error al subir la imagen',
  edit_banner_button: 'Cambiar imagen',
  edit_banner_modal_title: 'Configura tu página personal',
  edit_banner_okText: 'Realizar cambios',
  edit_banner_cta: 'Cambiar imagen de fondo',
  edit_banner_modal_subtitle: 'Banner actual',
  // PopupSubscriptionEnded
  popup_subscription_ended_modal_title_1: 'Tu periodo de prueba ha terminado',
  popup_subscription_ended_modal_title_2: 'El periodo de tu suscripción ha terminado',
  popup_subscription_ended_modal_content: 'Tu suscripción al {{planName}} ha concluido.',
  popup_subscription_ended_modal_li_1: 'El cargo de suscripción tuvo un error el día',
  popup_subscription_ended_modal_li_2: 'Se venció el periodo de tu suscripción',
  popup_subscription_ended_modal_li_3: 'Cancelaste la renovación automática de tu suscripción',
  popup_subscription_ended_preCTA: 'Renueva tu suscripción para seguir utilizando Almendra',
  popup_subscription_ended_cta: 'Elegir mi plan',
  // ExchangeTableMealPlan
  exchange_table_mealplan_warning: 'Ingresa el nombre del plan alimenticio',
  exchange_place_food: 'Colocar alimento',
  exchange_place_group: 'Colocar grupo',
  exchange_view_food: 'Ver alimentos',
  exchange_view_group: 'Ver grupos',
  // DownloadPatientDataXLSX
  extraComment: 'Comentario general',
  arrivalSource: 'Procedencia',
  intestinalConditionComments: 'Comentarios de condición intestinal',
  mail: 'Correo',
  download_patient_data_xlsx_filename: 'Datos de {{firstName}} {{lastName}}.xlsx',
  field: 'Campo',
  location_only: 'Lugar',
  download_patient_data_xlsx_noAppointments: 'No se encontraron consultas terminadas para este paciente',
  successful_download: 'Descarga exitosa',
  download_patient_data_xlsx_cta: 'Descargar datos en Excel',
  // PatientFoodFrequency
  patient_food_frequency_modal_title: 'Agrega el grupo de alimentos que desees',
  patient_food_frequency_placeholder: 'Ej: Suplementos de proteína',
  add: 'Agregar',
  patient_food_frequency_error_1: 'Por favor, ingresa el nombre del grupo',
  patient_food_frequency_error_2: 'Ya existe un grupo con este nombre',
  patient_food_frequency_modal_title_2: 'Formulario de frecuencia de consumo',
  patient_food_frequency_button: 'Grupos de alimentos',
  action: 'Acción',
  frequency: 'Frecuencia',
  patient_food_frequency_popconfirm: '¿Deseas eliminar esta categoría?',
  times_per_day: 'vez al día',
  times_per_day_other: 'veces al día',
  times_per_week: 'vez a la semana',
  times_per_week_other: 'veces a la semana',
  times_per_month: 'vez al mes',
  times_per_month_other: 'veces al mes',
  patient_food_frequency_emptyText: 'Empieza agregando grupos de alimentos dándole click al botón (+) de arriba',
  patient_food_frequency_main_button: 'Formulario de frecuencia de consumo',
  // PatientInformationProperties
  maritalStatus: 'Estado civil',
  lifeStyle: 'Estilo de vida',
  healthConditions: 'Condiciones de salud',
  actualDietType: 'Tipo de alimentación',
  actualMealsPerDay: 'Comidas por día',
  actualWaterConsumption: 'Agua al día (litros)',
  previousMealPlan: 'Plan alimenticio anterior',
  previousMealPlanCreator: 'Plan alimenticio anterior hecho por',
  pastDietType: 'Tipo del plan alimenticio anterior',
  physicalActivity: 'Actividad Física',
  dailyPhysicalActivity: 'Nivel de actividad física',
  physicalActivityDiscipline: 'Disciplinas - Actividad física',
  sleepTime: 'Hora de dormir',
  wakeUpTime: 'Hora de despertarse',
  sleepDuration: 'Horas de sueño',
  sleepDuration_question: '¿Cuantas hora duerme?',
  sleepDuration_placeholder: 'Ej: 7',
  sleepQuality: 'Calidad del sueño',
  alcoholConsumptionFrequency: 'Alcohol - Frecuencia de consumo',
  alcoholTypes: 'Alcohol',
  smokingFrequency: 'Tabaco - Frecuencia de consumo',
  coffeeConsumptionFrequency: 'Café - Frecuencia de consumo',
  supplements: 'Suplementos',
  stressLevel: 'Nivel de estrés',
  stressCause: 'Motivo del estrés',
  actualMotivation: 'Motivación',
  generalObjective: 'Objetivo general',
  intestinalCondition: 'Condición Intestinal',
  intestinalConditionPlaceholder: 'Selecciona la condición Intestinal',
  actualMedicineConsumption: 'Medicinas consumidas',
  drugAllergies: 'Alergias a medicamentos',
  currentDiseases: 'Condiciones patológicas actuales',
  personalDiseaseBackground: 'Antecedentes patológicos personales',
  personalDiseaseBackgroundComments: 'Antecedentes patológicos personales (comentarios)',
  familyDiseaseBackground: 'Antecedentes patológicos familiares',
  familyDiseaseBackgroundComments: 'Antecedentes patológicos familiares (comentarios)',
  specialConditions: 'Condiciones especiales',
  dislikedFoods: 'Alimentos que disgustan',
  allergies: 'Alergias',
  dietRecord: 'Registro Dietético',
  // General Objective
  general_objective_placeholder: 'Ej: Correr una maratón, tener una mejor relación con la comida, etc.',
  general_objective_button: 'Añadir otro objetivo',
  // PopupNewMealPlanTemplate
  popup_new_meal_plan_template_buttonQuick: 'Crear plantilla',
  popup_new_meal_plan_template_button: 'Crear plantilla alimenticia',
  popup_new_meal_plan_template_modal_title: 'Crea una plantilla de plan alimenticio',
  popup_new_meal_plan_template_modal_divider: 'Nombre de la plantilla',
  popup_new_meal_plan_template_modal_message: 'Ingresa el nombre de la plantilla',
  // HealthPlanCard
  public_view: 'Pública',
  private_view: 'Privada',
  created_on: 'creado el {{-date, DD/MM/YYYY}}',
  health_plan_card_default_single:
    'Esta es una consulta "regular". Para editarla haz click en los tres puntos en la esquina superior derecha. Cuando agendes consultas, tendrás la opción de asignarle al paciente un paquete nutricional (ej: Paquete Tonificación Verano, que son 8 consultas) o una consulta regular (ej: María vino por primera vez, y regresará en dos semanas para su consulta de control. Tanto la primera consulta como la consulta de control son consultas regulares, porque no forman parte de un paquete nutricional). ¿Tienes dudas? Escríbenos a soporte@almendra.io y las resoleveremos cuanto antes.',
  health_plan_card_modal_title: '¿Quieres eliminar este paquete?',
  health_plan_card_modal_content: 'Esta acción es irreversible. Si haces click en eliminar, se borrará permanentemente este paquete.',
  ready: '¡Listo!',
  health_plan_card_message_description: 'Paquete eliminado',
  health_plan_card_delete_action: 'Eliminar paquete',
  health_plan_card_div_1: 'Consultas incluidas: ',
  health_plan_card_div_2: 'Precio total: ',
  health_plan_card_div_3: 'Visibilidad: ',
  health_plan_card_desc: 'Descripción del paquete',
  health_plan_card_current_patients: 'Pacientes suscritos actualmente',
  health_plan_card_times_purchased: 'Cantidad de veces comprado',
  // PopupNewExpenses
  popup_new_expenses_msg_success: 'Expense was registered successfully',
  popup_new_expenses_msg_error: 'Hubo un problema registrando este gasto',
  popup_new_expenses_button: 'Agregar gasto',
  popup_new_expenses_modal_title: 'Agregar gasto extra',
  popup_new_expenses_label_1: 'Nombre del gasto',
  popup_new_expenses_message_1: 'Ingresa el nombre del gasto',
  popup_new_expenses_placeholder_1: 'Ej: Alquiler',
  popup_new_expenses_label_2: 'Monto del gasto',
  popup_new_expenses_message_2: 'Ingresa el monto del gasto',
  popup_new_expenses_placeholder_2: 'Ej: 100',
  popup_new_expenses_label_3: 'Categoría del gasto',
  popup_new_expenses_message_3: 'Selecciona la categoría del gasto',
  popup_new_expenses_placeholder_3: 'Ej: Accesorios de trabajo',
  popup_new_expenses_initial_date_recurrent: 'Fecha de inicio del gasto',
  popup_new_expenses_initial_date: 'Fecha del gasto',
  popup_new_expenses_initial_date_placeholder: 'Selecciona la fecha del gasto',
  popup_new_expenses_label_4: '¿Es un gasto recurrente?',
  popup_new_expenses_message_4: 'Selecciona la recurrencia del gasto',
  popup_new_expenses_label_5: 'Recurrencia',
  popup_new_expenses_message_5: 'Ingresa un número',
  popup_new_expenses_message_6: 'Selecciona un periodo',
  popup_new_expenses_label_7: '¿El gasto tiene fecha de fin?',
  popup_new_expenses_message_7: 'Selecciona la fecha fin del gasto',
  // PopupEquivalentsGroupSettings
  popup_equivalents_group_settings_tooltip: 'Opciones del grupo',
  popup_equivalents_group_modal_title: 'Opciones para planes alimenticios de intercambios',
  popup_equivalents_group_label_1: 'Mostrar como predeterminado',
  popup_equivalents_group_extra_1:
    'Al momento de crear planes alimenticios de intercambios, se asignará este grupo como grupo predeterminado para facilitarte en el llenado del plan',
  popup_equivalents_group_extra_2: 'Selecciona a qué categoría más se asemeja este grupo de equivalencias.',
  popup_equivalents_group_extra_3:
    'Puedes asignarle un color a este grupo para que tus pacientes puedan lo ubicar fácilmente en su plan alimenticio. Si no deseas elegir un color, haz <1>click aquí</1> y se le asignará un color automáticamente.',
  selected_color: 'Color seleccionado:',
  no_color_assigned: 'No has asignado un color',
  // AddExchangeRecipe
  add_exchange_recipe_button: 'Colocar recetas',
  add_exchange_recipe_showing: 'Mostrando {{count}} recetas',
  add_exchange_recipe_load_more: 'Cargar más recetas',
  add_exchange_recipe_no_recipes: 'No hay recetas por mostrar',
  add_exchange_recipe_info: 'Haz click en una receta para ver su información',
  add_exchange_recipe_no_load: 'No se pudo cargar la información de esta receta',
  no_content: 'Sin contenido',
  // PopupUploadMealPlanPDF
  popup_upload_meal_plan_pdf_emptyMsg: 'Adjunta un PDF para poder enviarlo',
  popup_upload_meal_plan_pdf_upload: 'Archivo en PDF adjuntado',
  popup_upload_meal_plan_pdf_upload_error: 'Hubo un error al subir el pdf',
  popup_upload_meal_plan_pdf_upload_warning: "There's no PDF file attached",
  popup_upload_meal_plan_pdf_button: 'Adjunta tu plan alimenticio en PDF',
  popup_upload_meal_plan_pdf_tooltip: 'En caso ya tengas un PDF hecho por ti y no quieras crear el plan en Almendra puedes adjuntarlo',
  popup_upload_meal_plan_pdf_modal_title: 'Plan alimenticio adjunto en PDF',
  meal_plan_name: 'Nombre del plan alimenticio',
  popup_upload_meal_plan_pdf_button_2: 'Adjunta tu plan alimenticio',
  // PopupAppInvitation
  cool: '¡Genial!',
  popup_app_invitation_success_title: 'Este paciente ya cuenta con acceso al App',
  popup_app_invitation_error_1: 'Ya tienes registrado a un paciente con el mail que ingresaste.',
  popup_app_invitation_error_2: 'No se pudo guardar el email. Por favor, inténtalo nuevamente',
  save_email: 'Guardar email',
  popup_app_invitation_modal_title: '¿Deseas enviarle el correo de acceso al App?',
  popup_app_invitation_modal_content_1:
    'El correo de acceso sirve para que el paciente active su cuenta en Almendra App, donde podrá visualizar en tiempo real su historial de paciente, su progreso y los planes alimenticios que le asignes.',
  popup_app_invitation_modal_content_2: 'Se enviará un correo a <1>{{mail}}</1> para que <3>{{patientName}}</3> acceda a su cuenta en Almendra.',
  save_and_send_confirmation_email: 'Guardar email y enviar confirmación',
  send_access_email: 'Enviar correo de acceso al App',
  popup_app_invitation_div_1: 'Opción recomendada',
  popup_app_invitation_div_2: 'En caso solo quieras guardar el email, puedes hacer click en el botón "Guardar email".',
  // FinancialsSummary
  show: 'Mostrar',
  expenses_cap: 'Gastos',
  income_cap: 'Ingresos',
  in_this_month: 'en este mes',
  in_this_year: 'en este año',
  in: 'en',
  last_month: ' el mes pasado', //ojo al espacio, debe estar
  last_year: ' el año pasado', //ojo al espacio, debe estar
  financials_summary_h3: '¡Todavía no tienes ingresos! Aparecerán apenas realices una consulta o registres un ingreso.',
  since_you_joined_Almendra: 'desde que te uniste a Almendra',
  financials_summary_tooltip: 'La ganancia neta es la suma de los ingresos menos los gastos del periodo seleccionado',
  net_income: 'Ganancia neta',
  net_loss: 'Pérdida neta',
  financials_summary_tooltip_2: 'La perdida es la suma de los ingresos menos los gastos del periodo seleccionado',
  financials_summary_statistic_title: '',
  last_week: 'la semana pasada',
  financials_summary_tooltip_3: 'El crecimiento significa en qué porcentaje mejoraste tu ganancia neta respecto a la del periodo pasado',
  financials_summary_tooltip_4: 'El decrecimiento significa en qué porcentaje empeoraste tu ganancia neta respecto a la del periodo pasado',
  financials_summary_statistic_title_2: '',
  // RecipesMain
  pending_recipe_load_failed: 'Hubo un error al cargar tus recetas pendientes por terminar',
  recipe_load_failed: 'Hubo un error al cargar tus recetas',
  recipes_main_pending_div: 'Tienes {{count}} {{countText}} de completar',
  recipes_main_pending_div_countText: 'receta pendiente',
  recipes_main_pending_div_countText_other: 'recetas pendientes',
  reset_filters: 'Limpiar filtros',
  searching_recipes: 'Buscando recetas...',
  no_recipes_found: 'No se encontraron recetas',
  recipes_main_loading_more: 'Cargando más recetas...',
  recipes_main_showing: 'Mostrando {{count}} receta',
  recipes_main_showing_other: 'Mostrando {{count}} recetas',
  // ExpertPasswordChange
  wrong_password: 'Contraseña incorrecta',
  expert_password_change_success_message: 'Tu contraseña ha sido modificada con éxito',
  expert_password_change_success_desc: 'Te recordamos nunca compartir tus credenciales con nadie :)',
  expert_password_change_error_message: 'Tu contraseña no pudo ser modificada',
  expert_password_change_error_desc: 'Por favor inténtalo nuevamente en unos instantes',
  expert_password_change_compare: 'Las contraseñas ingresadas no coinciden',
  current_password: 'Contraseña actual',
  enter_current_password: 'Ingresa tu contraseña actual',
  change_password: 'Cambiar contraseña',
  // RecipesFilters
  make_search_query: 'Buscar',
  'Mis recetas': 'Mis recetas',
  'Todas las recetas': 'Todas las recetas',
  search_filters: 'Filtros de la búsqueda',
  recipes_filters_title: 'Buscar recetas por criterios',
  recipes_filter_placeholder_1: 'Ej: Ensalada de quinua',
  minimum: 'Mínimo',
  maximum: 'Máximo',
  my_recipes: 'Mis recetas',
  all_recipes: 'Todas las recetas',
  // PopupCopyDay
  popup_copy_day_toggle_day: 'Si quieres seleccionar el día {{dayName}}, primero cámbialo a un día no libre',
  popup_copy_day_modal_title_1: 'Copiar día',
  popup_copy_day_modal_title_2: 'con equivalencias',
  popup_copy_day_text:
    'Selecciona los días a los cuales deseas copiar los alimentos del día {{originCopyDayName}} y haz click en Confirmar. Si algún día al que quieras copiar ya contiene alimentos, estos serán reemplazados. Esta acción es irreversible.',
  popup_copy_day_alert:
    'Se colocarán alimentos equivalentes en los días en los que desees copiar. Si un alimento no tiene equivalentes, se colocará el mismo alimento.',
  // PopupCopyExchangeDay
  popup_copy_exchange_day_warning: 'Selecciona al menos un día para reemplazar el contenido',
  popup_copy_exchange_day_content_1: 'Selecciona los días a los cuales deseas copiar el contenido del <1>{{currentDayName}}</1>.',
  popup_copy_exchange_day_content_2: 'Se reemplazará la siguiente información:',
  popup_copy_exchange_day_content_3: 'Grupos de intercambios de las comidas',
  popup_copy_exchange_day_content_4: 'Opciones de las comidas',
  popup_copy_exchange_day_content_5: 'Horas de las comidas',
  popup_copy_exchange_day_content_6: 'Comentarios de las comidas',
  // OnBoardingGoToTour
  creating_meal_plan: 'Creando un plan alimenticio',
  start_tour: 'Iniciar tour',
  tour_completed: 'Tour completado',
  continue_tour: 'Continuar con el tour',
  // DisciplineCardsMain
  disciplinaCards_examples: 'Ej: Yoga, Baloncesto, Natación etc.',
  already_exists: 'Ya existe una disciplina o actividad con ese nombre como opción',
  new_discipline_error: 'Agrega una disciplina o actividad válida',
  delete_discipline: '¿Deseas eliminar esta disciplina?',
  as_a_discipline: 'como disciplina',
  sport: 'Deporte',
  sport_other: 'Deportes',
  // PatientProfile
  personal_data: 'Datos Personales',
  actual: 'actual',
  custom_fields_profile: 'Campos propios',
  custom_fields_setup: 'Configurar campos',
  message_error_save: 'Ocurrió un problema al guardar este cambio',
  message_error_save_schema: 'No se pudo guardar este cambio: el formato del campo es inválido.',
  patient_profile_no_delete_title: 'Todavía no puedes eliminar este paciente',
  patient_profile_no_delete_content: 'Para eliminar al paciente de prueba tienes que haber terminado todo el proceso de introducción.',
  patient_profile_no_delete_title_2: 'Este paciente se encuentra en plena consulta',
  patient_profile_no_delete_content_2: 'Para eliminar a este paciente asegúrate que no tenga una consulta en curso.',
  patient_profile_delete_title: '¿Deseas eliminar a este paciente?',
  patient_profile_delete_div_1:
    'Esta acción es irreversible. Si haces click en eliminar, se borrará permanentemente este paciente, así como toda su información relacionada (consultas, planes alimenticios, medidas, etc.).',
  patient_profile_delete_div_2:
    'Recomendamos no eliminar el paciente ya que tambien se eliminarán todos los ingresos que este te generó dentro de la sección finanzas personales y por ende, la información podría verse distorsionada.',
  patient_profile_delete_desc_1: 'Eliminaste a',
  patient_profile_delete_desc_2: '¡Esperamos que vuelva pronto!',
  error_deleting_patient: 'Hubo un problema eliminando a este paciente. Por favor, refresca la página y vuelve a intentarlo.',
  firstName_example: 'Ej: Luciana',
  lastName_example: 'Ej: Solari',
  idNumber_example: 'Ej: 71234567',
  occupation_example: 'Ej: Profesor',
  phone_example: 'Ej: 999123123',
  stats: 'Estadísticas',
  time_as_patient: 'Tiempo como paciente',
  assigned_mealPlans: 'Dietas asignadas',
  income_generated: 'Ingresos generados\npor este paciente',
  appointments_made: 'Consulta realizada',
  appointments_made_other: 'Consultas realizadas',
  assistance_ratio: 'Tasa de asistencia\na consultas',
  average_appointment_time: 'Tiempo promedio\nde consulta',
  goal: 'Objetivo',
  goal_other: 'Objetivos',
  patient_wants_to: '{{name}} desea',
  current_goal: 'Objetivo actual',
  stress: 'Estrés',
  reason: 'Motivo',
  reason_other: 'Motivos',
  hour_placeholder_am: 'Ej: 6:30am',
  dream: 'Sueño',
  hour_placeholder_pm: 'Ej: 10:00pm',
  other_consumptions: 'Otros consumos',
  alcohol: 'Alcohol',
  alcohol_types: 'Tipos de alcohol',
  alcohol_placeholder: 'Ej: Cerveza',
  consumption_frequency: 'Frecuencia de consumo',
  tobacco: 'Tabaco',
  coffee: 'Café',
  supplement_types: 'Tipos de suplementos',
  supplement_placeholder: 'Ej: Creatina',
  food_registry: 'Registro de alimentos',
  nutrition_patient_info: 'Datos de la alimentación',
  section_not_viewed_by_patient: 'Esta sección no será visualizada por tu paciente.',
  actualMealsPerDay_placeholder: 'Ej: 3',
  has_done_previous_mealplan: '¿Ha realizado una dieta previamente?',
  previous_mealplan_type: 'Tipo de dieta realizada',
  previous_mealplan_type_placeholder: 'Ej: Keto, sin carbohidratos, paleo...',
  previous_mealplan_author: '¿Quién la prescribió?',
  previous_mealplan_author_placeholder: 'Ej: Un nutricionista, la vió en Instagram, la que le funcionó a un amigo',
  consumption_frequency_form_extra: 'Se pueden modificar los grupos de alimentos en Ajustes Generales',
  currentDiseases_can_add: 'Puedes agregar patologías que no estén incluidas en la lista',
  currentDiseases_placeholder: 'Ej: Hipotiroidismo',
  actualMedicineConsumption_placeholder: 'Ej: Paracetamol',
  drugAllergies_placeholder: 'Ej: AINEs',
  personalDiseaseBackground_placeholder: 'Ej: Gastritis',
  intestinalCondition_select: 'Selecciona la condición intestinal',
  patient_food_liked_enter: 'Ingresa los alimentos favoritos',
  patient_food_unliked_enter: 'Ingresa los alimentos no deseados',
  patient_food_allergies_enter: 'Ingresa los alimentos a los que sea alérgico',
  comments_and_others: 'Comentarios y otros',
  patient_address: 'Dirección del paciente',
  patient_source: '¿Cómo llegó a ti el paciente?',
  patient_images: 'Imágenes del paciente',
  modifying_measurements: 'Modificando tus medidas',
  // PopupBookingSettings
  popup_booking_settings_modal_title: 'Estás a punto de desactivar una modalidad de consulta que fue configurada en tu horario de atención',
  popup_booking_settings_modal_content:
    "Se eliminará esta modalidad de consulta de tu horario de atención (en <1>{'Configuración > Ajustes Generales > Horario de atención'}</1>).",
  popup_booking_settings_success_desc: 'Activaste el sistema de reservas online',
  popup_booking_settings_error: 'Hubo un error al activar el sistema de reservas online',
  popup_booking_settings_warning: 'No has terminado de configurar tu sistema de reserva',
  popup_booking_settings_content_warning_1: 'Haber configurado tu horario de atención<1/><2>(paso 2)</2>',
  popup_booking_settings_content_warning_2: 'Tener al menos 1 servicio activado<1/><2>(paso 1)</2>',
  online_booking_system: 'Sistema de reservas online',
  activate_online_booking_system: 'Activar sistema de reservas',
  customize_your_booking_system: 'Personaliza tu sistema de reservas online',
  allow_anyone_to_book: 'Permitir que cualquier persona realice una reserva de cita',
  anyone_can_book: 'Tanto tus pacientes actuales como cualquier persona que entre a tu página personal podrán reservar citas contigo.',
  only_patients_can_book: 'Solamente tus pacientes registrados podrán reservar citas contigo.',
  services_to_show: '¿Qué servicios deseas mostrar?',
  popup_booking_settings_where_services: 'Puedes crear o editar tus servicios en la sección ',
  popup_booking_settings_activate_one: 'Activa al menos 1 servicio para usar el sistema de reservas online',
  configure_business_hours: 'Configura tu horario de atención',
  popup_booking_settings_create_one: 'Crea por lo menos 1 bloque de horario para activar el sistema de reservas online',
  // RecoverMealPlanVersionModal
  mealPlan_old_version_title: 'Encontramos una versión de este plan alimenticio que no ha sido guardada',
  mealPlan_old_version_content_0:
    'Se encontró una versión no guardada cuya última fecha de modificación fue el <1>{{- unsavedDate, DD/MM/YYYY, h:mm:ss a}}</1>. Mientras que la última versión guardada del plan alimenticio fue el <3>{{- lastUpdatedAt, DD/MM/YYYY, h:mm:ss a}}</3>.',
  mealPlan_old_version_content_1: '¿Con cuál versión deseas quedarte?',
  last_saved_version: 'Última versión guardada',
  unsaved_version: 'Versión no guardada',
  use_this_version: 'Utilizar esta versión',
  most_used_option: 'Opción más utilizada',
  without_meals: 'Sin comidas',
  without_foods: 'Sin alimentos',
  without_groups: 'Sin grupos',
  without_menu: 'Sin menú',
  without_options: 'Sin opciones',
  // NutrientAnalysis
  analysis_per_nutrient: 'Análisis por nutriente',
  select_nutrient: 'Seleccionar nutriente',
  total_of_day: 'Total del día',
  of_the_day: 'del día',
  options_average: 'Promedio opciones',
  options_average_tooltip: 'Se calcula como la suma de todas las opciones entre el número de opciones',
  options_total_tooltip: 'Se calcula como la suma de todos los promedios de opciones',
  total_of_meal: 'Total de la comida',
  options_total_meal_exchange_tooltip: 'Se calcula como la suma del promedio de todos los grupos',
  // MealFoodInformation
  view_micros: 'Micros',
  view_micronutrients: 'Ver micronutrientes',
  meal_food_information_modal_title: 'Análisis de micronutrientes',
  meal_food_information_modal_source_warning: 'Al usar alimentos que no pertenezcan a la USDA, los resultados podrían tener un grado de distorción.',
  recommendations_institute_of_medicine: 'Recomendaciones según el Institute of Medicine de EEUU.',
  meal_food_information_view_recommendations: 'Para poder ver las recomendaciones, agrégale una fecha de nacimiento al paciente',
  // OnBoardingMainSection
  the_first_step: 'El primer paso',
  discount_obtained: '¡Descuento conseguido!',
  complete_every_item: 'Completa todos los puntos de esta sección para obtener el descuento',
  discount_percent: '10% de descuento',
  onboarding_main_section_tooltip:
    'Todos los usuarios que hicieron el entrenamiento despejaron sus dudas y pudieron usar el software con mayor facilidad',
  go_to_article: 'Ir al artículo',
  // PreciseObjectiveProgress
  precise_objective_progress_modal_title: 'Progreso del objetivo preciso',
  // EditSocialNetworks
  set_social_networks: 'Configurar redes sociales',
  edit_social_networks_modal_title: 'Configura tus redes sociales',
  show_whatsapp: 'Habilitar Whatsapp',
  enabled: 'Activado',
  disabled: 'Desactivado',
  contact_message_whatsapp: 'Mensaje de contacto',
  edit_social_networks_placeholder: 'Ej: ¡Hola {{firstName}}! Tengo interés en tus servicios. ¿Podrías agendarme una cita?',
  facebook_valid_link: 'Ingresa un link de Facebook válido',
  twitter_valid_link: 'Ingresa un link de Twitter válido',
  instagram_valid_link: 'Ingresa un link de Instagram válido',
  linkedin_valid_link: 'Ingresa un link de LinkedIn válido',
  youtube_valid_link: 'Ingresa un link de Youtube válido',
  // PopupAllocatePurchasedHealthPlan
  healthplan_successfully_assigned: 'Paquete asignado con éxito',
  assign_healthplan_appointment_in_progress: 'Para asignar el paquete a un paciente primero termina su consulta en proceso.',
  healthplan_selection_message: 'El precio incluye {{count}} consulta',
  healthplan_selection_message_other: 'El precio incluye {{count}} consultas',
  assign_healthplan_modal_title: 'Asignar nuevo paquete',
  assign_healthplan: 'Asignar paquete',
  no_healthplans_yet: 'Todavía no has creado tus paquetes nutricionales.',
  create_your_first_healthplan: 'Crea tu paquete nutricional en la sección <1>"Configuración > Paquetes Nutricionales"</1>',
  go_to_healthplans: 'Ir a paquetes',
  booking_no_charge: 'No se solicitará pagar por el paquete durante el proceso de reserva online',
  booking_charge: 'Se solicitará pagar por el paquete durante el proceso de reserva online',
  request_payment: 'Solicitar pago',
  not_request_payment: 'No solicitar pago',
  // MercadoPagoLinkAccount
  mercado_pago_link_account_modal_title: '¿Quieres vincular tu cuenta de MercadoPago con Almendra?',
  mercado_pago_link_account_modal_content_1:
    'Tus pacientes podrán realizar los pagos por tus consultas al momento de realizar la reserva de citas a través de "/reserva".',
  mercado_pago_link_account_modal_content_2:
    'Antes de vincular tu cuenta con MercadoPago y empezar a recibir pagos, verifica que tu cuenta de MercadoPago tenga la misma moneda que tu cuenta de Almendra:',
  mercado_pago_link_account_modal_content_3: 'Te redireccionaremos a la página de MercadoPago para que puedas realizar esta acción.',
  mercado_pago_link_account_modal_content_4:
    '<0>Nota: </0> MercadoPago cobra una tarifa por utilizar sus servicios. Diríjete <2>aquí</2> for more information. para más información. Almendra no cobra ninguna tarifa adicional.',
  mercado_pago_link_account_error: 'Hubo un error al contactar el servidor de MercadoPago. Inténtalo nuevamente.',
  mercado_pago_link_account_modal_unlink_title: '¿Quieres desvincular tu cuenta de MercadoPago con Almendra?',
  mercado_pago_link_account_modal_unlink_content:
    'Te recordamos que toda opción de pagos en tu cuenta se deshabilitará. Tus pacientes no podrán realizar los pagos después de esta acción.',
  // PatientsPicturesWall
  fill_every_field: 'Por favor, llena todos los campos',
  image_delete_title: '¿Quieres eliminar esta imagen?',
  edit_image: 'Editar imagen',
  new_image: 'Nueva imagen',
  change_image: 'Cambiar imagen',
  date_format_string: 'DD/MM/YYYY', //usado para placeholders y props
  // PopupEditHealthPlan
  new_healthplan: 'Nuevo paquete',
  edit_healthplan: 'Editar paquete',
  create_healthplan: 'Crear paquete',
  edit_single_appointment: 'Editar consulta regular',
  popup_edit_healthplan_name_message: 'Escribe el nombre del paquete',
  popup_edit_healthplan_price_message: 'Escribe el precio del paquete',
  includes: 'Incluye',
  php_included_appointment: '{{count}} consulta',
  php_included_appointment_other: '{{count}} consultas',
  php_included_appointments_duration: 'Duración de las consultas incluidas en el paquete',
  php_includedAppointments_v: 'Ingresa la duración de la Consulta #{{ count }}',
  popup_edit_healthplan_totalAppointments_message: 'Elige cuántas consultas tendrá',
  popup_edit_healthplan_description_message: 'Ingresa la descripción del paquete. Tus pacientes podrán leerla',
  php_add_appointment: 'Añadir consulta',
  popup_edit_healthplan_privacy_extra_public: 'Este paquete estará visible para todos en tu página personal',
  popup_edit_healthplan_privacy_extra_private: 'No se mostrará este paquete en tu página personal',
  privacy: 'Privacidad',
  popup_edit_healthplan_info: 'Todos los pacientes que tienen este plan en la <1/>actualidad no se verán afectados por los cambios.',
  // PreciseObjectiveModal
  add_precise_objective: 'Agregar objetivo preciso',
  add_objective: 'Agregar objetivo',
  edit_objective: 'Editar objetivo',
  precise_objecitve_modal_measurement_message: 'Ingresa la medida',
  precise_objecitve_modal_target_message: 'Ingresa el valor deseado de la medida',
  precise_objecitve_modal_target_placeholder: 'Ej: 58',
  enter_start_date: 'Ingresa la fecha de inicio',
  precise_objecitve_modal_date_conflict: 'La fecha de inicio tiene que ser menor a la fecha deseada',
  day_with_appointment: 'Día con consulta',
  target_date: 'Fecha deseada',
  // CustomMeasurements
  no_custom_for_recipes: 'No se pueden crear medidas propias para las recetas',
  custom_measurement_create_error_1: 'Por favor, ingresa el nombre de la unidad que deseas crear',
  custom_measurement_create_error_2: 'Por favor, ingresa otro nombre para la unidad',
  custom_measurement_create_error_3: 'El peso en gramos tiene que ser mayor a cero',
  custom_measurement_create_error_4: 'Ya existe una medida con el nombre',
  measurement_created_successfully: 'Medida creada con éxito',
  custom_measurement_modal_title: '¿Deseas crear la unidad "{{unit}}" ({{gramWeight}} g.) para este alimento?',
  custom_measurement_modal_content: 'Podrás editar o eliminar esta medida casera en la sección de Alimentos (/alimentos) en cualquier momento.',
  yes_create_measurement: 'Sí, crear medida',
  create_new_measurement: 'Crear nueva medida',
  custom_measurment_div_1: 'Ingresa tu propia medida para el alimento:',
  unit_name: 'Name of the unit (singular)',
  custom_measurment_remember_unit: 'Recuerda incluir solamente el nombre de la unidad y no con la cantidad.',
  custom_measurment_div_2: 'Has ingresado un número en el nombre de la unidad. Las cantidades de la unidad no se ponen en este lugar.',
  custom_measurment_div_3:
    'Por ejemplo: si quieres crear la unidad casera "2/3 tazas" tendrás que escribir "taza" como nombre de la unidad. Luego de haberla creado, podrás elegir que la cantidad sea "2/3".',
  custom_measurement_label: 'Peso en gramos de la nueva unidad',
  new_food_details: 'Detalles del nuevo alimento',
  // PopupPatientSettings
  popup_patient_settings_warning: 'Ingresa el email del paciente para invitarlo al app',
  popup_patient_settings_modal_title: '¿Deseas volver a enviarle el correo de acceso al app?',
  popup_patient_settings_modal_content: 'Anteriormente enviaste la invitación al app a este paciente pero este aún no ha ingresado al app.',
  patient_has_entered_app: 'El paciente ha ingresado al app',
  patient_has_not_entered_app: 'El paciente todavía no ha ingresado al app',
  delete_patient: 'Eliminar paciente',
  show_daily_calories: 'Mostrar las calorías por día en los planes alimenticios del paciente.',
  show_recipes_in_mealplan: 'Mostrar las recetas con sus respectivos ingredientes e instrucciones en los planes alimenticios del paciente.',
  autogenerate_groceries_list: 'Autogenerar la lista de compras para cada plan alimenticio.',
  show_workout_in_mealplan: 'Mostrar la rutina asignada en el plan alimenticio del paciente',
  access_to_app: 'Acceso al app de pacientes de Almendra',
  access_mail_sent: 'Correo de acceso enviado',
  access_mail_not_sent: 'Todavía no has enviado el correo de acceso al App',
  patient_has_verified_email: 'El paciente ha verificado su email',
  patient_has_not_verified_email: 'El paciente todavía no verifica su email',
  // OnboardingTours
  welcome_to_mealplan_tour: '¡Bienvenido al tour del plan alimenticio!',
  onboarding_tours_div_1:
    'Aprenderás a hacer el plan alimenticio paso por paso y te encantará: verás qué tan rápido te olvidas de Excel u otros softwares.',
  onboarding_tours_div_2: 'La pantalla de atrás muestra el primero de los 4 pasos de un plan alimenticio, que es elegir su formato.',
  onboarding_tours_div_3: 'Ahora veremos las distintas maneras de crear un plan alimenticio.',
  create_meal_plan: 'Crear plan alimenticio',
  onboarding_tours_step_1_content:
    'Haciendo click en el botón crearemos un plan alimenticio estricto para el paciente. Seremos redirigidos a calcular los nutrientes del mismo.',
  select_the_days: 'Seleccionar los días',
  onboarding_tours_step_2_content_1:
    'En esta sección podrás seleccionar los días para lo cuales podrás establecer el objetivo calórico y la distribución de macronutrientes.',
  required_action: 'Acción requerida',
  onboarding_tours_step_2_content_2: 'Selecciona todos los días para continuar',
  add_caloric_targets: 'Agregar objetivos calóricos',
  onboarding_tours_step_3_content: 'Aquí podrás ingresar el objetivo calórico del paciente, ya sea con las fórmulas o manualmente.',
  add_macro_targets: 'Agregar objetivos de macronutrientes',
  onboarding_tours_step_4_content_1: 'En esta sección podrás ingresar objetivos de macronutrientes para los días seleccionados.',
  onboarding_tours_step_4_content_2: 'Establece una distrución de macronutrientes',
  add_targets_by_meal: 'Agregar objetivos por comida',
  onboarding_tours_step_5_content:
    'En caso desees agregar objetivos calóricos y de macro nutrientes por comida, también puedes hacerlo con este botón:',
  view_patient_information: 'Ver la información del paciente',
  onboarding_tours_step_6_content_1: 'En el extremo derecho de tu pantalla, encontrarás el siguiente ícono:',
  onboarding_tours_step_6_content_2:
    'Al hacer click, podrás visualizar de manera rápida la información más relevante del paciente para guiarte tanto en los objetivos como durante el plan alimenticio.',
  save_caloric_targets: 'Guardar objetivos calóricos',
  onboarding_tours_step_7_content_1:
    'Después de que hayas establecido la distribución los macronutrientes debemos guardar los objetivos para los días seleccionados.',
  click_on_save_targets: 'Click en "Guardar los objetivos"',
  go_to_next_step: 'Vayamos al siguiente paso',
  onboarding_tours_step_8_content: '¡Listo! Vayamos a agregar alimentos, comidas y recetas al plan alimenticio.',
  search_foods: 'Buscar y agregar alimentos y recetas',
  onboarding_tours_step_9_content_1: 'En este campo podrás realizar las siguientes acciones:',
  onboarding_tours_step_9_content_2: 'Buscar alimentos y recetas',
  onboarding_tours_step_9_content_3: 'Modificar los filtros de búsqueda',
  onboarding_tours_step_9_content_4: 'Editar la cantidad y unidad del alimento o receta',
  onboarding_tours_step_9_content_5: 'Agregar el alimento o receta a las comidas que desees',
  edit_meal: 'Editar comida',
  onboarding_tours_step_10_content_2:
    'Cuando tu cursor esté encima de una comida, tendremos la opción de editarla dándole click al lápiz en la parte superior derecha de la comida, como podemos ver aquí:',
  onboarding_tours_step_10_content_1: 'Aquí podremos editar la comida o agregar comentarios. Podrás hacerlo en los siguientes pasos.',
  day_actions: 'Acciones de días',
  onboarding_tours_step_11_content:
    'También puedes hacer diferentes acciones en cada día al darle click en los tres puntos al lado del nombre del día',
  onboarding_tours_step_12_content_1: '¡Vas por buen camino!',
  onboarding_tours_step_12_content_2: '¡Edita este plan como quieras! Puedes agregar alimentos, comidas y aplicar lo que acabas de aprender.',
  onboarding_tours_step_12_content_3:
    'Una vez que acabes dale click al botón "Siguiente" en la esquina superior derecha, y podrás enviarle el plan al paciente.',
  onboarding_tours_step_13_content:
    'Aquí podrás ver un resumen del plan alimenticio y editar el nombre (título principal del PDF) y el periodo del plan alimenticio',
  add_indications: 'Agregar indicaciones y recomendaciones',
  onboarding_tours_step_14_content:
    'Puedes añadir indicaciones o recomendaciones para el paciente. Además, puedes crear tus propias plantillas reusables de indicaciones.',
  viewing_options: 'Ver opciones de visualización',
  onboarding_tours_step_15_content:
    'Aquí puedes elegir si muestras las calorías, la lista de compras y las recetas con instrucciones e ingredientes dentro del PDF o dentro del app',
  onboarding_tours_step_16_content:
    'Aquí puedes revisar y editar la lista de compras generada de manera automática. Siempre recomendamos revisarla antes de mandarla.',
  download_or_send_mealplan: 'Descarga o envía el plan',
  onboarding_tours_step_17_content: 'Puedes descargar el PDF o enviarlo al correo de tu paciente, sin tener que salir de Almendra.',
  onboarding_tours_step_18_content:
    'Una vez que termines el plan alimenticio, le llegará una notificación en el telefono a tu paciente y lo podrá ver en su app',
  tour_end: 'Hemos llegado al final del tour',
  onboarding_tours_step_19_content_1: 'Esperamos que esta ayuda te haya servido para ir adaptándote a Almendra.',
  onboarding_tours_step_19_content_2:
    'Si tienes alguna sugerencia ahora o en cualquier momento, por favor, déjanos un mensaje a través del buzón de sugerencias en la barra superior.',
  onboarding_tours_step_19_content_3: 'Te deseamos mucho éxito,',
  almendra_team: 'Equipo de Almendra',
  onboarding_tour_appointment_step_0_content_1: '¡Bienvenido al tour de la consulta!',
  onboarding_tour_appointment_step_0_content_2:
    'Aquí aprenderás cómo hacer una consulta nutricional con Almendra. Nunca verás tu información mejor organizada.',
  onboarding_tour_appointment_step_0_content_3: 'En esta página inicia la consulta, la cual consiste en 4 pasos:',
  onboarding_tour_appointment_step_0_content_4: 'información del paciente, medidas del paciente, plan alimenticio y resumen de la consulta.',
  onboarding_tour_appointment_step_0_content_5:
    'En el paso actual podemos ver y actualizar toda la información y la historia del paciente, consulta tras consulta. ¡Continuemos!',
  patient_fields: 'Campos del paciente',
  onboarding_tour_appointment_step_1_content: 'Hay distintos campos por los que puedes navegar.',
  anthropometric_measurements: 'Medidas antropométricas y químicas',
  onboarding_tour_appointment_step_2_content: 'Aquí podrás ver y editar las medidas pasadas del paciente.',
  onboarding_tour_appointment_step_3_content: 'Registremos las medidas de la consulta.',
  measurements_entry: 'Registro de medidas',
  onboarding_tour_appointment_step_4_content: 'Aquí podremos agregar las medidas correspondientes a la consulta.',
  onboarding_tour_appointment_step_5_content: 'Veamos los planes alimenticios asignados.',
  add_mealplans: 'Agregar planes alimenticios',
  onboarding_tour_appointment_step_6_content_1: 'Aquí podrás añadir planes alimenticios asociados a esta consulta.',
  onboarding_tour_appointment_step_6_content_2: 'Click en "Añadir plan alimenticio"',
  onboarding_tour_appointment_step_7_content:
    'Puedes empezar el plan alimenticio ahora mismo, dándole click al botón verde para empezar el plan. De lo contrario quedará como pendiente para que lo hagas luego.',
  onboarding_tour_appointment_step_8_content:
    'Puedes descargar un reporte de la consulta en formato PDF, también se lo puedes enviar a tus pacientes vía mail.',
  appointment_summary: 'Resumen de la consulta',
  onboarding_tour_appointment_step_9_content:
    'Puedes agregar los pagos, comentarios y agendar la próxima consulta. También puedes ver un resumen del progreso.',
  // PopupNewWorkout
  'Aumentar masa muscular': 'Aumentar masa muscular',
  'Quemar grasa': 'Quemar grasa',
  'Mejorar resistencia': 'Mejorar resistencia',
  'Aumentar flexibilidad': 'Aumentar flexibilidad',
  Otros: 'Otros',
  workout_template_successfully_created: '¡Plantilla de rutina creada con éxito!',
  workout_template_successfully_edited: '¡Plantilla de rutina editada con éxito!',
  workout_successfully_edited: '¡Rutina editada con éxito!',
  workout_successfully_deleted: '¡Rutina eliminada con éxito!',
  create_workout_template: 'Crear plantilla de rutina',
  assign: 'Asignar',
  edit_workout: 'Editar rutina',
  exercise_routine: 'Rutina de ejercicios',
  edit_workout_template: 'Editar plantilla de rutina',
  assign_exercise_routine: 'Asigna una rutina de entrenamiento',
  assign_routine: 'Asignar rutina',
  save_workout: 'Guardar rutina',
  create_workout: 'Crear rutina',
  unassign_routine: 'Desasignar rutina',
  popup_new_workout_modal_prompt: 'Puedes personalizar esta rutina para este paciente',
  popup_new_workout_name_message: 'Ingresa el nombre de la rutina',
  popup_new_workout_name_placeholder: 'Ej: Rutina para aumenta masa muscular',
  at_least_one_day: 'Selecciona mínimo un día',
  popup_new_workout_days_placeholder: 'Ej: Martes, Jueves, Sábado',
  popup_new_workout_goal_message: 'Selecciona un objetivo',
  popup_new_workout_goal_placeholder: 'Ej: Aumentar masa muscular',
  popup_new_workout_duration_message: 'Ingresa los minutos de duración',
  popup_new_workout_duration_placeholder: 'Ej: 60',
  equipment: 'Equipamiento',
  with_equipment: 'Con equipamiento',
  without_equipment: 'Sin equipamiento',
  popup_new_workout_body_message: 'Ingresa el contenido de la rutina',
  popup_new_workout_textarea_placeholder:
    'En esta sección se detalla el contenido de la rutina con todas sus especificaciones. Ej:\n- 20 sentadillas con salto\n- 10 flexiones\n- 30 jumping jacks',
  // FinancialsMain
  financials_general: 'General',
  last_period: 'el periodo pasado',
  financials_income: 'Ingresos',
  financials_expenses: 'Gastos',
  disabled_feature: 'Funcionalidad deshabilitada',
  in_this_week: 'en esta semana',
  in_the_last_days: 'en los últimos {{graphTimestamp}} días',
  previous_period_with_l: 'l periodo pasado',
  last_7_days: 'últimos 7 días',
  last_30_days: 'últimos 30 días',
  last_365_days: 'últimos 365 días',
  loading_financials: 'Cargando finanzas...',
  historic: 'histórico',
  // TourSteps
  tourSteps_0_title: 'Te presentamos el formato por Intercambios',
  tourSteps_0_content_1:
    'Este formato consiste en un sistema flexible que permite a los pacientes elegir lo que comerán según sus gustos y sin menús específicos.',
  tourSteps_0_content_2: 'Con este pequeño tour aprenderás a realizar este tipo de planes alimenticios fácilmente.',
  start_the_tour: '¡Comencemos el tour!',
  tourSteps_1_content_1: 'Los grupos de intercambios que hayas seleccionado como ',
  default: 'predeterminado',
  tourSteps_1_content_2: 'en la sección de',
  equivalents_lowercase: 'equivalencias',
  tourSteps_1_content_3: 'serán mostrados por defecto',
  tourSteps_2_content_1: 'En esta sección podrás',
  tourSteps_2_content_2: 'Añadir los grupos de intercambio al día',
  tourSteps_2_content_3: 'Añadir las comidas al día',
  tourSteps_3_content_1: 'Podrás cambiar la cantidad de porciones de cada comida en esta celda. Aparecerán los siguientes indicadores visuales:',
  quantity_over_0: 'cantidad es mayor a 0',
  quantity_is_0: 'cantidad es igual a 0',
  tourSteps_4_content: 'Aquí estará el total de porciones que ingresaste en el día para este grupo de intercambios',
  tourSteps_5_title: 'Análisis por día de un grupo de intercambio',
  tourSteps_5_content_1: 'Puedes ver el aporte calórico y de macronutrientes para este grupo de intercambios.',
  tourSteps_6_title: 'Análisis por comida',
  tourSteps_6_content_1: 'Acá puedes ver el análisis de todos los grupos de intercambio ingresados para esta comida.',
  tourSteps_7_title: 'Análisis por día',
  tourSteps_7_content_1: 'Puedes ver el aporte de todos los grupos de intercambio para este día.',
  tourSteps_8_title: 'Análisis por día vs Objetivos',
  tourSteps_8_content: 'Puedes ver el porcentaje de adecuación de cada macronutriente',
  tourSteps_9_content: 'Para agregar opciones de alimentos extras para mostrarlas al paciente, haz click en el botón',
  place_foods: 'Colocar alimentos',
  tourSteps_10_content: 'Acá se mostrará los grupos de intercambios asignados para esta comida en la sección anterior',
  tourSteps_11_title: 'Puedes agregar más alimentos a esa comida',
  tourSteps_11_content_1: 'Por ejemplo, si las porciones de intercambio que asignaste al Desayuno fueron: 1 Verdura, 2 Frutas, 1 Lácteo y 1 Cereal',
  tourSteps_11_content_2: 'Un grupo de opciones que podrías ingresar sería:',
  tourSteps_11_content_3: 'Avena con leche',
  tourSteps_11_content_4: '(1 Cereal y 1 Lácteo)',
  tourSteps_11_content_5: 'Smoothie de espinaca y papaya',
  tourSteps_11_content_6: '(1 Verdura y 1 Fruta)',
  tourSteps_11_content_7: 'Palta',
  tourSteps_11_content_8: '(1 Fruta)',
  tourSteps_12_content_1: 'Puedes ver el aporte nutricional de cada opción extra añadida en esta comida.',
  tourSteps_13_title:
    'Y, ¡listo! Una vez que hayas ingresado los grupos de intercambio y sus cantidades para cada comida, el plan alimenticio quedaría así:',
  // QuotesLoadingPage
  quotes_1: 'Enamorarse de uno mismo es el primer secreto para la felicidad.',
  quotes_2: 'Aceptarnos como somos significa valorar nuestras imperfecciones tanto como nuestras perfecciones.',
  quotes_3: 'La peor soledad es no sentirse cómodo contigo mismo.',
  quotes_4: 'Amarte a ti mismo no es vanidad. Es cordura.',
  quotes_5: 'Demasiadas personas sobrevaloran lo que no son y subvaloran lo que son.',
  quotes_6: 'Nunca agaches la cabeza. Mira el mundo directamente a la cara.',
  quotes_7: 'Sé fiel a lo que existe dentro de ti mismo.',
  quotes_8: 'Tienes que creer en ti mismo cuando nadie más lo hace.',
  quotes_9: 'Si tienes la capacidad de amar, ámate a ti mismo primero.',
  quotes_10: 'Quien mira afuera, sueña; quien mira adentro, despierta.',
  quotes_11: 'Nadie puede hacerte sentir inferior sin tu consentimiento',
  quotes_12: 'Confía en ti mismo, sabes más de lo que crees.',
  quotes_13: 'El crecimiento comienza cuando comenzamos a aceptar nuestra propia debilidad.',
  quotes_14: 'Soy mi propio experimento. Soy mi propia obra de arte.',
  quotes_15: 'Nunca es demasiado tarde para ser lo que podrías haber sido.',
  quotes_16: 'Permítete disfrutar cada momento feliz de tu vida.',
  quotes_17: 'La belleza comienza en el momento en que decidiste ser tú mismo.',
  quotes_18: 'No dudes de ti mismo, para eso están los enemigos.',
  quotes_19: 'Sé fiel a ti mismo. Un original vale más que una copia.',
  quotes_20: 'Siempre que sea posible, elige la paz',
  quotes_21: 'Los deseos son posibilidades. Atrévete a pedir un deseo.',
  quotes_22: 'Usted es un fenómeno natural, no un desastre natural.',
  // PatientStats
  hour: 'hora',
  hour_other: 'horas',
  // AddRecipeToMealPlan
  no_changes_in_recipe:
    'No has realizado cambios en la composición de la receta. Campos no editados: Ingredientes, Ingredientes Opcionales, Instrucciones, Porciones, Categoría.',
  new_feature: '¡Nueva funcionalidad!',
  error_editing_recipe: 'Ocurrió un problema al editar la receta',
  error_adding_ingredient: 'Ocurrió un problema al añadir el ingrediente',
  add_recipe_to_meal_plan_help_content_1: 'Ahora podrás editar una receta existente directamente desde el editor de planes alimenticios',
  add_recipe_to_meal_plan_help_content_2: 'Tendrás la opción de guardar los cambios de la receta como una nueva receta.',
  add_recipe_to_meal_plan_help_content_3:
    'También podrás guardar los cambios de la receta solo para el plan alimenticio actual. Esto resulta útil cuando necesitas ajustar una receta de acuerdo de los requerimientos o gustos del paciente para usarla solo una vez.',
  add_recipe_to_meal_plan_help_content_4: '¿Dónde puedo editar la receta con esta funcionalidad?',
  add_recipe_to_meal_plan_help_content_5: 'Al momento de añadir una receta al plan alimenticio (formato tabla o formato intercambios).',
  add_recipe_to_meal_plan_help_content_6: 'En cualquier receta añadida a un plan alimenticio anteriormente.',
  add_recipe_to_meal_plan_placeholder_1: 'Ingresa un ingrediente opcional',
  step: 'Paso',
  at_least_one_ingredient: 'Ingresa al menos un ingrediente a la receta',
  at_least_one_instruction: 'Ingresa al menos una instrucción a la receta',
  at_least_one_serving: 'El mínimo de porciones para la receta es 1',
  enter_recipe_name: 'Ingresa el nombre de la receta',
  add_recipe_to_meal_plan_warning_1: 'Para continuar, guarda la nueva receta con otro nombre',
  edit_recipe: 'Editar receta',
  no_image: 'Sin imagen',
  how_to_save_changes: '¿Cómo deseas guardar estos cambios?',
  keep_editing: 'Seguir haciendo cambios',
  only_this_meal: 'Solo para esta comida',
  only_this_meal_desc: 'Los cambios realizados se guardarán solo para esta comida. No podrás utilizarla en otras partes.',
  as_new_recipe: 'Como una nueva receta',
  as_new_recipe_desc: 'Se creará una nueva receta y tendrá los cambios que realizaste. Podrás utilizarla después en cualquier momento.',
  add_new_ingredient: 'Agregar nuevo ingrediente',
  // PersonalPageSettings
  disable_online_booking_error: 'Hubo un error al desactivar el sistema de reservas online',
  disabled_personal_page: 'Tu página personal está desactivada',
  disabled_personal_page_content: 'Actívala haciendo click en el switch de la sección "Mostrar mi página personal".',
  disabled_customFormFill_content: 'Activa tus formularios en la sección Ajustes Generales > Consultas',
  view_personal_page: 'Ver página personal',
  personal_page: 'Página personal',
  personal_page_url: 'URL de mi página personal',
  show_personal_page: 'Mostrar mi página personal',
  show_recipe_section: 'Mostrar la sección de recetas',
  show_blog_section: 'Mostrar la sección de blog',
  show_work_addresses: 'Mostrar las direcciones de mi consultorio',
  online_booking_link: 'URL para mis reservas online',
  enable_online_booking: 'Habilitar la reserva de citas online',
  what_form_to_send_first_appointment: '¿Qué formulario enviar en la primera consulta?',
  what_form_to_send_first_appointment_extra: 'Solo aplica para las consultas agendadas a través del sistema de reservas online.',
  what_form_to_send_second_appointment: '¿Qué formulario enviar a partir de la segunda consulta?',
  request_payment_long: 'Solicitar realizar el pago para poder realizar la reserva de cita',
  request_payment_long_extra_1: 'Para activar esta opción, vincula tu cuenta con PayPal',
  request_payment_long_extra_2: 'o MercadoPago',
  request_payment_long_extra_3: ' en la sección "Seguridad"',
  mandatory_payment: 'Pago obligatorio',
  optional_payment: 'Pago opcional',
  multiple_links: 'Múltiples enlaces',
  multiple_links_URL: 'URL de mis múltiples enlaces',
  multiple_links_settings: 'Configuración de múltiples enlaces',
  multiple_links_desktop: 'Edición en computadora',
  plan_restriction_modal_blog: 'Para poder crear tu propio Blog, necesitarías tener como mínimo el Plan Experto.',
  currently_subscribed_to: 'Actualmente estás en el plan',
  currently_not_subscribed_to: 'Actualmente no estás en ningún plan',
  // AppointmentSummary
  appointment_summary_fully_paid: 'Este paquete ha sido pagado en su totalidad',
  appointment_summary_fully_paid_2: 'Estás ingresando un monto',
  appointment_summary_fully_paid_3: 'mayor al saldo pendiente',
  appointment_summary_modal_content: '¿Deseas añadir <1>{{-currencySymbol}} {{amount}}</1> como monto extra para incluirlo en tus finanzas?',
  yes_add_extra_amount: 'Sí, añadir monto extra',
  fix_amount: 'Corregir monto',
  appointment_summary_modal_title: 'Aún quedaría un saldo pendiente de pago, ¿deseas continuar?',
  appointment_summary_modal_2_content_1: 'Parece que quieres ingresar un monto a pagar',
  appointment_summary_modal_2_content_2: 'menor al saldo pendiente',
  of_the_appointment: 'de la consulta',
  of_the_service: 'del paquete',
  appointment_summary_modal_2_content_3: 'Asumiremos que el monto total',
  will_be: 'será',
  instead_of: 'en vez de',
  unfilled_data: 'Dato no llenado',
  appointment_summary_changed_properties_tooltip_1: 'Este dato fue editado hace menos de ',
  appointment_summary_changed_properties_tooltip_2:
    'horas, por lo que fue sobrescrito por el valor de la consulta actual. Recuerda que para que se almacene un cambio en el historial del paciente deben pasar por lo menos ',
  appointment_summary_changed_properties_tooltip_3: 'horas luego del cambio anterior.',
  appointment_summary_changed_properties_tooltip_4: 'No fue modificado durante la consulta previa. Modificado el ',
  current_appointment: 'Consulta actual',
  property: 'Propiedad',
  previous_appointment: 'Consulta previa',
  cash: 'Efectivo',
  card: 'Tarjeta',
  no_card_commission: 'Aún no has ingresado la comisión que te cobran las tarjetas (Configuración > Ajustes Generales).',
  card_commission_prompt: 'Se considerará la comisión de {{amount}}% en tus finanzas',
  appointment_fully_paid: 'Esta consulta fue completamente pagada',
  pending_balance: 'Saldo pendiente',
  amount_paid_today: 'Monto pagado hoy',
  no_scheduled_appointments: 'aún no tiene consultas agendadas',
  previous_appointment_comparison: 'Comparación con la consulta previa',
  no_changes_previous_appointment: 'No hay cambios respecto a la consulta previa',
  any_other_comments: '¿Algún otro comentario sobre esta consulta que desees guardar?',
  any_other_comments_tooltip:
    'El registro de todos los comentarios de consultas de este paciente se puede ver en su perfil, en la sección "Comentarios y otros Datos".',
  // PopupEditScheduledAppointment
  delete_appointment_title: '¿Quieres eliminar esta consulta?',
  delete_scheduled_appointment_success: 'Eliminaste la consulta agendada con {{name}}',
  appointment_successfully_deleted: 'Consulta eliminada de la agenda',
  edit_appointment: 'Editar consulta',
  appointment_never_started: 'Esta consulta fue programada para el {{-date_1}} de {{-date_2}} a {{-date_3}} pero la consulta nunca se inició.',
  appointment_did_start: 'Esta consulta fue realizada el {{-date_1}} de {{-date_2}} a {{-date_3}}.',
  appointment_in_process: 'Esta consulta se encuentra en proceso',
  other_scheduled_appointments: 'Tienes otra consulta agendada para esta fecha',
  other_scheduled_appointments_other: 'Tienes otras consultas agendadas para esta fecha',
  select_modality: 'Selecciona la modalidad',
  // ExpertProfile
  change_subdomain_content_1: 'Tu página personal será cambiada de "<1>{{lastSubdomain}}</1>" a "<3>{{newSubdomain}}</3>".',
  change_subdomain_content_2: 'El nuevo acceso a tu dashboard se realizará a través de <1>{{-subdomainLink}}</1>.',
  change_subdomain_content_3: 'Al confirmar esta acción, te redigiremos a tu subdominio correspondiente.',
  subdomain_change_error: 'Hubo un error tratando de cambiar tu subdominio. Inténtalo nuevamente',
  mail_edit_success: 'Tu correo electrónico ha sido cambiado con éxito.',
  expired_code: 'El código ingresado ha expirado.',
  mail_edit_error: 'Hubo un error tratando de cambiar mail. Inténtalo nuevamente.',
  wrong_code_entered: 'El código ingresado es incorrecto.',
  change_email_title: '¿Deseas cambiar tu correo electrónico?',
  change_email_content_1:
    'Recuerda que con el correo electrónico se accede al dashboard. Al confirmar, desasociaremos el correo "<1>{{mail}}</1>" de esta cuenta.',
  change_email_content_2: 'La próxima vez que inicies sesión, ingresarás a través del correo "<1>{{mail}}</1>".',
  change_email_content_3: 'Al aceptar, te enviaremos un correo a <1>{{mail}}</1> para que confirmes este cambio.',
  change_email_content_4: 'Esta acción es irreversible. Recuerda nunca compartir tus credenciales con otra persona.',
  change_email_success: 'Te enviamos un correo electrónico a <1>{{mail}}</1> para confirmar este cambio',
  change_email_error: 'Hubo un error tratando de cambiar tu mail. Inténtalo nuevamente.',
  change_subdomain_title: '¿Deseas cambiar la dirección de tu subdominio?',
  enter_the_code: 'Ingresa el código',
  enter_the_code_tooltip: 'Un código será enviado a tu bandeja de entrada cuando solicites cambiar tu correo electrónico',
  verify: 'Verificar',
  enter_the_code_long: 'Ingresa el código para cambiar tu correo',
  invalid_email: 'El correo electrónico ingresado no es válido',
  personal_subdomain: 'Subdominio personal',
  personal_subdomain_valid: 'Ingresa un subdominio válido.',
  only_lowercase: 'Ingresa solo letras minúsculas.',
  personal_subdomain_length_min: 'Ingresa al menos 3 caracteres',
  personal_subdomain_length_max: 'Ingresa menos de 25 caracteres',
  personal_subdomain_unavailable: 'Este subdominio no está disponible. Intenta con otro.',
  nationality_message: 'Por favor, selecciona tu país de residencia',
  birthDate_message: 'Por favor, ingresa tu fecha de nacimiento',
  gender_message: 'Por favor, selecciona tu sexo',
  idNumber_message: 'Por favor, ingresa tu documento de identidad',
  customerName_label: 'Empresa',
  customerName_message: 'Ingresa el nombre de la empresa (máximo 40 caracteres)',
  customerName_ex: 'ej: Napsis Health LLC',
  customerName_extra: 'En caso este campo se encuentre ingresado, emitiremos los comprobantes de pago bajo este nombre',
  // MeasurementsHistory
  delete_appointment_error: 'Ocurrió un error al eliminar la consulta. Por favor, refresca la página y vuelve a intentarlo',
  feet_and_inches: 'pies y pulgadas',
  max_value: 'Máximo\nValor',
  min_value: 'Mínimo\nValor',
  total_variation: 'Variación\nTotal',
  last_measurement: 'Última\nMedida',
  normal_measurements_values: 'Valores normales',
  patient_on_normal_range: 'El paciente se encuentra en el rango normal',
  patient_not_on_normal_range: 'El paciente no se encuentra en el rango normal',
  measurements_normal_range_source: 'Fuente de valores normales: MedlinePlus (Biblioteca Nacional de Medicina de los EEUU) y laboratorios Blufstein',
  at_least: 'como mínimo',
  at_most: 'como máximo',
  child_data_source: 'Patrones de crecimiento infantil - OMS | Organización Mundial de la Salud',
  bmi_with_pre_gestational_weight: 'El IMC se calcula con el peso pre-gestional',
  one_week_ago: 'Hace una semana',
  two_weeks_ago: 'Hace dos semanas',
  one_month_ago: 'Hace un mes',
  three_months_ago: 'Hace tres meses',
  // FlexibleMealPlanDays
  no_other_food_groups: 'No se encontraron otros grupos con alimentos para este día.',
  delete_food_group_question: '¿Deseas borrar este grupo?',
  yes_delete_group: 'Sí, eliminar grupo',
  delete_dynamic: '¿Deseas borrar',
  copy_from_day_dynamic: '¿Copiar desde el día',
  copy_day_confirm_body:
    'Se reemplazará todo el contenido actual del día {{dayName_1}} por el contenido del {{dayName_2}}. Esta acción es irreversible.',
  copy_to_every_day_question: '¿Copiar este día en todos los días?',
  copy_to_every_day_body:
    'Se copiará el contenido de este día ({{dayName}}) en el resto de días. En caso existiera información en los otros días, esta será completamente reemplazada. Esta acción es irreversible.',
  clear_day_confirmation_body: 'Se eliminará todo el contenido actual del día {{name}}. Esta acción es irreversible.',
  remaining: 'Restante',
  switch_to_chart: 'Cambiar a gráfico',
  switch_to_table: 'Cambiar a tabla',
  view_weekly_summary: 'Ver resumen semanal',
  stick: 'Anclar',
  unstick: 'Desanclar',
  chart: 'Gráfico',
  clear_day: 'Limpiar día',
  meal_plan_only_has_one_day: 'El plan alimenticio solo tiene un día',
  copy_to_every_day: 'Copiar a todos los días',
  copy_from_day: 'Copiar del día',
  no_other_day_with_foods: 'Por ahora ningún otro día tiene alimentos',
  of_target: 'del objetivo',
  nutrients_per_meal: 'Nutrientes por comida',
  nutrients_per_day: 'Nutrientes por día',
  move_position: 'Mover de posición',
  where_to_move_meal: '¿A qué posición deseas mover esta comida?',
  at_first: 'Al principio',
  before_of: 'Antes de',
  at_the_end: 'Al final',
  delete_meal: 'Eliminar comida',
  mealplan_group_text_help: 'Agrega un grupo de alimentos en esta comida. Tu paciente deberá escojer un alimento del grupo.',
  flexible_mealplan_tooltip_1: 'Se muestra como referencia el promedio de las calorías, macronutrientes y micronutrientes en cada grupo de opciones',
  copying_meal_state: 'Copiando comida',
  macronutrients: 'Macronutrientes',
  micronutrients: 'Micronutrientes',
  // SearchBar
  no_results_for_search_searchBar: 'No se encontraron resultados para la búsqueda <1>"</1><2>{{text}}</2><3>"</3>',
  allergic_food_modal_title: 'Hemos encontrado una coincidencia con los alimentos alérgicos para el paciente',
  allergic_food_modal_content_0: 'Estás a punto de añadir el alimento',
  allergic_food_modal_content_1: 'Los alimentos a los que el paciente es alérgico son:',
  allergic_food_modal_content_2: '¿Deseas añadir este alimento al plan?',
  allergic_food_modal_okText: 'Sí, añadir igualemente',
  search_food_error: 'Ocurrió un problema al buscar los alimentos. Por favor, vuelve a intentarlo.',
  add_favorite_food_modal_title: 'Acabas de agregar tu primer alimento favorito',
  add_favorite_food_modal_content_1: 'Marca tus alimentos favoritos para agregarlos más rápido en tus planes alimenticios.',
  add_favorite_food_modal_content_2: 'Para ver todos tus alimentos favoritos, selecciona el botón de filtro (<1/>) y sigue los siguientes pasos:',
  add_favorite_food_modal_content_3: 'Podrás visualizar y editar todos tus alimentos favoritos en la sección de <1>Alimentos</1>',
  remove_favorite_food: '¿Deseas quitar este alimento de tus Alimentos Favoritos?',
  yes_remove: 'Sí, quitar',
  copy_food: 'Copiar alimento',
  searchbar_min_length: 'Escribe mínimo 3 caracteres',
  no_results: 'Sin resultados',
  result: 'resultado',
  result_other: 'resultados',
  showing: 'Mostrando',
  my_food: 'Mi alimento',
  almendra_recipe: 'Receta de Almendra',
  my_recipe: 'Mi receta',
  peru_branded_foods: 'Alimentos de supermercados Perú (2021)',
  accept: 'Aceptar',
  restaurate: 'Restaurar',
  searching: 'Buscando',
  favorite_foods_not_added: 'Todavía no agregas tus alimentos favoritos',
  recipes_not_found: 'No se encontraron recetas terminadas',
  foods_not_found: 'No se encontraron alimentos',
  foods_not_found_text:
    '¿No encontraste el alimento que buscabas? Ingresa un alimento manualmente y lo incorporaremos privadamente para tus próximas búsquedas.',
  select_favorite_foods_text:
    'Marca los alimentos que siempre usas como favoritos para facilitarte la búsqueda haciendo click en el ícono del corazón',
  in_every_database: 'en todas las bases de datos',
  create_your_own_recipes: 'Comienza creando tus propias recetas y utilízalas cuando elabores planes alimenticios de tus pacientes.',
  go_to_recipes: 'Ir a Recetas',
  add_food_manually: 'Ingresa un alimento manualmente y lo incorporaremos privadamente para tus próximas búsquedas.',
  // PayPalLinkAccount
  paypal_link_account_title: '¿Confirmas que {{mail}} es correcto?',
  paypal_verify_mail:
    'Verifica que tengas una cuenta de PayPal asociada a ese email. Te recordamos que si no tienes acceso a ese email, podrías no reclamar los fondos que te envíen.',
  account_successfully_linked: 'Cuenta vinculada con éxito',
  paypal_unlink_title: '¿Quieres desvincular tu cuenta de PayPal con Almendra?',
  paypal_unlink_content:
    'Te recordamos que toda opción de pagos en tu cuenta se deshabilitará. Tus pacientes no podrán realizar los pagos después de esta acción.',
  paypal_link_account: 'Vincular cuenta con PayPal',
  paypal_link_account_modal_content_1: 'Tus pacientes podrán pagar por tus consultas al reservar a través del sistema de reservas online.',
  paypal_link_account_modal_content_2: 'Como tu moneda en Almendra es {{-currency}}',
  paypal_link_account_modal_content_3: 'Tus pacientes pagarán en {{currentCurrencyCode}} ',
  paypal_link_account_modal_content_4:
    'Tus pacientes pagarán en USD. Se utilizará un tipo de cambio referencial USD/{{currentCurrencyCode}} que se actualiza cada 24 horas para convertir el monto de tus servicios a USD.',
  note: 'Nota',
  note_other: 'Notas',
  paypal_link_account_modal_content_5:
    '- PayPal cobra dos tarifas por utilizar sus servicios dependiendo de tu país: fee por transacción (dirígete <1>aquí</1> para más información) y fee para enviarte los pagos (dirígete <3>aquí</3> para más información).',
  paypal_link_account_modal_content_6: '- Almendra no cobra ninguna tarifa adicional.',
  paypal_link_account_modal_content_7: 'Recuerda revisar la <1>política de Protección del Vendedor de PayPal</1>.',
  paypal_email: 'Email de Paypal',
  enter_paypal_email: 'Ingresa tu email de Paypal',
  paypal_link_account_modal_content_8: 'Si no tienes una cuenta en PayPal, puedes registrarte gratuitamente <1>aquí</1>.',
  // MealPlanView
  view_summary: 'Ver resumen',
  view_day: 'Ver el {{dayName}}',
  meal_plan_view_alert_1: 'Estás viendo el plan alimenticio asignado para',
  meal_plan_view_alert_2: 'Este plan fue programado para el {{-date_1}} al {{-date_2}}.',
  meal_plan_view_alert_3: 'Recuerda que estás en modo "<1>solo lectura</1>", por lo que no se podrán realizar cambios.',
  go_to_day: 'Ir al {{dayName}}',
  // PopupHealthPlanPayments
  php_appointment_status_finished: 'Esta consulta finalizó el {{-date, DD/MM/YYYY}}.',
  php_appointment_status_delayed: 'Esta consulta no fue iniciada.',
  php_status_finished: 'Este paquete finalizó el {{-date, DD/MM/YYYY}}.',
  enrolled_since: 'Inscrito desde',
  no_scheduled_appointments_yet: 'Todavía no se han agendado consultas para este paquete.',
  appointmented_delayed: 'Consulta retrasada',
  payment_done: 'Pago realizado',
  payments_done: 'Pagos realizados',
  no_payments_yet: 'Aún no se han realizado pagos',
  payment_with_paypal: 'Realizado con Paypal',
  payment_with_mercadopago: 'Realizado con MercadoPago',
  service_completely_paid: 'Este paquete ha sido completamente pagado',
  additional_amount_paid: 'Se pagó un monto adicional de {{currencySymbol}} {{pendingAmount}}',
  unassign_service: 'Desasignar paquete',
  unassign_service_alert: 'Para desasignar el paquete a este paciente primero termina su consulta en proceso.',
  assign_service_alert: 'Para asignar el paquete a este paciente primero termina su consulta en proceso.',
  load_service_error: 'No se pudo cargar la información del paquete. Refresca la página y vuelve a intentarlo.',
  unassign_service_title: '¿Deseas desasignar el paquete nutricional?',
  unassign_service_title_content_1:
    'Se mantendrá en la historia del paciente la información de las consultas realizadas de este paquete pero se eliminarán todas las consultas agendadas en este paquete.',
  unassign_service_title_content_2:
    'Existe un pago realizado por {{processor}} para este paquete. Al desasignar este paquete se perderá la información de este pago.',
  yes_unassign: 'Sí, desasignar',
  unassign_service_success: 'Paquete desasignado satisfactoriamente',
  hide_appointment_details: 'Ocultar detalles de la consulta',
  hide_service_details: 'Ocultar detalles del paquete',
  view_appointment_details: 'Ver detalles de la consulta',
  view_service_details: 'Ver detalles del paquete',
  //
  // SBM
  //
  //AppointmentCommentsTimeline
  pre_edit_pastAppointmentComment_title: 'Estás a punto de editar un comentario de una consulta pasada',
  pre_edit_pastAppointmentComment_content:
    'Recomendamos hacer esto estrictamente cuando creas que hubo algún error en los comentarios de una consulta pasada',
  comments_before_firstAppointment: 'Comentarios previos a la primera consulta',
  comments_per_appointment: 'Comentarios por consulta',
  appointment_of_date: 'Consulta del ',
  // BillingPlanSelection
  regulate_plan: 'Regularizar',
  select_plan: 'Seleccionar',
  current_plan: 'Tu plan actual',
  choose_higher_billingPlan: 'Elige un plan con mayor límite de pacientes activos',
  no_surprise_billingPlans: 'Planes flexibles sin sorpresas',
  choose_your_right_plan: 'Elige el que se adapte a tus necesidades',
  most_popular_plan: 'Más popular',
  early_adopters_promotion: 'Promoción aplicada por lanzamiento',
  first_month_discount_percentage: '% de descuento el primer mes!',
  then: 'luego',
  billing_per_month: 'por mes, facturación',
  for_12_months: 'por 12 meses',
  anual_billing_per_month: 'por mes, facturación anual',
  plan_initial_discount_message: '¡Tienes <1>{{onBoardingDiscount}}% de descuento</1> en tu primer mes!',
  billing_plan_text_first: 'primer',
  billing_plan_text_month: 'mes',
  billing_plan_text_per: 'por',
  freemium: 'Gratis',
  error_ondemand_charge_title: 'Hubo un error de cobro por el uso de tu suscripción. Se incluirá este monto en tu próximo pago.',
  plan_forever: 'para siempre',
  plan_per_patient_per_month: '/ paciente activo / mes',
  features_active_patients_limit: 'Pacientes activos por mes',
  features_active_patients_limit_des:
    'Paciente es activo es aquel paciente por el cual ha pasado por una consulta o ha tenido un plan alimenticio en uso durante el mes del período de facturación.',
  features_active_patients_limit_free: 'Máximo 3 pacientes activos',
  features_active_patients_limit_free_des: 'El Plan Free te permite atender hasta un máximo de 3 pacientes distintos por mes.',
  features_active_patients_limit_pro: 'Pacientes activos ilimitados',
  features_active_patients_limit_pro_des: 'Todos los pacientes que quieras atender. El plan empieza con mínimo {{minPatients}} pacientes activos.',
  Free: 'Free',
  Standard: 'Standard',
  Pro: 'Pro',
  'Plan Free': 'Plan Free',
  'Plan Standard': 'Plan Standard',
  'Plan Pro': 'Plan Pro',
  features_active_max_stored_patients_free: 'Almacenamiento limitado',
  features_active_max_stored_patients_free_des: 'Registra hasta 20 pacientes o crea hasta 20 planes alimenticios.',
  features_active_max_stored_patients_pro: 'Almacenamiento ilimitado',
  features_active_max_stored_patients_pro_des: 'Sin límite de pacientes ni planes alimenticios.',
  features_active_patients_limit_unlimited: 'Ilimitado',
  features_active_patients_limit_unlimited_tooltip: 'Mínimo {{minPatients}} pacientes activos facturados',
  features_basic_records: 'Historial de pacientes',
  features_basic_records_des: 'Registra fácilmente la información de tus pacientes y accede en cualquier momento, en cualquier lugar.',
  features_basic_plans: 'Creación de planes alimenticios personalizados',
  features_basic_plans_des: 'Elabóralos extremadamente rápido, al máximo detalle y con una analítica de nutrientes increíble.',
  features_basic_groceries: 'Lista de compras automática',
  features_basic_groceries_des: 'Genera la lista de compras de cada plan con un solo click. Ahórrales tiempo y esfuerzo a todos tus pacientes.',
  features_basic_dashboard: 'Dashboard personalizado',
  features_basic_dashboard_des: 'Tu propio panel de control totalmente a tu medida.',
  features_basic_agenda: 'Agenda integrada',
  features_basic_agenda_des: 'Gestiona tu agenda desde Almendra y reduce el ausentismo a las consultas. Integración con Google Calendar disponible.',
  features_basic_database: 'Bases de datos de alimentos',
  features_basic_database_des:
    'Más de 10000 alimentos de bases de datos oficiales en la palma de tu mano, para que dejes de perder tiempo buscando los nutrientes en libros u otras páginas.',
  features_basic_app: 'App para pacientes',
  features_basic_app_des: 'Todos tus pacientes tendrán acceso inmediato a sus planes alimenticios y su progreso, con una interfaz extraordinaria.',
  features_basic_equivalents: 'Listas de equivalencias',
  features_basic_equivalents_des: 'Incorpora tus propias equivalencias para asignarlas en los planes alimenticios.',
  features_basic_templates: 'Plantillas de planes alimenticios',
  features_basic_templates_des: 'Genera dietas al instante gracias a plantillas alimenticias elaboradas por ti o por Almendra.',
  features_basic_services: 'Paquetes de consultas',
  features_basic_services_des: 'Lleva un mejor control de los tipos de consultas que ofreces, para conocer la rentabilidad que te da cada uno.',
  features_basic_recipes: 'Creación y análisis de recetas',
  features_basic_recipes_des:
    'Añade todas las recetas que quieras e inclúyelas en los planes alimenticios, los nutrientes son calculados automáticamente.',
  features_basic_links: 'Generador de enlace para Instagram',
  features_basic_links_des: 'Herramienta que te permite compartir múltiples enlaces utilizando un solo enlace.',
  features_basic_booking: 'Sistema de reservas online',
  features_basic_booking_des:
    'Olvídate de coordinar consultas textualmente o por teléfono. Aprovecha el tiempo en otras cosas, las consultas llegan solas.',
  features_basic_workouts: 'Rutinas de entrenamiento',
  features_basic_workouts_des: 'Acompaña tu plan alimenticio con una rutina de entrenamiento para tus pacientes.',
  features_basic_patients: 'Hasta 8 pacientes al mes',
  features_basic_patients_des: 'El Plan Básico te permite atender un máximo de 8 pacientes distintos por mes.',
  features_advanced_name: 'Todo lo del Básico más:',
  features_advanced_stats: 'Estadísticas del negocio',
  features_advanced_stats_des:
    'Análisis en tiempo real de toda tu labor. Olvídate de sacar cálculos semanales o mensuales de cuántos pacientes atendiste.',
  features_advanced_mailing: 'Envío automático de correos',
  features_advanced_mailing_des: 'Envía recordatorios de consulta y otros correos sin mover un dedo',
  features_advanced_finance: 'Finanzas del negocio',
  features_advanced_finance_des:
    'Rentabiliza al máximo tu conocimiento, gracias a toda una sección dedicada al registro, control y analítica de los números de tu negocio.',
  features_advanced_web: 'Página web personalizada',
  features_advanced_web_des: 'Tu propia página web sin costos adicionales. Da a conocer tus servicios de manera más completa.',
  features_advanced_patients: 'Hasta 25 pacientes al mes',
  features_advanced_patients_des: 'El Plan Avanzado te permite atender un máximo de 25 pacientes distintos por mes.',
  features_expert_pre: 'Todo lo del Avanzado más:',
  features_expert_blog: 'Almendra Blog',
  features_expert_blog_des: 'Divulga tu conocimiento con tu propio Blog y haz que tus ideas den de qué hablar.',
  features_expert_stats: 'Estadísticas por paciente',
  features_expert_stats_des: 'Analítica profunda para conocer a cada paciente al máximo detalle.',
  features_expert_patients: 'Hasta 50 pacientes al mes',
  features_expert_patients_des: 'El Plan Experto te permite atender un máximo de 50 pacientes distintos por mes.',
  features_unlimited_pre: 'Todo lo del Experto más:',
  features_unlimited_support: 'Soporte premium',
  features_unlimited_support_des:
    'Un equipo dedicado para que los tiempos de respuesta sean casi inmediatos, y tus dudas sean atendidas con máxima prioridad.',
  features_unlimited_patients: 'Pacientes mensuales ilimitados',
  features_unlimited_patients_des: 'Todos los pacientes que quieras. Simple.',
  features_unlimited_mealplangen: 'Generador de planes alimenticios',
  features_unlimited_mealplangen_des:
    'Algoritmo de planificación de alimentación personalizado que te ayuda a crear planes alimenticios eficaces y adaptados a las necesidades de tus pacientes.',
  currency_description: '* Expresado en dólares americanos (USD)',
  // HandleSubscription
  cancel_ondemand_content_pre_0:
    '{{firstName}}, el periodo actual de tu suscripción al <1>Plan {{planName}}</1> es del <3>{{-startDate, DD/MM/YYYY}} al {{-endDate, DD/MM/YYYY}}</3>.',
  cancel_ondemand_content_pre_1: 'Hasta el día de hoy, la facturación por este periodo es:',
  cancel_ondemand_subscription_title: '¿Deseas cancelar la renovación de tu suscripción?',
  cancel_grace_ondemand_subscription_content_0: 'Cómo actualmente tienes <1>0 pacientes activos</1>, queremos ayudarte a que sigas creciendo.',
  cancel_grace_ondemand_subscription_content_1:
    'Por esta vez, cancelaremos tu suscripción al <1>Plan {{planName}}</1> sin que incurras en algún pago y pasarás al <3>Plan Free</3>, donde podrás tener hasta 3 pacientes activos al mes sin necesidad de pagar, ¡totalmente gratis!',
  cancel_ondemand_subscription_content_0:
    'El <1>{{- endDate, DD/MM/YYYY}}</1>, último día de tu periodo actual, se cobrará la facturación pendiente hasta ese momento y se cancelará la renovación al <3>Plan {{planName}}</3>. De ahí, pasarás al <5>Plan Free</5>, donde podrás tener hasta 3 pacientes activos al mes sin necesidad de pagar, ¡totalmente gratis!.',
  cancel_ondemand_subscription_content_1: 'En caso sigas activando pacientes hasta esa fecha, será incluído en tu facturación.',
  do_you_want_to_continue: '¿Deseas continuar?',
  done_discount_obtained: '¡Listo! Descuento obtenido',
  canceled_subscription_to: 'Cancelaste tu suscripción al',
  subscription_cancel_failed: 'No se pudo cancelar tu suscripción',
  resumed_subscription_to: 'Reanudaste tu suscripción al',
  subscription_resume_failed: 'No se pudo reanudar tu suscripción',
  of_succes: 'de éxito',
  look_faq: 'Ver preguntas frecuentes',
  look_tutorials: 'Ver tutoriales',
  dont_unsuscribe_copywrite:
    'Recuerda que 9 de 10 pacientes prefieren Almendra. Probablemente uno de los motivos por los que te eligieron fue que les ofrecías un App para seguir el plan alimenticio. Si Almendra te ayuda a tener un paciente más, se paga solo.',
  what_would_you_add: 'Por favor cuéntanos qué te gustaría que agreguemos',
  cancel_susbcription_min_char: 'Ingresa al menos 10 caracteres',
  send_and_get_discount: 'Enviar y obtener {{name}}% de descuento',
  cancel_subscription_badSupport:
    'Últimamente hemos tenido una alta demanda y sentimos que hayas pasado por malos momentos. Podemos incluirte en la lista de soporte premium para evitar que vuelvas a pasar por eso.',
  activate_premium_support: 'Activar soporte Premium',
  premium_support_activated: '¡Listo! Soporte Premium activado',
  change: 'Cambiar',
  change_subscription_question: '¿Deseas cambiar de tu plan de suscripción？',
  yes_cancel: 'Sí, cancelar',
  cancel_recurring_subscription: '¿Deseas cancelar tu suscripción?',
  resume: 'Reanudar',
  yes_resume: 'Sí, reanudar',
  resume_recurring_subscription: '¿Deseas reanudar tu suscripción?',
  resume_recurring_subscription_details: 'Solicitaste la cancelación de tu plan. La cancelación está programada para el {{- date, DD/MM/YYYY }}.',
  cancel_subscription: 'Cancelar suscripción',
  why_cancel_subscription: '¿Por qué deseas cancelar tu suscripción?',
  reason_hard_to_use: 'Muy difícil de usar',
  reason_too_expensive: 'Precio muy elevado',
  reason_not_much_info: 'Falta de información sobre temas de nutrición',
  reason_bad_support: 'Mal soporte',
  reason_not_attending_patients: 'Actualmente no estoy atendiendo pacientes',
  we_could_fix_that: 'Podemos solucionar eso',
  how_can_we_improve: '¿Cómo podemos mejorar?',
  leave_your_opinion: 'Escribe tu opinión aquí',
  give_us_time:
    'Danos un tiempo para mejorar, tu comentario ya ha sido recibido por el equipo de experiencia de usuario y empezará a trabajar en ello cuanto antes. Mientras tanto, te regalamos {{discount}} % de descuento en tu siguiente mes si te quedas',
  churn_discount_accepted: '¡Listo! Descuento aceptado',
  obtain_churn_discount_and_stay: 'Obtener {{discount}} % y quedarme en Almendra',
  cancel_subscription_warning:
    'Recuerda que una vez anulada tu suscripción no podrás aprovechar las características de Almendra. Luego de 6 meses de estar desuscrito procederemos a eliminar todos los datos asociados a tu cuenta.',
  cancel_subscription_apologize: 'Te pedimos perdón si no pudimos satisfacer tus necesidades.',
  cancel_subscription_last_words: 'No dudes que mejoraremos y esperamos encontrarnos nuevamente en el futuro.',
  want_to_keep_subscription: 'Deseo mantener mi suscripción',
  // PopupEditAndDeleteIncome
  revenue_register_success: 'Ingreso registrado exitosamente',
  revenue_register_error: 'Hubo un problema registrando este ingreso',
  revenue_elimination_question: '¿Deseas borrar este ingreso?',
  revenue_elimination_success: 'Ingreso eliminado exitosamente',
  revenue_elimination_error: 'Hubo un problema eliminando este ingreso',
  edit_extra_revenue: 'Editar ingreso extra',
  delete_revenue: 'Eliminar ingreso',
  revenue_name: 'Nombre del ingreso',
  add_revenue_name: 'Ingresa el nombre del ingreso',
  obesity_webinar_example: 'Ej: Taller sobre obesidad',
  revenue_amount: 'Monto del ingreso',
  add_revenue_ammount: 'Ingresa el monto del ingreso',
  revenue_category: 'Categoría del ingreso',
  select_revenue_category: 'Selecciona la categoría del ingreso',
  webinars_example: 'Ej: Talleres',
  webinars: 'Talleres',
  talks: 'Charlas',
  product_sales: 'Venta de productos',
  salary: 'Sueldo',
  bonifications: 'Bonificaciones',
  comissions: 'Comisiones',
  other: 'Otros',
  revenue_date: 'Fecha del ingreso',
  select_revenue_date: 'Selecciona la fecha del ingreso',
  revenue_description: 'Descripción del ingreso',
  add_revenue_description: '(Opcional) Agrega una descripción del ingreso',
  'DD/MM/YYYY': 'DD/MM/YYYY',
  // PopupEditAndDeleteExpense
  each: 'Cada',
  expense_update_success: 'Gasto actualizado exitosamente',
  expense_update_error: 'Hubo un problema actualizando este gasto',
  expense_elimination_question: '¿Deseas eliminar este gasto?',
  expense_elimination_success: 'Gasto eliminado exitosamente',
  expense_elimination_error: 'Hubo un problema eliminando este gasto',
  edit_one_expense: 'Editar solo este gasto',
  edit_more_expense: 'Editar todos los gastos',
  delete_expense: 'Eliminar gasto',
  expense_name: 'Nombre del gasto',
  add_expense_name: 'Ingresa el nombre del gasto',
  rent_example: 'Ej: Alquiler',
  expense_amount: 'Monto del gasto',
  add_expense_ammount: 'Ingresa el monto del gasto',
  expense_category: 'Categoría del gasto',
  select_expense_category: 'Selecciona la categoría del gasto',
  work_tools_example: 'Ej: Accesorios de trabajo',
  rent: 'Alquiler',
  secretary: 'Asistente',
  marketing: 'Marketing',
  software: 'Software',
  work_tools: 'Accesorios de Trabajo',
  expense_date: 'Fecha del gasto',
  initial_expense_date: 'Fecha de inicio gasto',
  select_expense_date: 'Selecciona la fecha del gasto',
  is_expense_recurrent: '¿Es un gasto recurrente?',
  recurrrency: 'Recurrencia',
  select_expense_recurrency: 'Marca la recurrencia del gasto',
  expense_description: 'Descripción del ingreso',
  expense_end_date: '¿El gasto tiene fecha de fin?',
  select_expense_end_date: 'Selecciona la fecha fin del gasto',
  select_a_period: 'Selecciona un periodo',
  type_a_number: 'Ingresa un número',
  edit_expense: 'Editar gasto',
  edit_expenses: 'Editar gastos',
  // PopupNewIncome
  add_extra_revenue: 'Agregar ingreso extra',
  // AddEquivalentsGroup
  select_at_least_one_equivalent_group: 'Por favor, selecciona al menos un alimento del grupo de equivalencias.',
  select_at_least_one_equivalent_group_proportion: 'Por favor, ingresa la proporción para agregar el grupo de equivalencias seleccionado.',
  add_equivalents_group_action: 'Agregar grupo de equivalencias',
  add_equivalents_group: 'Agrega grupo de equivalencias',
  select_or_add_equivalents_group: 'Selecciona o escribe el grupo de equivalencias',
  calories_and_macros_average: 'Promedio de calorías y macronutrientes',
  no_description: 'Sin descripción',
  change_proportion: 'Cambiar proporción',
  have_not_created_equivalents: '¡Aún no creas equivalencias!',
  creating_equivalents_group_first_indication: 'Para crear tu primer grupo de equivalencias, haz click en <1>Alimentos</1> en la barra lateral.',
  creating_equivalents_group_second_indication: 'Luego, haz click en el botón <1>Crear equivalencia</1>',
  creating_equivalents_group_third_indication: 'Después, elige el nombre de tu primera equivalencia y agrega los alimentos que prefieras.',
  creating_equivalents_group_fourth_indication: 'Cuando termines, vuelve a hacer click en este botón y podrás agregarla en este plan alimenticio.',
  gram_w_count: 'gramo',
  gram_w_count_other: 'gramos',
  ounce_w_count: 'onza',
  ounce_w_count_other: 'onzas',
  // EquivalentsGroups
  my_equivalents: 'Mis equivalencias',
  cenan_equivalents: 'Equivalencias del CENAN',
  smae_equivalents: 'Equivalencias del SMAE',
  // BMIChart
  type_3_obesity: 'Obesidad Tipo 3',
  type_2_obesity: 'Obesidad Tipo 2',
  type_1_obesity: 'Obesidad Tipo 1',
  // ExchangeGroupDisplay
  select_at_least_one_meal: 'Selecciona al menos 1 comida para guardar los cambios',
  at_least_one_food: 'Selecciona al menos 1 alimento para guardar los cambios',
  meal_cant_be_unmarked: 'Esta comida no se puede desmarcar',
  select_consumables_food: 'Selecciona qué alimentos puede consumir el paciente en esta comida',
  meal_from_group_selected_for_food: ' Estos son los alimentos de este grupo que fueron seleccionados para esta comida',
  hide_all: 'Ocultar todos',
  apply_changes_in: 'Aplicar cambios en',
  selected_w_count: 'seleccionado',
  selected_w_count_other: 'seleccionados',
  // ExchangeMealPlanTargets
  delete_equivalents_group_error: 'No se pudo eliminar el grupo de intercambios',
  delete_equivalents_group_question: '¿Deseas eliminar este grupo de intercambios?',
  delete_equivalents_group_from_day: 'Se eliminará este grupo de intercambios del "<3>{{dayName}}</3>"',
  delete_meal_question: '¿Deseas eliminar esta comida?',
  delete_meal_from_day: 'Todo el contenido de esta comida se eliminará del "<3>{{dayName}}</3>"',
  equivalents_group_not_assigned: 'No has asignado grupos de intercambio para este día',
  // GroceriesListPDF
  mark_the_groceries_list_0: '¡Marca con un',
  mark_the_groceries_list_1: 'los alimentos que vayas comprando!',
  recipe_ingredients_included: '*Los ingredientes de la siguiente receta se encuentran incluidos en la lista de compras',
  recipe_ingredients_included_other: '*Los ingredientes de las siguientes recetas se encuentran incluidos en la lista de compras',
  week_mayus: 'Semana',
  selected_weeks: 'Semanas 1 al {{weeks}}',
  // GroceriesListTable
  was_grouped: 'Fue agrupado',
  with_comments: 'Con comentarios',
  not_visible: 'No visible',
  edit_item_warning: 'Para editar otro ítem, primero termina editando el actual o cancela la edición',
  save_item_warning: 'Ingresa el nombre, la cantidad y la unidad del ítem',
  select_at_least_two_items: 'Selecciona al menos dos ítems para poder agruparlos',
  select_new_items_to_group: 'Para agrupar, selecciona ítems que no hayan sido agrupados anteriormente',
  delete_all_groceries_list_comments: '¿Deseas eliminar todos los comentarios de esta lista de compras?',
  delete_all_comments: 'Eliminar todos los comentarios',
  reset_groceries_list: 'Restablecer la lista de compras',
  delete_groceries_list: 'Eliminar la lista de compras',
  complete_groceries_list_group_info: ' Completa la información de esta agrupación',
  item_was_grouped: 'Este ítem fue agrupado',
  item_has_comments: 'Este ítem contiene comentarios',
  item_hidden: 'Este ítem no será mostrado',
  groceries_comment_example: 'Ej: Puedes comprarlo en bodegas',
  item_composed_by: 'Ítem compuesto por',
  original_item: 'Ítem original',
  ungroup: 'Desagrupar',
  hide_selected_items: 'Ocultar los ítems selecionados de la lista de compras',
  show_selected_items: 'Mostrar los ítems seleccionados en la lista de compras',
  group_selected_items: 'Agrupar los ítems seleccionados en la lista de compras',
  hide: 'Ocultar',
  group_together: 'Agrupar',
  items_total: 'Total de items',
  // MealPlanDays
  no_foods_found_this_day: 'No se encontraron otras comidas con alimentos para este día.',
  go_to: 'Ir al',
  // ToggleHeaderNutrientAnalysis
  hide_extra_nutrient: 'Ocultar nutriente extra',
  show_extra_nutrient: 'Mostrar nutriente extra',
  // MealPlanFoodsTable
  copy_food_or_group: '¿Deseas copiar solo este alimento o todo el grupo？',
  copy_all_group: 'Todo el grupo',
  copy_just_one_food: 'Solo este alimento',
  food_quantity_empty: 'La cantidad del alimento no puede estar vacía',
  food_name_empty: 'El nombre del alimento no puede estar vacío',
  // MealPlanTargets
  how_many_meals: '¿Cuántas comidas tendrá?',
  meals_number_card_title_3: '3 comidas',
  meals_number_card_description_3: 'Desayuno, almuerzo, cena',
  meals_number_card_title_4: '4 comidas',
  meals_number_card_description_4: 'Desayuno, almuerzo, cena, snack',
  meals_number_card_title_5: '5 comidas',
  meals_number_card_description_5: 'Desayuno, media mañana, almuerzo, media tarde, cena',
  all_weekdays: 'Lunes a Viernes',
  delete_specific_meal_question: 'Deseas Borrar {{mealName}} ?',
  delete_specific_meal_confirmation:
    'Realiza esta acción si quieres que el paciente no tenga {{mealName}} este día. Al confirmar, los alimentos, comentarios y tiempo de comida serán borrados y no podrán ser recuperados.',
  copy_from_specific_day_question: 'Copiar desde el día {{dayName}} ?',
  copy_from_specific_day_confirmation:
    'Se reemplazará todo el contenido actual del día {{dayName1}} por el contenido del {{dayName2}}. Esta acción es irreversible.',
  add_free_days_question: '¿Quieres agregar días libres?',
  copy_in_all_days_question: '¿Copiar este día en todos los días?',
  micronutrients_recommendations_warning:
    'Al usar alimentos que no pertenezcan a la USDA, los resultados podrían tener un grado de distorción. Recomendaciones según el Institute of Medicine de EEUU.',
  birthday_to_see_recommendations: 'Para poder ver las recomendaciones, agrégale una fecha de nacimiento al paciente',
  copying_meal_1: 'Copiando comida',
  re_add_to_free_day_0: 'En caso quieras volver a agregar comidas a este día,',
  re_add_to_free_day_1: 'haz click en <1>Anterior</1> y ponle un objetivo calórico al día',
  this_food_has_different_macros: 'Esta comida contiene {{macros}}, pero le asignaste un objetivo de 0 gramos',
  day_without_objectives_per_meal: 'Esta día no contiene objetivos por comida. Para asignarlos regresa al paso anterior.',
  values_have_been_rounded:
    'Los valores han sido redondeados para una mejor visibilidad. Por eso podría parecer a simple vista que no se cumpla, por ejemplo, que 1gr de proteína equivale a 4kcal',
  meal_without_foods: 'Esta comida no contiene alimentos',
  meal_comment_example: 'Ej: Mezcla el yogurt con la avena',
  to_the_last: 'Al final',
  position_to_move_meal_question: '¿A qué posición deseas mover esta comida?',
  move_to_other_position: 'Mover de posición',
  at_the_beggining: 'Al principio',
  prior_to: 'Antes de',
  so_far_any_other_day_has_foods: 'Por ahora ningún otro día tiene alimentos',
  copy_to_all_days: 'Copiar a todos los días',
  pin: 'Anclar',
  unpin: 'Desanclar',
  graph: 'Gráfico',
  go_back_to_step_2:
    'Regresa al paso de los objetivos calóricos haciendo click en Anterior y restablece el objetivo calórico del día que quieras que sea libre.',
  day_copy_to_other_day:
    'Se copiará el contenido de este día {{dayName}} en el resto de días. En caso existiera información en los otros días, esta será completamente reemplazada. En caso el plan tenga días libres, estos no se verán afectados. Esta acción es irreversible.',
  delete_day_content_confirmation: 'Se eliminará todo el contenido actual del día {{dayName}}. Esta acción es irreversible.',
  change_to_graph: 'Cambiar a gráfico',
  change_to_table: 'Cambiar a table',
  see_weekly_summary: 'Ver resumen semanal',
  see_day: 'Ver el {{dayName}}',
  seven_model_days_tops: 'Este tipo de plan acepta 7 días modelo como máximo',
  model_day: 'Día Modelo',
  loading_mealPlan: 'Cargando Plan Alimenticio...',
  reset_day_objectives: '¿Restablecer el objetivo para el {{dayName}}?',
  everyday: 'Todos los días',
  tuesday_thursday_short: 'Mar-Jue',
  monday_wednesday_friday_short: 'Lun-Mie-Vie',
  select_boxes_for_objectives: 'Selecciona las casillas para establecer objetivos en cada día',
  new_day: 'Agregar día',
  save_objective: 'Guardar objetivo',
  save_objective_for_a_day: 'Guardar objetivo para el día',
  save_objective_for_some_days: 'Guardar objetivo para el día',
  save_objective_for_all_days: 'Guardar objetivo para todos los días',
  meal_plan_targets_pending_title: 'Selecciona los días deseados de la tabla para establecer el objetivo nutricional respectivo',
  meal_plan_targets_pending_description:
    'Podrás ir al siguiente paso una vez completes esta sección. Los días que dejes sin llenar serán considerados días libres.',
  type_caloric_objective: 'Ingresa el objetivo calórico',
  bmr_formula: 'Fórmula para calcular el metabolismo basal',
  measurements_tied_to_an_appointment: 'Medidas asociadas a la consulta del',
  leanBodyMass_formula_restriction: 'Necesitas la masa magra del paciente para aplicar esta fórmula',
  activity_level: 'Nivel de actividad',
  percentage_to_vary_tdee: '¿En qué porcentaje deseas aumentar/disminuir el GET?',
  mealPlan_not_tied_to_country_0: 'Este plan alimenticio no está asociado a una consulta.',
  mealPlan_not_tied_to_country_1:
    'Para que estimemos el requerimiento calórico de este paciente, necesitas haber llenado los siguientes campos antes de crear el plan alimenticio',
  mealPlan_not_tied_to_country_2: 'Para que estimemos un número de calorías para este paciente, necesitas llenar como mínimo los campos',
  daily_activity: 'Actividad diaria',
  macronutrients_distribution: 'Distribución de macronutrientes',
  amdr_recommended_protein: 'El porcentaje de proteínas en la dieta recomendado por la AMDR para personas adultas (18 años) es de 10% a 35%',
  target_result: 'Resultado',
  amdr_recommended_carbs: 'El porcentaje de carbohidratos en la dieta recomendado por la AMDR para personas adultas (18 años) es de 45% a 65%',
  amdr_recommended_fats: 'El porcentaje de grasas en la dieta recomendado por la AMDR para personas adultas (18 años) es de 20% a 35%',
  distribute_by_mealTime: 'Distribuir por tiempo de comida',
  put_daily_macronutrients_first: 'Coloca los objetivos de macronutrientes diarios primero',
  children_growth_oms_source: 'Fuente: Patrones de crecimiento infantil de la OMS (Organización Mundial de la Salud)',
  additional_information: 'Información complementaria',
  bmi_graph: 'Gráfico IMC',
  bmi_with_prePregnancy_weight: 'El IMC se calcula con el peso pre-gestional',
  somatotype: 'Somatotipo',
  somatotype_requirements: 'Se necesitan agregar diferentes pliegues y medidas para poder visualizar el somatotipo de {{name}}',
  measurements_requirements: 'Se necesitan las siguientes medidas',
  endomorph: 'Endomorfo',
  ectomorph: 'Ectomorfo',
  mesomorph: 'Mesomorfo',
  past_objectives: 'Objetivos Pasados',
  patient_without_past_objectives: 'Este paciente todavía no tiene objetivos pasados',
  section_for_objectives_history:
    'En esta sección podrás visualizar todo el historial de objetivos de {{name}} en sus diferentes planes de alimentación',
  dietetic_register: 'Registro Dietético',
  dietetic_register_shown_because_of_no_mealPlan: 'El paciente no cuenta con un plan alimenticio pasado, por eso se enseñará su registro dietético',
  past_mealPlan_without_objectives: 'El plan alimenticio pasado no tiene los objetivos calóricos completados',
  comments_or_conclusions: 'Comentarios o conclusiones',
  patient_without_mealPlan_and_register: 'no tiene un plan alimenticio pasado y tampoco tiene un registro dietético',
  blocked_nutrients_notification:
    'Como tienes bloqueados dos nutrientes ({{stateName}})  también ha sido bloqueado el tercer nutriente ({{lockedMacro}}), dado que ya no se hubiese podido modificar. Puedes hacer click en cualquier candado para desbloquear el nutriente.',
  prots_and_carbs: 'Proteínas y Carbohidratos',
  prots_and_fats: 'Proteínas y Grasas',
  carbs_and_fats: 'Carbohidratos y Grasas',
  macro_by_kg: 'por {{weightSystem}} de peso. ({{weight}} {{weightSystem_abv}})',
  starts_past: 'inició',
  view_bmi_chart_requirements: 'Se necesita agregar peso y altura en la consulta asociada a este plan alimenticio {{-date}} para visualizar el IMC',
  specific_mealplan_date: 'Datos del plan alimenticio que {{starts}} el {{-date}}',
  // PatientInformationDrawer
  not_registered: 'No se registró',
  see_patient_history: 'Ver Historia del paciente',
  patient_history: 'Historia del paciente',
  patient_basic_data: 'Datos básicos',
  last_appointment: 'Última consulta',
  patient_diseases_recommendations: 'Se recomienda consumir estos alimentos para pacientes con',
  appointment_objective: 'Objetivo de la consulta',
  physical_activity_level: 'Nivel de actividad física',
  wakeup_time: 'Hora de despertar',
  sleep_time: 'Hora de dormir',
  diet_type: 'Tipo de alimentación',
  number_of_foods_consumed_daily: 'Comidas consumidas al día',
  no_comments_found: 'No se encontraron comentarios para esta consulta',
  select_a_measurement_to_view: 'Selecciona una medida para ver los datos',
  see_patient_info_question: '¿Deseas ver toda la información del paciente?',
  //PopupDailyNutrients
  daily: 'Diario',
  undo_free_day_to_view_macros: 'Para visualizar nutrientes en este día primero conviértelo en un día no libre',
  nutrients_visualization: 'Visualización de nutrientes',
  // PopupAddRecipesToMealPlan
  recipe_already_selected: 'Esa receta ya ha sido seleccionada',
  add_recipes: 'Agregar recetas',
  search_and_add_recipes_to_mealPlan:
    'Busca y selecciona las recetas que hayas ingresado en el plan alimenticio para que aparezcan sus ingredientes e instrucciones en el PDF y en el App del paciente.',
  search_1: 'Búsqueda',
  recipe_example: 'Ej: Ensalada de quinua',
  no_more_results: 'No hay más resultados',
  selected_1: 'Seleccionadas',
  no_recipe_selected: 'No has seleccionado ninguna receta',
  // PopupAssignMultipleHours
  toggle_free_day_to_select: 'Si quieres seleccionar el día {{dayName}}, primero cámbialo a un día no libre.',
  add_time_and_select_days: 'Ingresa la hora y selecciona los días',
  asign_hours: 'Asignar horas',
  asign_hours_to_meal: 'Asignar hora a la comida {{mealName}}',
  select_all: 'Seleccionar todos',
  clear_selection: 'Limpiar selección',
  at_what_time_will_it_be: '¿A qué hora será?',
  assign_multiple_hours_fromEMPT:
    'Ingresa la hora que deseas asignar {{mealPronoun}} <3>{{mealName}}</3> del <3>{{dayIndex}}</3>. Si esta comida ya contiene una hora, esta será reemplazada. Si quieres que esta comida no sea a una hora determinada, deja el campo vacío.',
  assign_multiple_hours:
    'Ingresa la hora y selecciona los días a los cuales deseas asignar esa hora. Si alguno de esos días ya contiene una hora, esta será reemplazada.',
  // PopupMeal
  here_will_be_the_foods: 'Aquí aparecerán los alimentos que agregues en esta comida',
  day_without_meal_1: '¡Este día no tiene esta comida!',
  day_without_meal_add_food: 'Para que esté, simplemente agrégale un alimento',
  day_without_meal_objectives: 'Este día no contiene objetivos por comida. Para asignarlos regresa al paso anterior.',
  without_meal_objectives: 'Sin obj. por comidas',
  percentage_of_day: '% del día',
  move_meal: 'Mover comida',
  to_day: 'Al día',
  to_food: 'A la comida',
  cannot_move_meal_free_day: 'No puedes mover una comida en un día libre',
  meal_foods_will_be_replaced: 'En caso la comida de destino tenga alimentos, serán reemplazados por los de esta comida',
  // SearchbarDatabaseFilter
  my_createdFoods: 'Mis alimentos creados',
  my_favoriteFoods: 'Mis alimentos favoritos',
  system_recipes: 'Recetas de Almendra',
  other_recipes: 'Recetas de otros usuarios',
  loading_filters_error: 'Tuvimos un problema al cargar los filtros. Por favor, refresca la página e inténtalo nuevamente',
  select_one_database_warning: 'Debes seleccionar por lo menos una base de datos',
  select_one_database_error: 'No has seleccionado alguna base de datos',
  showing_only_favorite_foods: 'Mostrando solo alimentos favoritos',
  show_favorite_foods: 'Mostrar alimentos favoritos',
  show_all_foods: 'Ver todos los alimentos',
  search_foods_when_writing: 'Buscar alimentos al escribir',
  search_all: 'Buscar todos',
  current_filter: 'Filtro actual',
  database_filters: 'Filtros de bases de datos',
  select_filter_method: 'Selecciona el método de filtro',
  search_foods_when_writing_description: 'Ingresa el alimento que desees para buscarlo en las bases de datos seleccionadas.',
  show_all_foods_description:
    'Visualiza todos los diferentes alimentos de las bases de datos seleccionadas en la misma tabla sin necesidad de ingresar el alimento buscado',
  select_at_least_one_database: 'Selecciona al menos una base de datos',
  how_does_it_work: '¿Cómo funciona?',
  // MealPlanTemplatePendingTable
  of_1: 'de',
  no_pending_templates: 'No tienes plantillas pendientes por terminar',
  download_csv: 'Descargar CSV',
  print: 'Imprimir',
  show_hide_columns: 'Mostrar/Ocultar Columnas',
  delete_selection: 'Eliminar selección',
  row_s: 'fila(s)',
  // OnboardingScheduleCall
  schedule_training: 'Agendar entrenamiento',
  schedule_your_virtual_training: 'Agenda tu reunión virtual',
  // BabyFields
  gestation_and_birth_data: 'Datos del nacimiento y la gestación',
  fields_only_for_babies: 'Estos campos solo aparecerán para pacientes de 4 años o menos',
  birthWeight: 'Peso al nacer',
  birthHeight: 'Altura al nacer',
  gestational_age: 'Edad gestacional',
  extra_comments_about_babies: 'Comentarios adicionales sobre la gestación y el niño',
  extra_comments_babies_placeholder: 'Ej: Se le dio únicamente lactancia hasta los 6 meses',
  //ChangeMailInput
  mail_example: 'Ej: luciana@gmail.com',
  invalid_mail: 'Esta dirección de correo figura como inválida, por favor comunícate con el paciente',
  pending_mail_verification: 'Email pendiente de verificación',
  patient_mail_verified: 'Email verificado',
  pending_mail_change: 'Cambio de email pendiente de confirmación',
  pending_mail_change_description: 'El paciente todavía no acepta el cambio de email enviado a',
  undo_change: 'Cancelar cambio',
  patient_already_on_app: 'El paciente ha ingresado al App',
  patient_hasnt_entered_to_app: 'El paciente todavía no ingresa al App',
  delete_patient_mail_question: '¿Deseas eliminar el email de este paciente?',
  delete_patient_mail_in_use:
    'Este email está siendo utilizado por el paciente para acceder a su App. Al eliminar este email, el paciente no podrá ver su información a partir de esta fecha pero igual podrá acceder al App para ver su información histórica.',
  change_patient_mail_question: '¿Deseas cambiar el email de este paciente?',
  change_patient_mail_question_content:
    'Este paciente ha ingresado al App con el correo <1>{{mail}}</1>. <3>Por seguridad, enviaremos un enlace a <5>{{newMail}}</5> para que el paciente confirme el cambio de email.</3>Mientras tanto, el paciente podrá seguir ingresado al App con el anterior email. Todos los correos serán enviados a <7>{{mail}}</7> hasta que el paciente verifique el cambio. Aparecerá un ícono al costado del email indicando que el paciente todavía no ha confirmado este cambio.',
  change_not_verified_patient_mail:
    'Anteriormente enviaste el correo de acceso al App al paciente pero este aún no ha ingresado al App. Al aceptar, se invalidarán los correos enviados para que acceda a su cuenta.',
  resend_patient_verification_mail_question: '¿Deseas volver a enviarle el correo de acceso al App?',
  resend_patient_verification_mail_body: 'Anteriormente enviaste la invitación al App a este paciente pero este aún no ha ingresado al App.',
  resend_patient_verification_new_mail_question: '¿Deseas volver a enviar el correo de acceso al App a este nuevo email?',
  resend_patient_verification_new_mail_body_1: 'Anteriormente enviaste la invitación al App a este paciente a {{mail1}}',
  resend_patient_verification_new_mail_body_2: ', se enviará la invitación al nuevo email ingresado {{mail2}}.',
  // ChartMeasurements
  click_in_add_measurements: 'Haz click en <1>Registrar medidas</1> para ingresar las medidas de esta consulta.',
  select_or_add_measurement: 'Selecciona o escribe la medida',
  all_time_high_n: 'Máximo\nHistórico',
  all_time_low_n: 'Mínimo\nHistórico',
  total_variation_n: 'Variación\nTotal',
  last_measurement_n: 'Última\nMedida',
  empty_measurement: 'Sin registros',
  patient_without_specific_measurement:
    'Este paciente aún no tiene registros de esta medida. Para visualizar el gráfico y los valores relevantes, recuerda medirla la próxima consulta.',
  to_register_a_measurement_go_back:
    'Para ingresar una medida, primero regresa a la consulta haciendo click en el temporizador de la barra lateral. Luego haz click en Registrar medidas.',
  // ChartMeasurements
  limit: 'Límite',
  hours_per_week_detail: 'Horas por semana',
  // PatientMealPlans
  about_to_edit_mealPlan_date: 'Estas a punto de editar la fecha de un plan alimenticio y podría traer repercusiones',
  about_to_edit_mealPlan_date_confirmation:
    'Si editas la fecha de este plan alimenticio, este paciente pasará a ser un paciente activo. ¿Deseas editar la fecha de igual manera?',
  start_date_successfully_edited: 'Fecha de inicio editada con éxito',
  delete_mealPlan_question: '¿Deseas eliminar este plan alimenticio?',
  mealPlan_error_title: 'Ocurrió un error al generar el Plan Alimenticio',
  plan_name: 'Nombre del Plan',
  mealPlan_not_tied_to: 'Plan no asociado a',
  mealPlan_ended: 'Dieta concluida',
  appointments_process_mayus: 'En proceso',
  started_today: 'Inició hoy',
  started: 'Inició',
  starts: 'Inicia',
  foods_filling: 'Llenado de alimentos',
  in_process_blank_mealPlan: 'En proceso - Texto libre',
  pending_finish: 'Pendiente terminar',
  caloric_objectives: 'Objetivos calóricos',
  recently_created: 'Recién creada',
  view_pdf: 'Ver PDF',
  theres_an_appointment_ongoing: '¡Hay una consulta en curso!',
  i_want_to_start_now: 'Quiero empezarlo ahora',
  ill_do_it_later: 'Lo empezaré después',
  start_filling: 'Empezar a llenar',
  start_mealPlan_after_appointment:
    'Puedes realizar este plan alimenticio después de la consulta. Cuando añades un plan alimenticio, automáticamente se etiqueta como pendiente.',
  finish_editing: 'Terminar de editar',
  mealPlan_1: 'plan alimenticio...',
  patient_without_mealPlans_yet: 'Todavía no has asignado algún plan alimenticio para este paciente',
  adding: 'Añadiendo',
  deleting: 'Eliminando',
  mealPlan_attached: 'Este plan está adjuntado en PDF',
  mealPlan_with_workout: 'Este plan tiene una rutina incluida',
  mealPlan_with_no_appointment: 'Plan alimenticio no asociado a una consulta',
  mealPlan_linked_with_appointment: 'Plan alimenticio asociado a la consulta del día',
  // PatientsList
  patients: 'Pacientes',
  loading_patients: 'Cargando pacientes...',
  active: 'Activo',
  inactive: 'Inactivo',
  active_patient_short_description:
    'Ha realizado por lo menos una consulta o ha tenido un plan alimenticio este mes de suscripción. Para más información ir a la sección de Suscripción dentro de Ajustes.',
  inactive_patient_short_description: 'No ha realizado ninguna consulta ni ha tenido un plan alimenticio este mes de suscripción',
  // PatientsStatDisplay
  patient_stats_display_description:
    'Calcula las consultas agendadas pero no realizadas. Si el paciente agendó una consulta en marzo y otra en abril, pero solo fue a la de marzo, esta cifra será de 50%. Si no ha faltado a ninguna, será 100%.',
  // PendingPatientModal
  accept_patient_request_question: '¿Deseas aceptar la solicitud de este paciente?',
  requested_to_be_your_patient: 'solicitó ser tu paciente.',
  decline_pending_patient_request_description: 'El paciente desaparecerá de la lista de pacientes pendientes',
  accept_pending_patient_request_description: 'El paciente será agregado a tu registro junto con toda su información',
  reject: 'Rechazar',
  // PopupDietRecord
  diet_record_short_description: 'Esta sección es para registrar la actividad alimenticia de los pacientes en las últimas 24h/48h.',
  diet_record_first_indication: 'Empieza agregando las comidas dándole click a "Agregar comida"',
  meal_title_example: 'Ej: Desayuno',
  diet_record_comment_example: 'Ej: Pan con mantequilla, se siente lleno después de comerlo',
  meal_hour_example: '8:30am',
  add_meal: 'Agregar Comida',
  optional: 'Opcional',
  // PopupPatientTags
  add_tags: 'Agregar etiquetas',
  put_tags_to_your_patients: 'Colócale etiquetas a tu paciente según sus características',
  patient_tag_example: 'Ej: Postoperatorio',
  add_personalized_tags_description:
    'Puedes agregar etiquetas que no estén incluidas en la lista. Te recordamos que tus pacientes no podrán visualizar las etiquetas que les coloques.',
  suggestions: 'Sugerencias',
  no_tags_suggestions: 'No encontramos ninguna sugerencia de etiqueta, pero puedes agregar las que desees.',
  // PregnancyOrNursingFields
  example_short: 'Ej.',
  edit_field_warning: 'Estás a punto de editar este campo. Si lo haces, la información guardada se perderá.',
  special_conditions: 'Condiciones especiales',
  pregnancy_mayus: 'Embarazo',
  nursing_mayus: 'Lactancia',
  none_mayus: 'Ninguna',
  none: 'ninguna',
  last_menstruation_date: 'Fecha de última menstruación',
  date_with_more_than_10_months:
    'La fecha que seleccionaste tiene más de 10 meses de antiguedad, procura que sea más reciente para que la condición actual de la paciente sea de embarazada',
  special_conditions_comment_example: 'Ej: Gastritis severa al iniciar el tercer trimestre',
  special_conditions_comment_example_1: 'Ej: Los primeros meses fueron de solo lactancia, luego se añadieron fórmulas infantiles',
  system_data_will_change_with_special_condition:
    'Los cálculos del sistema, como por ejemplo los requerimientos calóricos, cambiarán, dado que la paciente está embarazada. Para calcular el BMR correctamente se necesitará añadir el peso pre-gestacional',
  currently_pregnant: 'Actualmente en embarazo',
  currently_nursing: 'Actualmente en lactancia',
  nursing_startDate: 'Inicio de la lactancia',
  nursing_duration: 'Duración de la lactancia',
  month_other: 'meses',
  // TableMeasurements
  'N/A': 'N/A',
  add_measurement_question: '¿Deseas registrar una medida?',
  change_measurement_question: '¿Deseas cambiar esta medida?',
  current_value: 'Valor actual',
  new_value: 'Nuevo valor',
  used_to_find_other_measurements: 'se utiliza para hallar otras medidas',
  empty: 'Vacío',
  other_measurements_will_be_modified: 'Otras medidas serán modificadas',
  yes_change_measurement: 'Sí, cambiar medida',
  actual_value_cannot_backup_other: 'Los valores actuales no podrán ser recuperados',
  measurements_history: 'Historial de medidas',
  search_for_a_measurement: 'Busca una medida',
  last: 'Últimas',
  patient_with_no_measurements_yet_warning: 'Todavía no has registrado medidas para este paciente',
  register_measurements_during_appointment:
    'Registra medidas durante una consulta en la sección <1>Medidas</1> (paso 2).<2/>El propósito de esta tabla es corregir algún posible error luego de que el paciente no esté contigo.<3/><4/>Haz click en este botón para ir a la sección de medidas: ',
  change_measurement_confirmation: 'Se cambiará el valor de la medida <1>{{measurementName}}</1> de la consulta del {{-date}}',
  // ExchangeMealPlanDemoPDF
  exchange_mealPlan_demo_title: 'Plan alimenticio por intercambios - DEMO',
  // CustomForm
  form_with_at_least_one_field_error: 'El formulario debe contener al menos un campo.',
  custom_form_edit_error: 'Hubo un error editando el formulario. Inténtalo más tarde.',
  custom_form_edit_success: 'Formulario editado con éxito',
  custom_form_create_error: 'Hubo un error creando el formulario. Inténtalo más tarde.',
  custom_form_create_success: 'Formulario creado con éxito',
  other_personal_data: 'Otros datos personales',
  form: 'Formulario',
  create_form: 'Crear formulario',
  setup_form_description:
    'Configura el formulario que recibirá tu paciente antes de su consulta para que lo llene. Puedes elegir los campos que desees.',
  form_name: 'Nombre del formulario',
  form_name_validator: 'Ingresa un nombre para el formulario.',
  form_name_example: 'Ej. Formulario para controles',
  add_field: 'Agregar campo',
  // ExistingPatientForm
  enter: 'Ingresar',
  forgot_password_add_code: 'No recuerdo mi contraseña, ingresar con código temporal',
  // MultipleLinksEdit
  fill_field_before_adding_new_one: 'Por favor, llena el campo anterior antes de agregar uno nuevo',
  type_a_valid_link: 'Por favor, ingresa un enlace válido',
  organize_your_links_into_one: 'Organiza tus múltiples direcciones en un solo enlace con Almendra.',
  why_is_it_useful: '¿Para qué sirve?',
  organize_your_links_into_one_description:
    'En un solo enlace podrás tener tu página de Facebook, el link de tu tienda virtual, de tu e-book, de tu página principal, etc. Cuando termines, copia el enlace generado y pégalo donde desees (por ejemplo, en la bio de tu Instagram).',
  multiple_links_example: 'Ej. Página de Facebook',
  delete_field_question: '¿Deseas borrar este campo?',
  preview_in_laptop: 'Vista previa',
  // ShowAgenda
  fill_info_to_enable_feature: 'Completa la siguiente información para habilitar esta funcionalidad:',
  and_update_this_settings: 'y actualiza estas opciones.',
  enter_to: 'Ingresa a',
  invalid_credentials: 'Las credenciales son inválidas',
  credentials_verify_error: 'Las credenciales son inválidas',
  slot_occupied_while_waiting: 'El horario seleccionado fue ocupado mientras realizabas la reserva. Elige otro horario.',
  fill_all_data_for_scheduling: 'Completa los datos para realizar la reserva',
  slot_occupied_while_waiting_error: 'Otra persona reservó estas horas mientras confirmabas la reserva. Inténtalo nuevamente',
  mail_is_already_registered_error: 'El correo electrónico utilizado se encuentra registrado. Intenta con otro correo electrónico.',
  transaction_failed_message: 'No se pudo realizar la transacción',
  transaction_failed_message_next_steps:
    'Verifica que hayas ingresado los datos correctamente de la tarjeta, que esta tenga saldo disponible y se encuentre habilitada para pago online. Contáctate con tu banco para mayor información.',
  booking_failed: 'La reserva no se pudo realizar',
  booking_failed_description: 'Parece que hubo un problema al reservar la cita. Por favor, refresca la página y vuelve a intentarlo.',
  mail_already_registered_as_patient: 'Este correo ya se encuentra registrado como Paciente.',
  mail_not_in_expert_database: 'Este correo no se encontró en los registros de tu nutricionista',
  verify_your_mail: 'Verifica tu correo',
  wrong_code: 'Código incorrecto',
  code_verified_success: 'Correo verificado con éxito',
  type_the_code_sent_to_mail: 'Ingresa el código enviado a ',
  no_pending_recipes: 'No tienes recetas pendientes por completar',
  select_healthPlan: 'Selecciona el paquete',
  healthPlan_selection: 'Selección el paquete',
  fill_in_your_data: 'Completa tus datos',
  confirm_your_booking: 'Confirma tu reserva',
  make_the_payment: 'Realiza el pago',
  check_your_mail_and_confirm_booking: 'Revisa tu correo y confirma la cita',
  booking_scheduled_succesfully: 'Cita agendada con éxito',
  aprox_minutes: 'minutos aprox',
  go_to_my_account: 'Ir a mi cuenta',
  autorization: 'Autorización',
  im_a_patient: 'Soy paciente',
  im_not_a_patient_yet: 'Todavía no soy paciente',
  booking: 'Reserva',
  identification: 'Identificación',
  you_have_this_healthPlan_in_progress: 'Actualmente tienes el siguiente paquete nutricional en proceso',
  healthPlan_is_all_booked:
    'Todas las consultas de tu paquete actual han sido agendadas. Podrás agendar una nueva consulta cuando se termine tu paquete actual.',
  booking_one_appointment_from_healthPlan: 'Se utilizará 1 consulta del paquete para continuar con la reserva.',
  appointment_time: 'Horario de la cita',
  fill_your_data_and_book: 'Completa tus datos y agenda tu cita',
  next_steps: 'Siguientes pasos',
  custom_form_info_for_patient_booking:
    'Recibirás un correo con un enlace para que ingreses tus hábitos alimentarios en un formulario. El propósito de este formulario es ayudarte en el logro de tus objetivos. Asegúrate de completarlo antes de la fecha de tu consulta.',
  booking_politics: 'Políticas de agenda',
  booking_politics_description: 'Por cortesía, en caso de cancelar la reserva asegúrate de comunicárselo a tu nutricionista con anticipación.',
  confirming_your_booking: 'Confirmando la reserva...',
  appointment_with: 'Consulta con',
  no_service_selected: 'No has seleccionado algún servicio',
  paypal_total: 'Total PayPal',
  pay: 'Pagar',
  pay_later: 'Pagar después',
  make_payment: 'Realizar pago',
  confirm_and_book: 'Confirmar y reservar',
  book_apointment: 'Agendar consulta',
  patient_wants_to_book: 'Eres paciente de {{name}} y quieres agendar una consulta',
  not_patient_wants_to_book: 'No eres paciente de {{name}} pero quieres agendar una consulta',
  booking_appointment_in_healthplan: 'Agendando consulta {{singular}} de {{total}}',
  change_healthPlans_privacy:
    'No has cambiado la privacidad de tus paquetes nutricionales a <1>pública</1>. En <3><5>Configuración - Paquetes Nutricionales</5></3> podrás realizar estos cambios para que tus pacientes puedan ver los planes que ofreces.',
  hours_selection: 'Selección de horario',
  no_booking_communicate_with_expert:
    'Actualmente {{firstName}} no está agendando consultas por este medio, comunícate con {{firstName}} vía {{mail}}.',
  validation_and_confirmation: 'Revisión y confirmación',
  all_healthPlan_appointments_booked:
    'Todas las consultas de tu paquete actual han sido agendadas. Podrás agendar una nueva consulta cuando se termine tu paquete actual.',
  // PopupOnboarding
  congrats_for_choosing_us: 'Felicitaciones por elegir Almendra',
  what_are_you_looking_for: '¿Qué es lo que buscas en Almendra?',
  tell_us_about_you: 'Ahora, cuéntanos un poco de ti',
  woman: 'Mujer',
  man: 'Hombre',
  what_are_you_looking_for_example:
    '¿Hacer planes alimenticios personalizados más rápido? ¿Darle una mejor experiencia a tus pacientes? ¿Tener tu información más ordenada?',
  nationality_1: 'Nacionalidad',
  select_nationality: 'Por favor, selecciona tu nacionalidad.',
  select_birthDate: 'Por favor, selecciona tu fecha de nacimiento.',
  select_phone: 'Por favor, ingresa tu número de teléfono.',
  select_valid_phone: 'Por favor, ingresa un número de teléfono válido.',
  select_to_continue: 'Por favor selecciona una de las opciones para continuar',
  im_an_expert: 'Soy nutricionista/nutriólogo/coach',
  im_a_student: 'Soy estudiante de nutrición',
  i_want_a_diet: 'Quiero una dieta',
  easy_mealPlans: 'Crear planes alimenticios más fácil',
  more_order: 'Tener más orden en las historias de pacientes',
  better_experience: 'Darle una mejor experiencia a mis pacientes',
  save_time: 'Ahorrar tiempo en mi día a día',
  at_least_5_characters: 'Por favor ingresa al menos 5 caracteres',
  identify_yourself_options: '¿Con cuál de estas opciones te identificas?',
  enter_university: 'Ingresa tu universidad y carrera',
  enter_university_placeholder: 'Ej. Carrera de Nutrición y Dietética en la Universidad Católica',
  what_are_you_looking_for_mealPlan: '¿Qué buscas en tu plan alimenticio?',
  what_are_you_looking_for_mealPlan_placeholder: 'Ej: Que no sea muy caro, flexibilidad de alimentos, recetas, etc.',
  expert_with_default_food_sources: 'Como tu país de residencia es <1>{{country}}</1>, agregaremos automáticamente las siguientes bases de datos:',
  expert_without_default_food_sources:
    'Todavía no tenemos incorporada la tabla de composición alimentaria de tu país, por mientras ponemos a tu disposición la tabla de la <3>USDA</3>',
  onboarding_submit_error: 'Ups! Hubo un problema. Por favor, refresca la página e inténtalo nuevamente',
  // PopupCardComission
  why_we_ask_this: '¿Por qué preguntamos esto?',
  importance_regarding_card_comissions: 'Es de suma importancia considerar la comisión de las tarjetas de crédito o débito por estos motivos',
  importance_regarding_card_comissions_reason_1: 'En realidad, es dinero que no recibes.',
  importance_regarding_card_comissions_reason_2: 'A la hora de visualizar tus finanzas o estadísticas, verás solo el dinero que te corresponde',
  what_is_average_comission: "¿Qué significa 'comisión promedio'?",
  average_comission_example:
    'Imagina que utilizas dos POS, uno por cada marca de tarjeta. La comisión de un POS es de 3%, mientras que la del otro es de 5%. En este caso, deberás ingresar 4% en el campo.',
  // SecuritySettings
  see_more: 'Ver más',
  associated_accounts: 'Cuentas asociadas',
  linked_account: 'Cuenta vinculada',
  unlinked_account: 'Cuenta no vinculada',
  // VisualSettings
  image_succesfully_updated: 'Imagen actualizada satisfactoriamente',
  image_upload_error: 'Hubo un error al subir la imagen',
  lateral_bar: 'Barra lateral',
  upload_logo: 'Subir logo',
  delete_logo: 'Eliminar logo',
  change_text: 'Cambiar texto',
  reduce: 'Achicar',
  enlarge: 'Agrandar',
  topic: 'Tema',
  primary_color: 'Color primario',
  // ZoomLinkAccount
  zoom_unlink_title: '¿Quieres desvincular tu cuenta de Zoom con Almendra?',
  zoom_link_title: '¿Quieres vincular tu cuenta de Zoom con Almendra?',
  zoom_link_automation:
    'Al momento de agendar consultas online podrás seleccionar si deseas crear la sala con Zoom, automáticamente se enviará al paciente el enlace de la sala.',
  zoom_redirect: 'Te redireccionaremos a la página de Zoom para que puedas realizar esta operación.',
  // IncomeByModality
  Presencial: 'Presencial',
  'A Domicilio': 'A Domicilio',
  Online: 'Online',
  // ErrorReload
  network_error_happened: 'Ocurrió un error de conexión',
  // FoodsTable
  user_measurements: 'Medidas propias',
  other_user_measurements: 'Otras medidas caseras',
  // LikedFoods
  liked_foods_title: 'Gusto del paciente',
  disliked_foods_title: 'Disgusto del paciente',
  allergic_foods_title: 'Alergia del paciente',
  // Pal Calculation
  mets_cant_exceed_24_hours: `Las actividades con METs de cada día no pueden pasar las 24 horas`,
  mets_cant_exceed_24_hours_specific_day: `Las actividades con METs del día {{dayName}} no pueden pasar las 24 horas`,
  // MetsModal
  add_days_with_activity: 'Agrega los días en los cuales se realiza la actividad',
  add_activity_hours: 'Agrega cuántas horas y minutos dura la actividad',
  sport_lowercase: 'deporte',
  times_per_week_uppercase: 'Veces por semana',
  mets_per_hour: 'METs/hora',
  dummy_activity_description: 'Esta actividad es un comodín que te ayudará a completar las horas diarias, con un MET de 1.3',
  activities: 'Actividades',
  mets_week_avg_description:
    'Este número es el promedio general de la semana, si tienes actividades en días dispersos, no igualará a la suma exacta de las horas',
  delete_activity_question: '¿Deseas eliminar esta actividad?',
  activity_list: 'Lista de actividades',
  add_and_edit_activities:
    'Agrega y edita las actividades correspondientes al día a día del paciente, para poder calcular un nivel de actividad más preciso.',
  daily_mets: 'Total de METs por día:',
  see_added_hours: 'Ver detalle de horas agregadas',
  hide_added_hours: 'Ocultar detalle de horas agregadas',
  no_activity_added_yet: 'Todavía no agregas actividades para este paciente.',
  add_activity: 'Agregar actividad',
  add_activity_ph: 'Escribe alguna actividad...',
  no_activities_found: 'No se encontraron actividades para la búsqueda',
  // DeleteConfirmationModal
  delete_confirmation_modal: 'Para confirmar esta acción, escribe "borrar" en el siguiente campo',
  delete_keyword: 'borrar',
  // NoData
  no_data: 'No hay datos',
  // WaitToResendTimer
  try_again_in: 'vuelve a intentarlo en',
  resend_mail: 'Reenviar correo',
  // ServiceHoursDisplay
  modalities: 'Modalidades',
  // ServiceHoursEdit
  add_bussiness_hours: 'Añadir horario',
  drag_and_drop_business_hours: 'Haz click en cualquier celda y arrastra hacia abajo para configurar tu horario de atención.',
  generalSettings_businessHours: 'Horario de Atención',
  enter_average_duration: 'No has ingresado la duración promedio de consulta',
  at_least_one_modality: 'Debes tener al menos una de las siguientes modalidades de consulta',
  at_least_one_address: 'tener al menos 1 dirección de consultorio',
  no_modalities_available: 'No tienes modalidades de consulta disponibles',
  find_servicehours_configuration_1:
    'En <1><3>Ajustes Generales</3></1> podrás encontrar estos campos en las secciones "<5>Consultas</5>" y "<7>Dirección del consultorio</7>',
  find_servicehours_configuration_2: 'En <1><3>Ajustes Generales</3></1> podrás encontrar este campo en la sección de "<5>Consultas</5>',
  // WorkoutSelectModal
  routine_created_successfully: 'Rutina creada con éxito',
  assign_workout: 'Asignar rutina',
  assign_workout_routine: 'Asignar rutina de entrenamiento',
  select_template: 'Selecciona una plantilla',
  use_workout_template: 'Usar plantilla de rutina',
  duplicate_workout: 'Duplicar rutina',
  delete_workout: 'Eliminar rutina',
  workout: 'Rutina',
  delete_ongoing_appointment_question: 'Deseas eliminar la consulta en curso',
  // AppointmentMain
  back: 'Atrás',
  specific_patient_appointment_deleted: 'Consulta de {{patientName}} eliminada.',
  measurements: 'Medidas',
  // PopupPatientsLimitGraceEnded
  plan_limit_exceeded: 'Te has pasado del límite de tu plan',
  upgrade_your_subscription: 'Mejora tu suscripción para continuar utilizando Almendra',
  select_my_billingPlan: 'Elegir mi plan',
  plan_limit_description:
    'Tu suscripción al {{planName}} tiene como límite {{patientsLimit}} pacientes activos. Actualmente tienes {{activePatients}}.',
  // Popupeditcustommeasurements
  edit_custom_measurement_add_unit: 'Por favor, ingresa el nombre de la unidad que deseas crear',
  edit_custom_measurement_add_valid_name: 'Por favor, ingresa otro nombre para la unidad',
  edit_custom_measurement_add_weight: 'El peso (en gramos) tiene que ser mayor a cero',
  custom_measurement_already_exists: 'Ya existe una medida con el nombre',
  no_changes_made: 'No has realizado cambios',
  edit_custom_measurement_question: '¿Deseas editar esta medida casera?',
  edit_custom_measurement_disclaimer:
    'En caso hayas utilizado esta medida casera para este alimento en algún plan alimenticio, plantilla, grupo de equivalencias, receta o alimentos favoritos, se actualizará con los nuevos cambios.',
  edit_custom_measurement_confirmation: 'Sí, editar medida casera',
  edit_custom_measurement_success: 'Medida casera editada con éxito',
  edit_custom_measurement_error: 'Ocurrió un error editando la medida casera. Inténtalo nuevamente.',
  edit_custom_measurement: 'Editar medida casera',
  custom_measurement_for_food: 'Medida casera para el alimento',
  unit_name_singular: 'Nombre de la unidad (en singular)',
  add_unit_name_singular_disclaimer: 'Recuerda incluir solamente el nombre de la unidad y no con la cantidad.',
  add_unit_name_error: 'Has ingresado un número en el nombre de la unidad. Las cantidades de la unidad no se ponen en este lugar. ',
  add_unit_name_error_description:
    'Por ejemplo: si quieres crear la unidad casera "2/3 tazas" tendrás que escribir "taza" como nombre de la unidad. Luego de haberla creado, podrás elegir que la cantidad sea "2/3".',
  weight_for_one_unit: 'Peso (en gramos) por 1',
  // CreatedFoodsTable
  delete_food_question: '¿Deseas borrar este alimento?',
  food_delete_error: 'Ocurrió un error eliminando el alimento. Inténtalo nuevamente.',
  created_foods: 'Alimentos creados',
  restore: 'Restaurar',
  no_created_foods: 'No has creado alimentos',
  create_custom_food_description:
    'Si no encuentras un alimento en las diferentes bases de datos que proporcionamos, puedes crear un alimento manualmente y lo incorporaremos privadamente para tus próximas búsquedas.',
  // CustomMeasurementsTable
  delete_custom_measurement: '¿Deseas borrar esta medida casera?',
  delete_custom_measurement_success: 'Medida casera eliminada con éxito',
  delete_custom_measurement_error: 'Ocurrió un error eliminando la medida casera. Inténtalo nuevamente.',
  search_custom_measurement: 'Buscar medida casera',
  created_custom_measurements: 'Medidas caseras creadas',
  no_created_custom_measurements: 'No has creado medidas caseras',
  create_custom_measurement_description:
    'Si no encuentras una medida para un determinado alimento, puedes crear tu propia medida casera y utilizarla en los planes alimenticios.',
  // Header
  logout_modal_title: '¿Deseas cerrar sesión?',
  logout_modal_ok: 'Sí, cerrar sesión',
  logout_modal_no: 'No',
  chat_deactivated: 'El chat de soporte está temporalmente desactivado debido a problemas técnicos',
  home: 'Inicio',
  report: 'Reporte',
  chat: 'Chat',
  schedule: 'Agenda',
  tours_and_videos: 'Tours y videos',
  custom_fields: 'Campos personalizados',
  with: 'con',
  new_mealPlan: 'Nuevo plan alimenticio',
  edit_mealPlan: 'Editar plan alimenticio',
  view_mealPlan: 'Ver plan alimenticio',
  view_template: 'Ver plantilla',
  edit_template: 'Editar plantilla',
  view_recipe: 'Ver receta',
  workouts: 'Rutinas',
  settings: 'Configuración',
  notification: 'Notificaciones',
  mailbox: 'Correo',
  introduction: 'Introducción',
  plans: 'Planes',
  diets_minus: 'dietas',
  appointment_minus: 'consulta',
  patients_minus: 'pacientes',
  report_minus: 'reporte',
  templates_minus: 'plantillas',
  diets: 'Dietas',
  profile_and_configuration: 'Perfil y configuración',
  help: 'Ayuda',
  novelties: 'Novedades',
  log_out: 'Cerrar sesión',
  show_chat: 'Mostrar chat',
  hide_chat: 'Ocultar chat',
  help_center: 'Centro de ayuda',
  // BlogsHome
  delete_publication_question: '¿Deseas borrar esta publicación?',
  publications: 'Publicaciones',
  no_publications_found: 'No se encontraron publicaciones',
  soon_you_will_see_expert_publications: 'Próximamente podrás ver las publicaciones de',
  created_in: 'Creado el',
  appointment_locations: 'Lugares de consulta',
  school_association: 'Colegiatura',
  publication: 'publicación',
  publication_other: 'publicaciones',
  in_total: 'en total',
  // MealPlanMain
  foods_blankText: 'Alimentos - Texto libre',
  lose_weight: 'Bajar de peso',
  gain_weight: 'Subir de peso',
  to_be_determined: 'Por definir',
  categorize_by_progress: 'Por progreso',
  // PersonalPageBlog
  share_blog_link: 'Les comparto el link de mi publicación',
  empty_article: 'El contenido de este artículo está vacío',
  of_reading: 'de lectura',
  // ServicesMain
  manage_your_healthPlans: 'Administra tus paquetes nutricionales',
  succesfully: 'satisfactoriamente',
  // PersonalPageHome
  description_updated: 'Descripción básica actualizada',
  school_association_updated: 'Colegiatura actualizada',
  business_hours_updated: 'Horario de atención actualizado',
  delete_profile_picture: '¿Quieres eliminar tu imagen de perfil?',
  short_description_of_you: 'Breve descripción de tí',
  school_association_code: 'Código de colegiado',
  appointments_personalPage: 'consulta',
  appointments_personalPage_other: 'consultas',
  // PersonalPageMain
  whatsapp_predetermined: 'Hola. ¿Qué servicios ofreces?',
  blog: 'Blog',
  view_all_publications: 'Ver todas las publicaciones',
  go_back_to_dashboard: 'Regresar al dashboard',
  go_back_1: 'Regresar',
  // ReferralNotification
  invite_colleagues: 'Invita a tus colegas',
  invite_colleagues_description: 'Invita a las personas que aún no tengan cuenta en Almendra a que se registren a través de tu enlace.',
  offer_them_a_discount: 'Regálales un descuento',
  offer_them_a_discount_description: 'Todos tus referidos tendrán un descuento del 20% en su primer mes.',
  receive_a_discount: '¡Recibe un descuento permanente!',
  receive_a_discount_description: 'Cuando cada colega se suscriba a cualquier plan en Almendra, recibirás un 20% de descuento de por vida.',
  new_1: 'Nuevo',
  referal_program: 'Programa de referidos',
  go_to_referrals: 'Ir a Referidos',
  // ExpertReferrals
  register_with_my_link_and_win: 'Ya probaste Almendra? Regístrate con mi enlace y recibirás hasta US$ 29.99 en tu primer mes!',
  invitation_sent_success: 'Invitación enviada con éxito',
  invitation_sent_to: 'Se envío la invitación a ',
  invitation_sent_error: 'No se pudo enviar la invitación a ',
  copy_the_link: 'Copia el enlace',
  invite_colleagues_and_win: 'Invita a tus colegas y gana hasta el 100% de descuento de por vida',
  invite_colleagues_and_win_description:
    'Por cada colega que invites que se suscriba recibirás un 20% de descuento en tu facturación, <1>¡para siempre!</1> <3>Lee los términos</3>',
  share_almendra_with_colleagues: 'Comparte Almendra con tus colegas',
  sent_a_mail_to_you_colleagues: 'Envía un correo electrónico a tus colegas que nunca hayan probado Almendra para unirse con tu enlace.',
  enter_mail: 'Ingresa la dirección de correo electrónico',
  enter_one_mail_at_the_time: 'Ingresa una dirección de correo electrónico a la vez',
  referral_follow_up: 'Haz un seguimiento de tus referidos',
  mail_invitations: 'Invitaciones enviadas por email',
  benefits_received: 'Beneficios recibidos',
  show_more_info: 'Mostrar más información',
  how_referrals_work: '¿Cómo funciona el programa de referidos?',
  frequent_questions: 'Preguntas frecuentes',
  search_for_faq: 'Consulta estas respuestas a las preguntas más comunes y encuentra más información en el',
  referrals_faq_q1: '¿Qué recibirá mi colega cuando le mande una invitación?',
  referrals_faq_a1: 'Cuando se registre con tu enlace, tu colega recibirá 20% de descuento adicional en su primer mes de suscripción.',
  referrals_faq_q2: 'Un amigo me invitó a registrarme en Almendra, pero no he recibido ningún cupón.',
  referrals_faq_a2:
    ' No es necesario que tengas un cupón de descuento. Basta registrarte con el enlace que te ha enviado tu colega. Solo así recibirás el beneficio del descuento.',
  referrals_faq_q3: 'Invité a un amigo, pero no he recibido el descuento en mi suscripción',
  referrals_faq_a3:
    'Recibirás un 20% de descuento en tu suscripción mensual cuando tu referido tenga una suscripción activa. Este proceso se hace automáticamente. Para ver el detalle de tus referidos, haz click en',
  your_benefits: 'Tus beneficios',
  your_referrals: 'Tus referidos',
  your_referrals_discount: 'de descuento en tu suscripción actual',
  no_referrals_yet: 'Todavía no has referido a colegas',
  invited: 'Invitado',
  registered: 'Registrado',
  subscribed: 'Suscrito',
  pending: 'Pendiente',
  you_sent_a_mail_invitation: 'Le enviaste la invitación por email',
  registered_with_your_link: 'Se registró con tu enlace',
  has_an_active_subscription: 'Tiene una suscripción activa',
  benefit_was_removed: 'El beneficio se removió el {{-date}}',
  benefit_will_be_removed: 'El beneficio se removerá el {{-date}} si no mantiene su suscripción activa.',
  // RecipeCreate
  delete_this_recipe_question: '¿Deseas eliminar esta receta?',
  recipe_was_eliminated: 'Se eliminó la receta',
  finished_this_recipe_question: '¿Terminaste de completar esta receta?',
  finished_this_recipe_desctiption: 'Una vez que confirmes esta acción, podrás incluir esta receta en tus planes alimenticios.',
  recipe_Was_finished: 'Terminaste de completar la receta ',
  pending_recipe: 'Receta {{recipeName}} pendiente de terminar',
  cant_edit_a_finished_recipe: '¡No puedes editar una receta terminada!',
  recipe_updated_succesfully: 'Receta actualizada con éxito',
  edit_only_recipes_you_created: 'Solo puedes editar recetas que hayas creado',
  error_changing_recipe_ingredients: ' Ups! No se pudo cambiar el orden de los ingredientes',
  complete_the_instruction: 'Completa la instrucción',
  enter_recipe_portions_warning: 'Necesitas colocar un número de porciones, en caso no lo hagas, el valor por defecto será de 1 porción',
  edit_someones_recipe_warning: 'Parece que estás queriendo editar una receta que no te pertenece',
  recipe_name: 'Nombre de la receta',
  finished_recipe: 'Terminar receta',
  delete_recipe: 'Eliminar receta',
  ingredients_for_this_recipe: 'Señala los ingredientes para preparar esta receta',
  optional_ingredient_question: '¿Deseas añadir un ingrediente opcional? ',
  without_optional_ingredients: 'Sin ingredientes opcionales',
  optional_ingredient_example: 'Ingresa un ingrediente opcional. Ej: 1 pizca de sal',
  follow_steps_to_prepare_recipe: 'Señala los pasos a seguir para preparar esta receta',
  warning: 'Aviso',
  yes_finished: 'Sí, he terminado',
  // Sidebar
  go_to_appointment_1: 'Ir a la consulta',
  // PatientAuthForm
  patients_login_title: 'Pacientes',
  log_in_as_patient: 'Iniciar sesión como paciente',
  im_dietitian: 'Soy nutricionista',
  // PlanRestrictionModal
  upgrade_your_plan: 'Mejora tu plan',
  upgrade_for_this_feature: 'Esta funcionalidad no está disponible para tu plan.',
  // PlanRestrictionHOC
  feature_disabled_for_free_plan: 'Esta función está deshabilitada para el Plan Free',
  feature_disabled_for_basic_plan: 'Esta función está deshabilitada para el Plan Básico',
  feature_disabled_for_advanced_plan: 'Esta función está deshabilitada para el Plan Avanzado',
  feature_disabled: 'Esta función está deshabilitada',
  feature_disabled_free: 'Esta funcionalidad solo es válida para el Plan Pro',
  // WorkoutsMain
  duplicate_workout_question: '¿Deseas duplicará la rutina',
  duplicate_workout_content: 'Se duplicará todo el contenido de la misma.',
  workout_duplicated: 'Rutina {{name}} fue duplicada.',
  delete_workout_question: '¿Deseas eliminar la rutina',
  workout_deleted: 'Rutina {{name}} fue eliminada.',
  workout_templates: 'Plantillas de rutinas',
  no_workouts_available: 'No hay rutinas disponibles',
  by_goal: 'Por objetivo',
  by_type: 'Por tipo',

  //WorkoutsTable
  no_workouts_yet: 'Aún no creas rutinas',

  // StatsMain
  week_of_the_year: 'Semana {{weekNo}} del año',
  in_the_last_custom_days: 'en los últimos {{name}} días',
  the_last_custom_days: 'los {{name}} días previos',
  go_to_finance: 'Ir a finanzas',
  no_patients_with_healthPlans_stat: 'Actualmente no tienes pacientes que hayan adquirido paquetes de consultas.',
  go_to_patients: 'Ir a pacientes',
  patients_provenance: 'Proveniencia de los pacientes',
  provenance: 'Proveniencia',
  no_patients_with_provenance: 'Todavía no agregas de dónde provienen tus pacientes',
  patients_by_healthPlan: 'Pacientes por paquete nutricional',
  signed_up_patients: 'paciente inscrito',
  signed_up_patients_other: 'pacientes inscritos',
  finished_mealPlans: 'Planes Alimenticios completados',
  no_mealplans_done: 'Todavía no completas planes alimenticios.',
  go_to_diets: 'Ir a dietas',
  income_by_modality: 'Ingresos por modalidad',
  no_income_gained: 'Todavía no percibes ingresos desde consultas.',
  no_patients_registered: 'Todavía no agregas pacientes.',
  patients_by_sex: 'Pacientes por sexo',
  most_frequent_patients: 'Pacientes más frecuentes',
  no_appointments_done: 'Todavía no realizas consultas.',
  mealPlans_by_objective: 'Planes Alimenticios por objetivo',
  reutilized_templates: 'Plantillas reutilizadas',
  no_reutilized_templates_yet: 'Todavía no utilizas plantillas.',
  appointments_performed: 'Consultas realizadas',
  patients_by_age: 'Pacientes por edades',
  patients_by_tags: 'Pacientes por etiquetas',
  clasification_by_tags: 'Clasificación según etiquetas',
  no_tags_added: 'Todavía no agregas etiquetas de pacientes.',
  hours: 'Horas',
  appointment_average_duration: 'Tiempo medio por consulta',
  appointment_accumulated_duration: 'Tiempo acumulado de consultas',
  mealPlan_average_duration: 'Tiempo medio de elaboración de planes alimenticios',
  mealPlan_accumulated_duration: 'Tiempo acumulado de elaboración de planes alimenticios',
  scheduled_appointment_assistance_ratio: 'Ratio de asistencia a las consultas agendadas ',
  appointments_scheduled: 'agendadas',
  from: 'Del',
  to: 'al',
  // ChildrenData
  patient_in_percentile: 'El paciente se encuentra en el percentil',
  result_out_of_range: 'El resultado no se encuentra dentro del rango de valores',
  height_for_age: 'Altura para la edad',
  height_required: 'Se necesita registrar la altura para este análisis',
  measures: 'mide', //is,
  height_and_weight_required: 'Se necesita registrar peso y altura para este análisis',
  weight_for_age: 'Peso para la edad',
  weights: 'pesa',
  weight_required: 'Se necesita registrar peso para este análisis',
  weight_for_height: 'Peso para la altura',
  imc_for_age: 'IMC para la edad',
  imc_required: 'Se necesita el índice de masa corporal para este análisis',
  has_imc: 'tiene un IMC',
  headCircumference: 'Circunferencia de la cabeza',
  headGirth_required: 'Se necesita registrar el perímetro cefálico para este análisis',
  has_a_headGirth_that_measures: 'tiene una circunferencia de la cabeza que mide',
  armCircumference: 'Circunferencia del brazo',
  armGirth_required: 'Se necesita registrar el perímetro del brazo para este análisis',
  has_a_armGirth_that_measures: 'tiene una circunferencia del brazo que mide',
  the_boys: 'los niños',
  the_girls: 'las niñas',
  less: 'menos',
  smaller: 'menor',
  more: 'más',
  bigger: 'mayor',
  he: 'él',
  her: 'ella',
  childenData_main_text:
    'Un {{percentile}}% de {{gender}} de su misma edad y sexo {{measure}} igual o {{less}} que {{genderPronun}} y  un {{otherPercentile}}% {{measure}} {{more}} que {{genderPronun}}',
  childenData_main_text_2:
    'Un {{percentile}}% de {{gender}} de su misma altura pesa igual o menos que {{genderPronun}} y un {{otherPercentile}}% pesa más que {{genderPronun}}',
  // SystemTags
  baby: 'Bebé',
  newborn: 'Recién nacido',
  kid: 'Niño',
  teen: 'Adolescente',
  with_acne: 'Con acné',
  sportsman: 'Deportista',
  elderly: 'Adulto mayor',
  pregnant: 'Embarazada',
  postpartum: 'Postparto',
  menopausal: 'Menopáusica',
  pre_operative: 'Preoperatorio',
  post_operative: 'Postoperatorio',
  // Upload
  only_upload_certain_excel: 'Solo puedes subir archivos de Excel 97-2003 (.xls) o Excel 2007+ (.xlsx)',
  less_than_100kb: 'El archivo subido debe pesar menos de 100kb',
  less_than_10MB: 'El archivo adjunto debe pesar menos de 10MB',
  only_pdf_files: 'Solo está permitido subir archivos en formato PDF',
  less_than_15MB: 'El archivo adjunto debe pesar menos de 15MB',
  only_images_pdf_and_office: 'Solo está permitido subir imágenes, pdf, y archivos de Microsoft Office.',
  less_than_3MB: 'La imagen debe pesar menos de 3MB',
  jpg_or_png_image: 'Solo puedes subir una imagen con formato .JPG o .PNG',
  // Functions
  appointment_deleted: 'Consulta eliminada.',
  delete_scheduled_appointment_question: '¿Quieres eliminar esta consulta agendada?',
  registered_payment_will_be_deleted: 'Existe un pago realizado para este paquete. Al eliminar esta consulta se perderá la información de este pago.',
  patient_name: '{{name}}',
  finish_appoitment_before_starting_one: 'Termina la consulta con {{foundPatient}} para poder iniciar otra',
  the_patient: 'el paciente',
  start_scheduled_appointment_with: '¿Deseas iniciar la consulta agendada con {{patient}}?',
  appointment_with_videocall: 'Esta consulta tiene una videollamada. Haz <1>click aquí</1> para abrir la videollamada en una nueva ventana.',
  logout_currentAppointment: 'Tienes una consulta en curso. ¿Realmente deseas cerrar sesión?',
  end_currentAppointment: '¿Deseas finalizar la consulta en curso?',
  logout_and_endAppointment: 'En caso cierres sesión, la consulta finalizará.',
  info_will_be_saved: 'La información quedará guardada en el historial del paciente.',
  yes_logout: 'Sí, cerrar sesión',
  yes_finish: 'Sí, finalizar',
  appointment_with_patient_finished: 'Consulta con {{patient}} terminada.',
  // RecipeCategoriesList
  appetizers: 'Entrada',
  main_course: 'Plato principal',
  salad: 'Ensalada',
  desert: 'Postre',
  side: 'Guarnición',
  beverage: 'Bebida',
  arab: 'Árabe',
  indian: 'India',
  mexican: 'Mexicana',
  chinese: 'China',
  italian: 'Italiana',
  american: 'Americana',
  japanese: 'Japonesa',
  spanish_1: 'Española',
  argentinian: 'Argentina',
  french: 'Francesa',
  peruvian: 'Peruana',
  german: 'Alemana',
  brasilian: 'Brasileña',
  cuban: 'Cubana',
  greek: 'Griega',
  hungarian: 'Húngara',
  english_1: 'Inglesa',
  thai: 'Tailandesa',
  russian: 'Rusa',
  no_lactose: 'Sin Lactosa',
  no_gluten: 'Sin Gluten',
  no_soy: 'Sin Soya',
  meat_dishes: 'Platos de carne',
  pork_dishes: 'Platos de cerdo',
  fish_dishes: 'Platos de pescado',
  seafood_dishes: 'Platos de marisco',
  chicken_dishes: 'Platos de pollo (avícola)',
  vegetarian_dishes: 'Platos vegetarianos',
  vegan_dishes: 'Platos veganos',
  pot_parboiled: 'Olla (Sancochada)',
  pot_presurized: 'Olla (A presión)',
  microwave: 'Microondas',
  oven: 'Al Horno',
  fried: 'Frita',
  in_pan: 'En sartén',
  charcoaled: 'Al carbón',
  grilled: 'A la parrilla',
  steamed: 'Al vapor',
  blended: 'Licuado',
  sweet: 'Dulce',
  salty: 'Salado',
  bitter: 'Amargo',
  acid: 'Ácido',
  Snack: 'Snack',
  'Durante el entreno': 'Durante el entreno',
  in_the_kitchen: 'Por cocina',
  by_time_of_the_day: 'Por hora del día',
  by_taste: 'Tipo de sabor',
  by_cooking_type: 'Tipo de cocción',
  by_ingredients: 'Por ingredientes',
  by_exclution: 'Por exclusión',
  by_type_plate: 'Por tipo de plato',
  // MailSettings
  // si se va a cambiar estas variables, se tienen que cambiar en server mailing
  custom_mail_v_patientName: '{Nombre del paciente}',
  custom_mail_v_firstName: '{Tu nombre}',
  custom_mail_v_lastName: '{Tu apellido}',
  custom_mail_v_phone: '{Tu teléfono}',
  custom_mail_v_mail: '{Tu correo}',
  custom_mail_v_appointmentType: '{Tipo de consulta}',
  custom_mail_v_appointmentDate: '{Fecha y hora de la consulta}',
  custom_mail_v_appointmentPrice: '{Precio de la consulta}',
  custom_mail_v_appointmentModality: '{Modalidad de la consulta}',
  custom_mail_v_appointmentAddress: '{Dirección de la consulta}',
  custom_mail_v_mealPlanStartDate: '{Inicio del plan alimenticio}',
  custom_mail_v_mealPlanEndDate: '{Fin del plan alimenticio}',
  patientWelcomeMailSubject: '¡Bienvenido!',
  patientWelcomeMailTemplate: `
  <h3>¡Hola {{patientName}}!</h3>
  <p>Me da mucho gusto que hayas tomado la decisión de preocuparte por tu cuerpo y tu salud,
   y por darme la confianza de encargarme de que llegues a tu objetivo. Recuerda que siempre estaré
   para lo que necesites, y no dudes en preguntarme cualquier duda que se te ocurra.</p>
  <p>Podremos hacerle seguimiento a tu progreso a través de Almendra. Ahí puedes ver tu dieta,
  tus medidas, ver mis horarios disponibles para sacar citas </p>
  <p>¡Estamos en contacto!</p>
  <p>Atentamente, {{firstName}} {{lastName}} </p>
  <p>Teléfono: {{phone}} </p>
  <p>Correo: {{mail}}</p>
`,
  appointmentReminderSubject: 'Recordatorio de consulta - Nutricionista',
  appointmentReminderMailTemplate: `
  <h3>Hola, {{patientName}}</h3>
  <p>Este es un cordial recordatorio de tu consulta en el nutricionista.</p>
  <p>Estos son los detalles:</p>
  <ul>
    <li>Tipo de consulta: {{appointmentType}}</li>
    <li>Fecha y hora: {{appointmentDate}}</li>
    <li>Precio: {{appointmentPrice}}</li>
    <li>Modalidad: {{appointmentModality}}</li>
    <li>Dirección: {{appointmentAddress}}</li>
  </ul>
  <p>Atentamente,</p>
  <p>{{firstName}} {lastName}</p>
  <p>Teléfono: {{phone}}</p>
  <p>Correo: {{mail}}</p>
  `,
  patientBirthdayMailSubject: '¡Feliz día!',
  patientBirthdayMailTemplate:
    '<h3>¡Feliz día {{patientName}}!</h3>\n<p>Espero que hoy pases un día lleno de felicidad, los mejores deseos para ti.</p>',
  patientMealPlanMailSubject: '¡Tu plan alimenticio está listo!',
  patientMealPlanMailTemplate: `
  <h3>Hola, {{patientName}}</h3>
  <p>Adjunto tu plan alimenticio programado del {{mealPlanStartDate}} al {{mealPlanEndDate}}.</p>
  <p>Cualquier consulta no dudes en comunicarte conmigo.</p>
  <p>Saludos,</p>
  <p>{{firstName}}</p>
`,
  patientAppointmentMailSubject: '¡Tu reporte de consulta está listo!',
  patientAppointmentMailTemplate: `
  <h3>Hola, {{patientName}}</h3>
  <p>Adjunto el reporte de tu consulta de hoy</p>
  <p>Cualquier consulta no dudes en comunicarte conmigo.</p>
  <p>Saludos,</p>
  <p>{{firstName}}</p>
`,
  patient_passwordMail_title: 'Acceso al App de pacientes',
  patient_passwordMail_true:
    'Al registrar un paciente con un correo electrónico, se mostrará una opción para enviarle un correo para que cree su cuenta y pueda acceder al App de pacientes.',
  patient_passwordMail_false: 'Al registrar un paciente con un correo electrónico, no se le invitará a acceder al App de Pacientes.',
  activated: 'Activo',
  deactivated: 'Desactivado',
  patient_passwordMail_content:
    'Este correo se envía al instante que registras a un paciente con un correo electrónico. Se le pedirá crear una contraseña para entrar al App de pacientes.',
  patient_welcomeMail_true: 'Bienvenida a paciente',
  send_mail: 'Enviar el correo ',
  automatic_mail_will_be_sent_1:
    'Cada vez que agregues un paciente se enviará un correo automático a su correo, cuando se cumpla el tiempo indicado.',
  automatic_mail_wont_be_sent_1: 'No se enviará un correo automático cada vez que agregues un paciente.',
  automatic_mail_will_be_sent_2: 'Cada vez que agendes una consulta se enviará un correo automático a ese paciente, faltando el tiempo indicado.',
  automatic_mail_wont_be_sent_2: 'No se enviará un correo de recordatorio cada vez que agendes una consulta.',
  automatic_mail_will_be_sent_3: 'Se le enviará un correo automático a tu paciente el día de su cumpleaños.',
  automatic_mail_wont_be_sent_3: 'No se le enviará un correo automático a tu paciente el día de su cumpleaños',
  hours_after_registering_the_patient: '{{hour}} después de registrar al paciente. {{instantly}}',
  instantly: 'Instantáneamente',
  reset_mail_format: '¿Deseas restablecer el formato del correo al del sistema？',
  reset_format: 'Restablecer formato',
  patient_welcomeMail_placeholder: 'Ej: ¡Vamos a hacer esto posible!',
  appointment_reminder_placeholder: 'Ej: ¡Recuerda tu consulta!',
  birthday_greeting: 'Saludo por cumpleaños',
  birthDate_mail_placeholder: 'Ej: ¡Feliz día, Luis Felipe!',
  mealPlan_mail_placeholder: 'Ej: Luis Felipe, tu plan alimenticio está listo',
  appointment_report_placeholder: 'Ej: Tu reporte de consulta está listo',
  body: 'Cuerpo',
  if_you_write: 'Si escribes...',
  if_you_write_with_variable:
    'Si escribes {Tu nombre}, en el correo aparecerá {{name}}, pero si escribes Tu nombre aparecerá Tu nombre. Recuerda usar {}',
  will_result_in: 'Aparecerá...',
  patients_name_placeholder: 'El nombre del paciente que reciba el correo. Ej. Juan',
  no_phone_number: '[¡No has completado tu número de teléfono!]',
  appointment_type_details: 'El tipo de la consulta asociada ({{healthPlans}})',
  date_and_hour_details: 'La fecha y la hora de la consulta asociada. Por ejemplo, 12/10/2017 a las 09:00am',
  price_details: 'El precio de la consulta asociada. Por ejemplo, {{-currency}} 100',
  appointment_modality_details: 'La modalidad de la consulta agendada. Puede ser consulta presencial, a domicilio o online',
  hours_before_appointment: 'horas antes de cada consulta',
  appointment_address_details: 'La dirección donde se va realizar la consulta. En caso la modalidad sea online, se omitirá la dirección.',
  mealPlan_start_placeholder: 'La fecha de inicio del plan. Ej. 10/12/2024',
  mealPlan_end_placeholder: 'La fecha de fin del plan. Ej. 14/12/2024',
  automatic_mails_restriction: 'Para poder mandar mails automáticos a tus pacientes, mejora tu suscripción. ',
  current_billingPlan: 'Tienes una suscripcion vigente al {{planName}}',
  no_current_billingPlan: 'No tienes una suscripción vigente a ningun plan',
  // FoodProperties
  baby_foods: 'Alimentos infantiles',
  baked_products: 'Alimentos preparados (Entradas)',
  snacks: 'Aperitivos',
  beverages: 'Bebidas',
  meats_and_others: 'Carnes y derivados',
  breakfast_cereals: 'Cereales de desayuno',
  cereal_grains_pasta: 'Cereales y derivados',
  restaurant_foods: 'Comida de restaurante',
  fast_food: 'Comida rápida',
  lamb_deer_and_other: 'Cordero, ternero y productos de caza',
  sweets: 'Dulces',
  sausages: 'Embutidos y fiambres',
  spices_and_herbs: 'Especies y hierbas',
  fruit_and_fruit_juices: 'Frutas y jugos',
  pasta_and_cereal_grains: 'Granos de cereales y pasta',
  fats_and_oils: 'Grasas y aceites',
  dairy_and_eggs: 'Leche, huevos y derivados',
  legumes: 'Legumbres',
  legumes_and_legume_products: 'Leguminosas y derivados',
  miscellaneous: 'Misceláneos',
  nut_and_seed_products: 'Nueces y semillas',
  fish_and_seafood: 'Pescados y mariscos',
  poultry_products: 'Productos avícolas',
  pork_products: 'Productos de cerdo',
  soup_sauces_and_gravies: 'Sopas y salsas',
  tubers_roots_and_other: 'Tubérculos, raíces y derivados',
  vegetables: 'Vegetales',
  // Activities and met lists
  metsName: 'name',
  // FoodMacroFunctions

  // Diseases Array

  indications_diabetes_title: 'Indicaciones para Diabetes',
  indications_diabetes_1:
    'Alimentos a evitar: Repostería, pastelería, bollería y dulces en general. Manteca de cerdo, aceites de palma o de coco. Carnes grasas, embutidos, salazones y ahumados. Gaseosas y zumos comercializados. Bebidas alcohólicas',
  indications_diabetes_2: 'Se recomienda deporte y/o ejercicio físico',
  recommendations_diabetes_1: 'Cereales enteros, legumbres, aceite de oliva, verduras y hortalizas, frutas frescas, pescados',
  indications_dislipemia_title: 'Indicaciones para Dislipemia',
  indications_dislipemia_1:
    'Alimentos a evitar: Papas fritas o chips. Leche entera, nata, quesos muy grasos. Embutidos, hamburguesas, pato, vísceras. Salsas hechas con mantequilla y mantequilla en general. Chocolate y pastelería',
  recommendations_dislipemia_1: 'Cereales (pan, pastas, cereales). Frutas, hortalizas y legumbres. Carne (pollo y pavo). Frutos secos',
  indications_hypertension_title: 'Indicaciones para Hipertensión',
  recommendations_hypertension_1:
    'Aves y otras carnes poco grasas. Huevos. Leche, yogures, Pastas alimenticias y cereales (mejor si son integrales). Fruta natural. Frutos secos sin sal',
  indications_hypertension_1:
    'Alimentos a evitar: Sal de cocina y mesa. Carnes saladas, ahumadas y curadas. Embutidos en general. Quesos en general. Sopas de sobre, purés instantáneos. Agua con gas y bebidas refrescantes con gas, Condimentos salados. Pan y biscotes con sal',
  indications_celiac_title: 'Indicaciones para Celiaquía',
  recommendations_celiac_1:
    'Aves y otras carnes poco grasas. Huevos, leche, yogures. Pastas alimenticias y cereales (mejor si son integrales). Fruta natural. Frutos secos sin sal',
  indications_celiac_1:
    'Alimentos a evitar (contienen gluten): Cereales (trigo, cebada, centeno, avena, salvado). Pan, pasteles, tartas, galletas, bizcochos. Pastas. Higos secos. Carnes procesadas, rellenos, salsas, caldos o sopas concentradas. Bebidas destiladas o fermentadas a partir de cereales',
  indications_obesity_title: 'Indicaciones para Obesidad',
  recommendations_obesity_1:
    'Legumbres. Verduras y hortalizas. Frutas (frescas a ser posible). Zumos naturales. Carne, pescado, huevos y derivados (carnes magras, evitar piel y grasa visible antes de cocinar)',
  indications_obesity_1:
    'Alimentos a evitar: Leche y lácteos (leche entera, postres lácteos con leche entera, nata o azucarados, yogures azucarados). Carnes grasas y embutidos. Dulces (galletas, pastelería). Zumos azucarados, gaseosas, bebidas alcoholicas. Chocolates, miel, mermeladas convencionales',
  indications_pregnant_title: 'Indicaciones para Embarazadas',
  indications_pregnant_1:
    'Evitar el consumo de alcohol y el consumo de tabaco. Evitar pescados que tengan alto nivel de mercurio. Hacer ejercicio aeróbico por mínimo 30 minutos al día en la mayoría de los días de la semana',
  indications_nursing_title: 'Indicaciones para Lactantes',
  indications_nursing_1:
    'Se recomienda hacer lactancia exclusiva por los primeros 6 meses, luego continuarla hasta los dos años o más acompañada de una buena nutrición apropiada para la edad, Hacer ejercicio aeróbico por mínimo 30 minutos al día en la mayoría de los días de la semana',
  obesity: 'Obesidad',
  celiac_disease: 'Celiaquía',
  hypertension: 'Hipertensión',
  dyslipidemia: 'Dislipemia',
  diabetes_mellitus: 'Diabetes Mellitus',

  // AddServiceHours
  start_hour_before_end: 'La hora de inicio debe ser menor a la hora final',
  block_hours_limit: 'El bloque debe tener más de {{minutes}} minutos',
  block_hours_conflict: '¿Existe conflicto con otro bloque que has creado anteriormente',
  select_at_leas_one_day: 'Debes seleccionar al menos un día',
  select_at_leas_one_modality: 'Debes seleccionar al menos una modalidad',
  new_block_hour: 'Nuevo bloque de atención',
  edit_block_hour: 'Editar bloque de atención',
  selected_days: 'Días seleccionados',
  selected_block: 'Horario seleccionado',
  appointment_modality: ' Modalidad de consulta',

  // FreePlanMaxCapModal
  free_plan_max_cap_title: '¡Estás creciendo rapidísimo!',
  free_plan_max_cap_description:
    'Actualmente estás en el plan Free, y permite tener hasta 20 pacientes o 20 planes alimenticios. Para crear este {{capType}}, debes mejorar tu plan.',

  // GenerateMealPlanModal
  generate_meal_plan: 'Generar plan alimenticio',
  generate_meal_plan_type_validator: 'Para usar Almendra Engine® debes ir al paso inicial y cambiar el tipo de plan alimenticio',
  generate_meal_plan_dailyCalories_validator: 'Tienes que asignar un objetivo calórico en el Paso 2 (Objetivos)',
  generate_meal_plan_dailyCaloriesDistribution_validator: 'Tienes que asignar objetivos calóricos por comida en el Paso 2 (Objetivos)',
  generate_meal_plan_mealsPerDay_validator: 'Tiene que haber al menos 3 comidas',
  generate_meal_plan_regenerate_title: '¿Deseas generar un nuevo plan alimenticio?',
  generate_meal_plan_regenerate_content: 'Todo alimento o comentario que hayas ingresado será sobreescrito',
  generate_meal_plan_success_title: '¡Tu plan está listo!',
  generate_meal_plan_success_body:
    'Almendra Engine® aún se encuentra en beta y está en constante mejora. En caso tengas alguna sugerencia, cuéntanos en el buzón de "Sugerencias" ubicado en la barra superior.',
  generate_meal_plan_success_action: 'Listo',
  generate_meal_plan_error_title: 'Ups! Ocurrió algo inesperado',
  generate_meal_plan_error_body: 'No pudimos generar el plan alimenticio con los datos ingresados',
  generate_meal_plan_error_action: 'Entendido',
  generate_meal_plan_error_demand: 'Parece que tenemos mucha demanda de planes. Por favor, vuelve a intentarlo en un rato...',
  generate_meal_plan_error_suggestion_title: 'Te sugerimos revisar lo siguiente:',
  generate_meal_plan_error_suggestion_content_1: '- La fecha de nacimiento del paciente debe ser mayor a 17 años (Perfil del Paciente)',
  generate_meal_plan_error_suggestion_content_2: '- La distribución de macronutrientes para los días seleccionados (Paso 2 - Objetivos)',
  generate_meal_plan_error_suggestion_content_3: '- La distribución de calorías para las comidas (Paso 2 - Objetivos)',
  // LoadingGenerator
  generating_meal_plan_title: 'Generando plan alimenticio',
  generating_meal_plan_subtitle: 'Estamos buscando la mejor combinación dentro de millones posibles, esto podría tardar unos minutos...',
};
