export const PATIENTS_SUBDOMAIN_NAME = 'patients'; // Si cambia, cambiar también en landing y server
export const LANDING_URL = {
  local: 'http://localhost:3003',
  development: 'https://dev.almendra.io',
  staging: 'https://stage.almendra.io',
  production: 'https://almendra.io',
}[process.env.REACT_APP_ENV];

export const API_URL = {
  local: 'http://localhost:8081',
  development: 'https://api-dev.almendra.io',
  staging: 'https://api-stage.almendra.io',
  production: 'https://api-alm.soysuper.fit',
}[process.env.REACT_APP_ENV];

export const PDF_GENERATOR_URL = {
  // local: "http://localhost:4000/dev/api/pdf",
  local: 'https://services.almendra.io/dev/api/pdf',
  development: 'https://services.almendra.io/dev/api/pdf',
  staging: 'https://services.almendra.io/dev/api/pdf',
  production: 'https://services.almendra.io/prod/api/pdf',
}[process.env.REACT_APP_ENV];

export const PATIENTS_WEB_APP_URL = {
  local: `http://${PATIENTS_SUBDOMAIN_NAME}.localhost:3000`,
  development: `https://${PATIENTS_SUBDOMAIN_NAME}.dev.almendra.io`,
  staging: `https://${PATIENTS_SUBDOMAIN_NAME}.stage.almendra.io`,
  production: `https://${PATIENTS_SUBDOMAIN_NAME}.almendra.io`,
}[process.env.REACT_APP_ENV];

export const STAGING_OR_PRODUCTION = process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'production';

const parsedData = window.location.host.split('.');
export const onSubdomain = process.env.NODE_ENV === 'production' ? parsedData.length >= 3 : parsedData.length === 2;
export const URL_SUBDOMAIN = onSubdomain ? parsedData[0] : undefined;

export const getSubdomainUrl = function getSubdomainUrl(subdomain) {
  return {
    local: `http://${subdomain}.localhost:3000`,
    development: `https://${subdomain}.dev.almendra.io`,
    staging: `https://${subdomain}.stage.almendra.io`,
    production: `https://${subdomain}.almendra.io`,
  }[process.env.REACT_APP_ENV];
};
